import React, {Component} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";

class AccountLinkSection extends Component {
    render() {
        return (
            <Link to={this.props.link} className={"no-text-decor"}>
                <div className={"d-flex rounded hover-shadow overflow-hidden bg-white shadow-sm h-100"}>
                    <div className={"bg-primary text-white p-3 d-flex align-items-center"}>
                        <FontAwesomeIcon icon={"bicycle"} size={"2x"} />
                    </div>
                    <div className={"p-3 d-flex align-items-center"}>
                        <div className={""}>
                            <h6 className={"m-0"}>{this.props.title}</h6>
                            {
                                this.props.subtitle &&
                                <p className={"text-secondary small py-2 m-0"}>
                                    {this.props.subtitle}
                                </p>
                            }
                        </div>
                    </div>
                </div>
            </Link>
        );
    }
}

export default AccountLinkSection;