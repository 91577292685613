import React, {Component} from 'react';
import {
    BASE_SELLER_URL,
    SELLER_CREATE_NEW_ITEM_URL,
    SELLER_SHOW_ALL_ACTIVE_ITEMS_URL,
    SELLER_SHOW_ALL_INACTIVE_ITEMS_URL
} from "../../urls/SellerUrl";
import SquarePanelsWithTitle from "../../../../general/selectable_square/SquarePanelsWithTitle";

class SellerItems extends Component {
    state = {
        render: [
            {
                name: "Add new bicycle",
                link: SELLER_CREATE_NEW_ITEM_URL
            },
            {
                name: "Show inactive bicycles (41)",
                link: SELLER_SHOW_ALL_INACTIVE_ITEMS_URL
            },
            {
                name: "Show active bicycles (41)",
                link: SELLER_SHOW_ALL_ACTIVE_ITEMS_URL
            },
            {
                name: "Pending actions (3)",
                link: ""
            },
            {
                name: "Bicycles on rent (2)",
                link: ""
            },
            {
                name: "Bicycles sold (10)",
                link: ""
            }
        ]
    }

    render() {
        return (
            <div>
                <SquarePanelsWithTitle title={"Manage Bicycles"}
                                       subtitle={"Manage all selling bicycle at Bicycle Company"}
                                       render={this.state.render}
                                       base_url={BASE_SELLER_URL}/>
            </div>
        );
    }
}

export default SellerItems;