import React, {Component} from 'react';
import {Col} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {COL, ITEM_URL} from "../../../constants/application/ApplicationUrl";
import {ITEM_ID_PARAM} from "../../../constants/ParamConstant";

class SectionItem extends Component {

    constructor(props) {
        super(props);
        this.discountPercent = this.discountPercent.bind(this);
    }

    discountPercent() {
        let discount = Number(this.props.item.price.replace(",",""))*100 / parseInt(this.props.item.mrp.replace(",", "").replace("₹",""));
        return Math.round(discount * 100) / 100;
    }
    render() {
        return (
            <Col xs={6} sm={6} md={4} lg={4} className={"p-2"}>
                <Link to={ITEM_URL.replace(COL + ITEM_ID_PARAM, this.props.item.id)} className={"no-text-decor"}>
                    <div className={"p-2 bg-white shadow-sm rounded border hover-shadow h-100"}>
                        <div className={"position-relative"} style={{height: 200}}>
                            <div className={"position-absolute p-2 end-0 top-0"}>
                                <div className={"rounded-circle d-flex justify-content-center align-items-center"} style={{height: 30, width: 30, background: "rgba(255,255,255,0.8)"}}>
                                    <FontAwesomeIcon icon={["far", "heart"]} />
                                    <i className="fa fa-heart-o" aria-hidden="true"></i>
                                </div>
                            </div>
                            <img className={"img-object-fit-contain rounded"}
                                 src={this.props.item.images?.length > 0 && this.props.item.images[0]}
                                 alt={"item"}/>
                        </div>
                        <div className={"py-2"}>
                            <p className={"m-0"}>
                                <b>{this.props.item.name ? this.props.item.name: this.props.item.brand}</b>
                                {this.props.item.description}
                            </p>
                            <p className={"m-0 small"}>
                                size: {this.props.item.size}
                            </p>
                            <div className={"d-flex align-items-center position-relative"}>
                                <b className={"me-2"}>
                                    <FontAwesomeIcon icon={"rupee-sign"} size={"sm"} /> {this.props.item.price}
                                </b>
                                <p className={"text-muted text-decoration-line-through small m-0 me-2"}>
                                    <FontAwesomeIcon icon={"rupee-sign"} size={"sm"} /> {this.props.item.mrp}
                                </p>
                                <p className={"text-light-green2 fs-12 m-0"}> {this.discountPercent()}% off</p>
                            </div>
                        </div>
                    </div>
                </Link>
            </Col>
        );
    }
}

export default SectionItem;