import React, {Component} from 'react';
import {Button, Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import SpinLoaderWithText from "../../../general/loader/SpinLoaderWithText";
import {BackendGETRequest} from "../../../../webrequests/BackendRequest";
import {
    BASE_ACCOUNT_URL,
    CREATE_NEW_ADDRESS_URL,
    UPDATE_ADDRESS_URL
} from "../urls/AccountUrl";
import {GET_ADDRESS_API} from "../../../../constants/backend/APIConstant";
import {Connector} from "../../../../../../redux/Connector";
import {
    BUY_NOW_PAGE_URL,
    COL,
    SELL_BICYCLE_NOW_PAGE_URL
} from "../../../../constants/application/ApplicationUrl";
import {ADDRESS_ID_PARAM} from "../../../../constants/ParamConstant";
import AddressViewComponent from "./AddressViewComponent";
import AreYouLookingForSomethingElse from "../../../general/AreYouLookingForSomethingElse";
import GeneralBreadCrumb from "../../../general/breadcrumb/GeneralBreadCrumb";

class Address extends Component {
    state = {
        address: [],
        is_loading: true,
        links: [
            {
                link: CREATE_NEW_ADDRESS_URL,
                title: "Create New Address"
            },
            {
                link: SELL_BICYCLE_NOW_PAGE_URL,
                title: "Sell Bicycle"
            },
            {
                link: BUY_NOW_PAGE_URL,
                title: "Buy Bicycle"
            }
        ],
        navigator_links: [
            {
                title: "My Account",
                link: BASE_ACCOUNT_URL
            },
            {
                title: "Address"
            }
        ]
    }

    constructor(props) {
        super(props);
        this.OnSuccessRequest = this.OnSuccessRequest.bind(this);
        this.OnFailureRequest = this.OnFailureRequest.bind(this);
        this.AddressComponent = this.AddressComponent.bind(this);
    }

    componentDidMount() {
        BackendGETRequest(this.props, GET_ADDRESS_API, this.OnSuccessRequest, this.OnFailureRequest);
    }

    OnSuccessRequest(response) {
        this.setState({
            address: response.body.address,
            is_loading: false
        });
    }

    OnFailureRequest() {
        this.setState({
            is_loading: false
        });
    }

    AddressComponent() {
        if (this.state.address.length === 0) {
            return <div className={"d-flex flex-column justify-content-center align-items-center flex-fill p-2 py-5"}>
                <p className={"text-secondary"}>
                    No address found
                </p>
                <Button variant={"outline-primary"} as={Link} to={CREATE_NEW_ADDRESS_URL}>Create new address</Button>
            </div>;
        }

        return <Row className={"g-0"}>
            {
                this.state.address.map(addressElement => <Col as={Link} md={6} key={addressElement.id} className={"no-text-decor"}
                                                               to={UPDATE_ADDRESS_URL.replace(COL + ADDRESS_ID_PARAM, addressElement.id)}>
                    <div className={"shadow-sm hover-shadow rounded m-1 overflow-hidden bg-white"}>
                        <AddressViewComponent address={addressElement} />
                    </div>
                </Col>)
            }
        </Row>;
    }

    render() {
        return (
            <div className={"flex-fill d-flex flex-column"}>
                <Row className={"g-0"}>
                    <Col lg={9} xl={10} className={"p-1 mb-3"}>
                        <div className={"d-flex flex-column"}>
                            <div className={"bg-white rounded my-2 mx-1 p-2"}>
                                <GeneralBreadCrumb items={this.state.navigator_links} />
                            </div>

                            {
                                this.state.is_loading? <SpinLoaderWithText content={"Loading address"} /> : <this.AddressComponent />
                            }
                        </div>
                    </Col>
                    <Col lg={3} xl={2} className={"p-2"}>
                        <AreYouLookingForSomethingElse links={this.state.links} />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Connector(Address);