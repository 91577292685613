import {COL, SHOW_ITEM_SINGLE_URL} from "../../../../constants/application/ApplicationUrl";
import {ORDER_ID_PARAM} from "../../../../constants/ParamConstant";

export const BASE_INSPECTOR_URL = "/user/inspector";

export const INSPECTOR_URL = BASE_INSPECTOR_URL + "/cycle";
export const INSPECTOR_ITEMS_URL = INSPECTOR_URL + "/bicycles";
export const INSPECTOR_SHOW_ALL_PENDING_ITEMS_URL = INSPECTOR_URL + "/my_inspections";
export const INSPECTOR_SHOW_SINGLE_PENDING_BICYCLE_URL = INSPECTOR_SHOW_ALL_PENDING_ITEMS_URL + SHOW_ITEM_SINGLE_URL + "/" + COL + ORDER_ID_PARAM;
export const INSPECTOR_SHOW_ALL_STATS_URL = INSPECTOR_URL + "/my_statistics";

// export const INSPECTOR_CREATE_NEW_ITEM_URL = INSPECTOR_ITEMS_URL + CREATE_NEW_URL;
// export const INSPECTOR_SHOW_ALL_INACTIVE_ITEMS_URL = INSPECTOR_ITEMS_URL + "/inactive_items";
// export const INSPECTOR_EDIT_SINGLE_BICYCLE_URL = INSPECTOR_ITEMS_URL + SHOW_SINGLE_URL + "/" + COL + ITEM_ID_PARAM + "/update";
// export const INSPECTOR_BRAND_URL = INSPECTOR_URL + "/brands";
// export const INSPECTOR_CREATE_NEW_BRAND_URL = INSPECTOR_BRAND_URL + CREATE_NEW_URL;
// export const INSPECTOR_SHOW_ALL_BRANDS_URL = INSPECTOR_BRAND_URL + "/items";
// export const INSPECTOR_SHOW_SINGLE_BRAND_URL = INSPECTOR_BRAND_URL + SHOW_SINGLE_URL + "/" + COL + BRAND_ID_PARAM;
// export const INSPECTOR_EDIT_SINGLE_BRAND_URL = INSPECTOR_BRAND_URL + SHOW_SINGLE_URL + "/" + COL + BRAND_ID_PARAM + "/update";