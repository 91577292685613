import React, {Component} from 'react';
import SpinLoaderWithText from "../loader/SpinLoaderWithText";
import {DEFAULT_ERROR_MSG} from "../../../constants/Constant";

class LoadingErrorOrComponent extends Component {
    DEFAULT_LOADING_TEXT = "Loading";

    render() {
        return (
            <div className={"flex-fill d-flex justify-content-center align-items-center"}>
                {
                    this.props.is_loading && <div className={"d-flex"}>
                        <SpinLoaderWithText content={this.props?.loading_text || this.DEFAULT_LOADING_TEXT} />
                    </div>
                }
                {
                    this.props.is_loading_fail && <div className={"d-flex p-5 justify-content-center"}>
                        <p className={"text-muted small"}>
                            {DEFAULT_ERROR_MSG}
                        </p>
                    </div>
                }
                {
                    !(this.props.is_loading || this.props.is_loading_fail) &&
                    <this.props.component/>
                }
            </div>
        );
    }
}

export default LoadingErrorOrComponent;