import React, {Component} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {PRIVACY_POLICY_URL} from "../policies/urls/PoliciesUrl";
import {BICYCLE_COMPANY_EMAIL} from "../../constants/Constant";

class Footer extends Component {
    name = "Bicycle Company";

    render() {
        return(
            <section className={"bg-dark"} style={{background:"#343a40"}}>
                <Container className={"text-white p-4"}>
                    <Row>
                        <Col md={4}>
                            <div className={"py-2"}>
                                <b>Policies</b>
                                <div className={"text-secondary"}>
                                    <Link to={PRIVACY_POLICY_URL} className={"no-text-decor"}>
                                        <p>Privacy Policy</p>
                                    </Link>
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className={"py-2"}>
                                <b>Contact us</b>
                                <div className={"text-secondary"}>
                                    <p>Bicycle Company</p>
                                    <a href={"mailto:" + BICYCLE_COMPANY_EMAIL } rel="noreferrer" target="_blank" className={"no-text-decor"}>
                                        <p className={"my-1"}>
                                            <FontAwesomeIcon icon={["fab", "google"]} className={"me-1"} />
                                            {BICYCLE_COMPANY_EMAIL}
                                        </p>
                                    </a>
                                    <a href={"https://www.facebook.com/pixiemartindia"} rel="noreferrer" target="_blank" className={"no-text-decor"}>
                                        <p className={"my-1"}>
                                            <FontAwesomeIcon icon={["fab", "facebook"]} className={"me-1"} />
                                            Facebook
                                        </p>
                                    </a>
                                    <a href={"https://www.instagram.com/pixiemartindia/"} rel="noreferrer" target="_blank" className={"no-text-decor"}>
                                        <p className={"my-1"}>
                                            <FontAwesomeIcon icon={["fab", "instagram"]} className={"me-1"} />
                                            Instagram
                                        </p>
                                    </a>
                                </div>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className={"py-2"}>
                                <b>Address</b>
                                <div className={"text-secondary"}>
                                    <p className={"m-0"}>Doddakannelli Rd, Bellandur</p>
                                    <p>Bengaluru, Karnataka</p>
                                    <p>560035</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className={"text-center text-secondary border-top border-secondary p-2"}>
                    {this.name} | Pixie Mart
                </div>
            </section>
        );
    }
}

export default Footer;
