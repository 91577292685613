import React, {Component} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import inspection_image from "../../../statics/img/home/sellin3steps/inspection.png";
import payment_image from "../../../statics/img/home/sellin3steps/payment.png";
import search_bicycle_image from "../../../statics/img/home/sellin3steps/search_bicycle.png";

class SellSteps extends Component {
    render() {
        return (
            <section className={"py-5 bg-white"}>
                <div className={"mb-4"}>
                    <h2 className={"text-center p-2 fw-bold mt-3 fs_heading2"}>Sell your bicycle in just 3 steps</h2>
                    <div className={"d-flex justify-content-center p-2"}>
                        <p className={"text-muted text-center col-md-3 fs_75c_4"}>
                            Book a free pickup from your home or work at a time slot as per your convenience
                        </p>
                    </div>
                </div>
                <Container>
                    <Row className={"g-0"}>
                        <Col md={4} className={"p-2"}>
                            <div className={"text-center"}>
                                <div className={"px-5"} style={{height: 240}}>
                                    <img src={search_bicycle_image} className={"img-object-fit-contain"} alt={"search_bicycle_image"} />
                                </div>
                                <h3 className={"fw-bold fs_75c_10 p-2"}>
                                    Search Bicycle Model
                                </h3>
                                <p className={"text-muted fs_75c_4"}>
                                    Search your brand and model & enter the details of your bicycle and upload images and then sent for inspection.
                                </p>
                            </div>
                        </Col>
                        <Col md={4} className={"p-2"}>
                            <div className={"text-center"}>
                                <div className={"px-5"} style={{height: 240}}>
                                    <img src={inspection_image} className={"img-object-fit-contain"} alt={"inspection_image"} />
                                </div>
                                <h3 className={"fw-bold fs_75c_10 p-2"}>
                                    Inspection And Pickup
                                </h3>
                                <p className={"text-muted fs_75c_4"}>
                                    Inspector will inspect your bicycle at your address and estimate the price, post that the pickup will schedule
                                </p>
                            </div>
                        </Col>
                        <Col md={4} className={"p-2"}>
                            <div className={"text-center"}>
                                <div className={"px-5"} style={{height: 240}}>
                                    <img src={payment_image} className={"img-object-fit-contain"} alt={"payment_image"} />
                                </div>
                                <h3 className={"fw-bold fs_75c_10 p-2"}>
                                    Get Your Payment Instantly
                                </h3>
                                <p className={"text-muted fs_75c_4"}>
                                    As soon as your bicycle price estimate and while pickup your payment made through the any payment methods.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}

export default SellSteps;