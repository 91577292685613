import React, {Component} from 'react';
import {Row} from "react-bootstrap";
import SectionItem from "./SectionItem";

class SectionItems extends Component {
    render() {
        return (
            <div>
                <h3 className={"fs_75c_10 fw-bold"}>{this.props.label}</h3>
                {/*<Link to={"###"}>More</Link>*/}
                <Row className={"g-0"}>
                    {
                        this.props.items.map((item, index) => {
                            return <SectionItem key={"section_item_" + index} item={item} />
                        })
                    }
                </Row>
            </div>
        );
    }
}

export default SectionItems;