import React, {Component} from 'react';
import {Container} from "react-bootstrap";
import SquarePanelComponent from "../../general/selectable_square/SquarePanelComponent";
import {BackendGETRequest} from "../../../webrequests/BackendRequest";
import {GET_INSPECTION_INFO_API} from "../../../constants/backend/APIConstant";
import {Connector} from "../../../../../redux/Connector";
import {isNullOrUndefinedObject} from "../../../utils/validators/Validator";
import {BASE_APPROVAL_URL, SELLING_APPROVAL_COMPLETED_URL, SELLING_APPROVAL_URL} from "./urls/ApprovalsUrls";

class ApprovalsHome extends Component {
    state = {
        is_loading: true,
        render: [
            {
                name:"Pending Payment Approval",
                count: 0,
                key: "sellingBicycleApproval",
                link: SELLING_APPROVAL_URL
            },
            {
                name:"Completed Payment Approval",
                key: "completedPaymentApproval",
                link: SELLING_APPROVAL_COMPLETED_URL
            }
        ]
    }

    componentDidMount() {
        BackendGETRequest(this.props, GET_INSPECTION_INFO_API, (response) => {
            let localRender = this.state.render;
            localRender.forEach(render => {
                if(!isNullOrUndefinedObject(response.body[render.key])) {
                    render.count = response.body[render.key];
                }
            });

            this.setState({
                is_loading: false
            });
        }, (error) => this.setState({
            is_loading: false
        }));
    }


    render() {
        return (
            <div>
                <Container className={"pt-5p"}>
                    <h3 className={"px-2"}>Approvals Account</h3>
                    <SquarePanelComponent render={this.state.render} base_url={BASE_APPROVAL_URL} isLoading={this.state.is_loading} />
                </Container>
            </div>
        );
    }
}

export default Connector(ApprovalsHome);