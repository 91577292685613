import React, {Component} from 'react';
import {Button} from "react-bootstrap";
import {GoogleAuth} from "@codetrix-studio/capacitor-google-auth";
import google_icon from "../../statics/img/google/google_icon.png";
import Authenticate from "../../webrequests/requestwrapper/Authenticate";
import {Connector} from "../../../../redux/Connector";

class GoogleAuthenticateAndroid extends Component {
    constructor(props) {
        super(props);
        this.OnGoogleLoginClick = this.OnGoogleLoginClick.bind(this);
    }

    async OnGoogleLoginClick() {
        GoogleAuth.signIn().then(
            (user) => {
                Authenticate(this.props, user.authentication.idToken);
            }).catch((error) => {
                console.error("[OnGoogleLoginClick] user authentication", error);
            });
    }

    render() {
        return (
            <div className={"w-100"}>
                <Button variant={"transparent"} style={{minWidth: "50%"}} className={"d-flex border align-items-center"} onClick={this.OnGoogleLoginClick}>
                    <div style={{width: 34}}>
                        <img src={google_icon} alt="google icon" className={"img-object-fit-contain"} />
                    </div>
                    <div className={"d-flex flex-fill justify-content-center text-secondary"}>
                        Sign in with Google
                    </div>
                </Button>
            </div>
        );
    }
}

export default Connector(GoogleAuthenticateAndroid);