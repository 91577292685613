import React, {Component} from 'react';
import {
    BASE_SELLER_URL, SELLER_ADD_MODEL_WITH_AMAZON_ASIN_URL,
    SELLER_SHOW_ALL_BUYING_MODELS_URL
} from "../../urls/SellerUrl";
import SquarePanelsWithTitle from "../../../../general/selectable_square/SquarePanelsWithTitle";

class SellerBuyBicycle extends Component {
    state = {
        render: [
            {
                name: "Add model with amazon asin",
                link: SELLER_ADD_MODEL_WITH_AMAZON_ASIN_URL
            },
            {
                name: "Show all buying bicycle",
                link: SELLER_SHOW_ALL_BUYING_MODELS_URL
            },
        ]
    }

    render() {
        return (
            <div>
                <SquarePanelsWithTitle title={"Manage buying bicycle models"}
                                       subtitle={"Bicycle those will get sell by customers"}
                                       render={this.state.render}
                                       base_url={BASE_SELLER_URL}/>
            </div>
        );
    }
}

export default SellerBuyBicycle;