import React, {Component} from 'react';
import {Button, Container, Table} from "react-bootstrap";
import {BackendGETRequest, BackendPOSTRequest} from "../../../../../webrequests/BackendRequest";
import {
    GET_SINGLE_BICYCLE_API, DELETE_BICYCLE_API
} from "../../../../../constants/backend/APIConstant";
import {Connector} from "../../../../../../../redux/Connector";
import {
    COL,

} from "../../../../../constants/application/ApplicationUrl";
import {BreadCrumb} from "../../../../panels/breadcrum/BreadCrumb";
import {BASE_SELLER_URL, SELLER_EDIT_SINGLE_BICYCLE_URL, SELLER_BUY_BICYCLE_URL} from "../../urls/SellerUrl";
import {ITEM_ID_PARAM} from "../../../../../constants/ParamConstant";

class SellerShowSingleItem extends Component {
    state = {
        item: {},
        is_delete_btn_enabled: false
    }

    constructor(props) {
        super(props);
        this.onSingleItemsSuccessCallback = this.onSingleItemsSuccessCallback.bind(this);
        this.onItemFailureCallback = this.onItemFailureCallback.bind(this);
        this.onClickDeleteButtonFirst = this.onClickDeleteButtonFirst.bind(this);
        this.onClickDeleteButton = this.onClickDeleteButton.bind(this);
        this.onClickEditButton = this.onClickEditButton.bind(this);
    }

    componentDidMount() {
        BackendGETRequest(this.props, GET_SINGLE_BICYCLE_API.replace(COL + ITEM_ID_PARAM, this.props.match.params[ITEM_ID_PARAM]), this.onSingleItemsSuccessCallback, this.onItemFailureCallback)
    }

    onSingleItemsSuccessCallback(response) {
        this.setState({
            item: response.body
        });
    }

    onItemFailureCallback(error) {

    }

    onClickDeleteButtonFirst() {
        this.setState({
            is_delete_btn_enabled: true
        });
    }

    onClickDeleteButton() {
        BackendPOSTRequest(this.props, DELETE_BICYCLE_API.replace(COL + ITEM_ID_PARAM, this.props.match.params[ITEM_ID_PARAM]),
            {
                "itemId": this.props.match.params[ITEM_ID_PARAM]
            },
            ()=> { window.location.href = SELLER_BUY_BICYCLE_URL;},
            this.onItemFailureCallback);
    }

    onClickEditButton() {
        window.location.href = SELLER_EDIT_SINGLE_BICYCLE_URL.replace(COL + ITEM_ID_PARAM, this.props.match.params[ITEM_ID_PARAM]);
    }

    render() {
        return (
            <div>
                <Container className={"pt-5p"}>
                    <h3>Item</h3>
                    <div className={"py-2"}>
                        <BreadCrumb base_url={BASE_SELLER_URL} />
                    </div>

                    <div className={"d-flex"}>
                        <div className={"shadow-sm p-2 bg-white col-6"}>
                            <div className={""} style={{height: 300}}>
                                {
                                    this.state.item.images &&
                                    this.state.item.images.map((image, index) => <img key={"img_item_" + index} alt={"item"} src={image} className={"img-object-fit-cover"}/>)
                                }
                            </div>
                        </div>
                        <div className={"p-2 d-flex flex-column"}>
                            <Button variant={"success"} className={"m-2"} style={{width: 200}} onClick={this.onClickEditButton}>Edit this item</Button>
                            <Button variant={"success"} className={"m-2"} style={{width: 200}} onClick={()=>alert("WIP")}>Add more images</Button>
                            {
                                !this.state.is_delete_btn_enabled &&
                                <Button variant={"danger"} className={"m-2"} onClick={this.onClickDeleteButtonFirst}>Delete
                                    this item</Button>
                            }
                            {
                                this.state.is_delete_btn_enabled &&
                                <div className={"w-100"}>
                                    <div className={"px-2 fw-bold"}>
                                        Are you sure to delete this item?
                                    </div>

                                    <Button variant={"danger"} className={"m-2"} onClick={this.onClickDeleteButton}>Yes,
                                        Delete</Button>
                                </div>
                            }
                        </div>
                    </div>

                    <div className={"d-flex flex-column py-3"}>
                        <div className={"bg-white p-1"}>
                            <Table striped bordered hover>
                                <tbody>
                                {
                                    Object.entries(this.state.item).map(([key, value], index) => {
                                        return <tr key={"item_row_" + index}>
                                            <th className={"text-capitalize"} >{key} </th>
                                            <td>
                                                {value === null?"":String(value)}
                                            </td>
                                        </tr>;
                                    })
                                }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}

export default Connector(SellerShowSingleItem);