import {ADDRESS_ID_PARAM, ORDER_ID_PARAM} from "../../../../constants/ParamConstant";

export const BASE_ACCOUNT_URL = "/user/account";

// ADDRESS
export const MY_ADDRESS_URL = BASE_ACCOUNT_URL + "/address";
export const CREATE_NEW_ADDRESS_URL = MY_ADDRESS_URL + "/address/create";
export const UPDATE_ADDRESS_URL = MY_ADDRESS_URL + "/:" + ADDRESS_ID_PARAM;

// ORDERS
export const MY_ORDERS_URL = BASE_ACCOUNT_URL + "/orders";
export const MY_SINGLE_ORDER_URL = MY_ORDERS_URL + "/:" + ORDER_ID_PARAM;

// SECURITY
export const MY_SECURITY_URL = BASE_ACCOUNT_URL + "/security";