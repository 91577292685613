import {COL, CREATE_NEW_URL, SHOW_ITEM_SINGLE_URL} from "../../../../constants/application/ApplicationUrl";
import {BRAND_ID_PARAM, ITEM_ID_PARAM} from "../../../../constants/ParamConstant";

export const BASE_SELLER_URL = "/user/seller";

// SELL
export const SELLER_URL = BASE_SELLER_URL + "/cycle";
export const SELLER_SELL_BICYCLE_URL = SELLER_URL + "/sell_bicycles";
export const SELLER_CREATE_NEW_ITEM_URL = SELLER_SELL_BICYCLE_URL + CREATE_NEW_URL;
export const SELLER_SHOW_ALL_INACTIVE_ITEMS_URL = SELLER_SELL_BICYCLE_URL + "/inactive_items";
export const SELLER_SHOW_ALL_ACTIVE_ITEMS_URL = SELLER_SELL_BICYCLE_URL + "/active_items";
export const SELLER_SHOW_SINGLE_BICYCLE_URL = SELLER_SELL_BICYCLE_URL + SHOW_ITEM_SINGLE_URL + "/" + COL + ITEM_ID_PARAM;
export const SELLER_EDIT_SINGLE_BICYCLE_URL = SELLER_SELL_BICYCLE_URL + SHOW_ITEM_SINGLE_URL + "/" + COL + ITEM_ID_PARAM + "/update";
export const SELLER_BRAND_URL = SELLER_URL + "/brands";
export const SELLER_CREATE_NEW_BRAND_URL = SELLER_BRAND_URL + CREATE_NEW_URL;
export const SELLER_SHOW_ALL_BRANDS_URL = SELLER_BRAND_URL + "/items";
export const SELLER_SHOW_SINGLE_BRAND_URL = SELLER_BRAND_URL + SHOW_ITEM_SINGLE_URL + "/" + COL + BRAND_ID_PARAM;
export const SELLER_EDIT_SINGLE_BRAND_URL = SELLER_BRAND_URL + SHOW_ITEM_SINGLE_URL + "/" + COL + BRAND_ID_PARAM + "/update";

// BUY
export const SELLER_BUY_BICYCLE_URL = SELLER_URL + "/buy_bicycles";
export const SELLER_ADD_MODEL_WITH_AMAZON_ASIN_URL = SELLER_BUY_BICYCLE_URL + "/amazon_asin";
export const SELLER_SHOW_ALL_BUYING_MODELS_URL = SELLER_BUY_BICYCLE_URL + "/bicycles";