import React, {Component} from 'react';
import {Container} from "react-bootstrap";
import SquarePanelComponent from "../../../general/selectable_square/SquarePanelComponent";
import {BASE_SELLER_URL, SELLER_CREATE_NEW_BRAND_URL, SELLER_SHOW_ALL_BRANDS_URL} from "../urls/SellerUrl";

class SellerBrands extends Component {
    state = {
        render: [
            {
                name: "Create New Brand",
                link: SELLER_CREATE_NEW_BRAND_URL
            },
            {
                name: "Show All Brands",
                link: SELLER_SHOW_ALL_BRANDS_URL
            }
        ]
    }

    render() {
        return (
            <div>
                <Container className={"pt-5p"}>
                    <h3 className={"px-2"}>Manage Brands</h3>
                    <SquarePanelComponent render={this.state.render} base_url={BASE_SELLER_URL} />
                </Container>
            </div>
        );
    }
}

export default SellerBrands;