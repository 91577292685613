import React, {Component} from 'react';
import {Offcanvas} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {BUY_NOW_PAGE_URL, SELL_NOW_PAGE_URL} from "../../../constants/application/ApplicationUrl";
import {Connector} from "../../../../../redux/Connector";
import {BASE_ACCOUNT_URL, MY_ADDRESS_URL, MY_ORDERS_URL, MY_SECURITY_URL} from "../../user/account/urls/AccountUrl";
import {Link} from "react-router-dom";
import "./sidebar.css";
import {OnClickSignOut} from "../../../webrequests/requestwrapper/Authenticate";
import {GetLoginPageUrl} from "../../../utils/PixieMartUtil";

class SidePanel extends Component {
    state = {
        sections: [
            {
                title: "My bicycle",
                tabs: [
                    {
                        name: "Sell Now",
                        link: SELL_NOW_PAGE_URL
                    },
                    {
                        name: "Buy Now",
                        link: BUY_NOW_PAGE_URL
                    }
                ]
            },
            // {
            //     title: "Support and Services",
            //     tabs: [
            //         {
            //             name: "Customer Service",
            //             link: ""
            //         },
            //         {
            //             name: "Repair my bicycle",
            //             link: ""
            //         },
            //         {
            //             name: "Extensions for bicycle",
            //             link: ""
            //         }
            //     ]
            // },
            {
                title: "My options",
                is_auth_required: true,
                tabs: [
                    {
                        name: "My Account",
                        link: BASE_ACCOUNT_URL
                    },
                    {
                        name: "My orders",
                        link: MY_ORDERS_URL
                    },
                    {
                        name: "Login and Security",
                        link: MY_SECURITY_URL
                    },
                    {
                        name: "My address",
                        link: MY_ADDRESS_URL
                    }
                ]
            }
        ]
    }

    render() {
        return (
            <div>
                <Offcanvas show={this.props.show} onHide={this.props.onHide} style={{width: 280}}>
                    <Offcanvas.Header className={"bg-light px-2"} closeButton>
                        <Offcanvas.Title>
                            {
                                this.props.user.username ?
                                    <div>
                                        <FontAwesomeIcon icon={"user-circle"} className={"me-2"} size={"1x"} />
                                        {this.props.user.firstName} {this.props.user.lastName}
                                    </div>:
                                    <a href={GetLoginPageUrl()} className={"no-text-decor"}>
                                        <FontAwesomeIcon icon={"user-circle"} className={"me-2"} size={"1x"} />
                                        Login
                                    </a>
                            }
                        </Offcanvas.Title>
                        {/*<CloseButton variant={"white"} className={"shadow-none"} onClick={this.props.onHide} />*/}
                    </Offcanvas.Header>
                    <Offcanvas.Body className={"border-top p-0"}>
                        {
                            this.state.sections.filter(section => !section.is_auth_required || this.props.user.username).map((section, index) => {
                                return <div key={"s_section_" + index} className={"border-top p-2"}>
                                    <div>
                                        <h6 className={"fw-bold py-1"}>{section.title}</h6>
                                    </div>
                                    {
                                        section.tabs.map((tab, t_index) => {
                                            return <Link to={tab.link} onClick={this.props.onHide} className={"no-text-decor"} key={"s_tab_" + index + "_" + t_index}><div>
                                                <p className={"p-2 m-0 left-panel-link"}>{tab.name}</p>
                                            </div></Link>;
                                        })
                                    }
                                </div>
                            })
                        }
                    </Offcanvas.Body>
                    {
                        this.props.user.username &&
                        <Offcanvas.Header className={"p-0"}>
                            <div className={"bg-light p-3 w-100 cursor-pointer"} onClick={() => OnClickSignOut(this.props)}>
                                <FontAwesomeIcon icon={"sign-out-alt"} className={"me-1"} />
                                Sign out
                            </div>
                        </Offcanvas.Header>
                    }
                </Offcanvas>
            </div>
        );
    }
}

export default Connector(SidePanel);