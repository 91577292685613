import React, {Component} from 'react';
import {Col, Container, Form, Row} from "react-bootstrap";
import SectionItems from "../../home/itemsections/SectionItems";
import {SetDocumentTitle} from "../../../managers/DocumentTitleManager";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {BackendGETRequest} from "../../../webrequests/BackendRequest";
import {ShowErrorModalFromError} from "../../modals/centermodal/CenterModalScreenManager";
import {GET_ALL_ITEM_FOR_SELL_API} from "../../../constants/backend/APIConstant";
import {HOME_URL} from "../../../constants/application/ApplicationUrl";
import {Connector} from "../../../../../redux/Connector";
import GeneralBreadCrumb from "../../general/breadcrumb/GeneralBreadCrumb";

class BuyNowPage extends Component {
    state = {
        is_loading_items: true,
        items: []
    }

    componentDidMount() {
        SetDocumentTitle("Buying best bicycle in Bengaluru", "Pre-loved, refurbished rides for your little ones - Buying best bicycles for kids in Bengaluru at best price");
        BackendGETRequest(this.props, GET_ALL_ITEM_FOR_SELL_API, (response) => this.setState({items: response.body["bicycles"], is_loading_items: false}), (error) => ShowErrorModalFromError(this.props, "Requesting bicycle items", error));
    }

    render() {
        return (
            <section className={"bg-light"}>
                <div className={"position-relative"}>
                    <div className={"p-2 mb-3 bg-light-yellow"}>
                        <p className={"m-0 small"}>
                            All the products are refurbished & go through strict quality checks.
                            Let your little ones have the freedom to try new things, in your budget
                        </p>
                    </div>
                    <Container>
                        <Row className={"g-0"}>
                            <Col md={3} className={"px-2"}>
                                <div className={"rounded bg-white shadow-sm border mb-3"}>
                                    <div className={"border-bottom p-2 px-4 fw-bold"}>
                                        Filter
                                        <div className={"float-end"}>
                                            <FontAwesomeIcon icon={"filter"} />
                                        </div>
                                    </div>
                                    <div className={"border-bottom p-2 px-4"}>
                                        <div className={"py-2 fw-bold"}>
                                            <FontAwesomeIcon icon={"chevron-down"} className={"me-2"} />
                                            Brand
                                        </div>
                                        <div>
                                            <Form.Check label={"Firefox"} />
                                            <Form.Check label={"HRX"} />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col md={9}>
                                <div className={"px-2 pb-3"}>
                                    <GeneralBreadCrumb items={[{title: "Home", link: HOME_URL}, {title: "Buy"}]} />
                                </div>
                                <div className={"px-2"}>
                                    <div className={"bg-light-blue p-2 rounded"} style={{height: 200}}>
                                        <h3 className={"py-3 fw-bold bangers-font"}>
                                            Pre-loved, Refurbished rides for your little ones
                                        </h3>
                                        <p className={"m-0"}>Purchase gently used and carefully refurbished rides for kids</p>
                                    </div>
                                </div>
                                <SectionItems label={""} items={this.state.items} />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        );
    }
}

export default Connector(BuyNowPage);