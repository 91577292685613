import React, {Component} from 'react';
import {isEmptyString, isNullOrUndefinedObject} from "../../../../utils/validators/Validator";
import {Button, Dropdown, FormControl} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {GetDefaultBrandUrl} from "../../../../utils/PixieMartUtil";
import {BrandSelectComponent} from "../../../spages/sell/BrandSelectComponent";

class SearchBarComponent extends Component {
    
    state = {
        search_text: ""
    }

    constructor(props) {
        super(props);
        this.OnSearchTextChange = this.OnSearchTextChange.bind(this);
        this.FilterBrandsSearchResult = this.FilterBrandsSearchResult.bind(this);
        this.SearchComponent = this.SearchComponent.bind(this);
        this.OnClickItem = this.OnClickItem.bind(this);
    }

    OnClickItem() {
        this.setState({
            search_text: ""
        });
    }

    FilterBrandsSearchResult() {
        let filterBrands = this.props.brands.filter(brand => isEmptyString(this.state.search_text) || brand.name.toLowerCase().replace(" ","").includes(this.state.search_text.toLowerCase().replace(" ","")));
        if(filterBrands.length === 0) {
            return <></>;
        }

        return <Dropdown.Item className={"bg-white"}>
            <div className={"border-bottom"}>
                <p className={"text-muted small m-0"}>
                    Select from bicycle brands to start sell
                </p>
                <div className={"d-flex flex-wrap"}>
                    {
                        filterBrands.map(brand => <div className={"p-2 col-3 col-sm-2 col-md-2 col-lg-1"}>
                            <BrandSelectComponent brand={brand} />
                        </div>)
                    }
                </div>
            </div>
        </Dropdown.Item>
    }
    
    SearchComponent() {
        if(isEmptyString(this.state.search_text) || this.state.search_text.length < 2 || isNullOrUndefinedObject(this.props.brands)) {
            return <></>;
        }

        return <Dropdown.Menu className={"w-100 shadow"}>
            <this.FilterBrandsSearchResult />

            <Dropdown.Item className={"bg-white"}>
                <div className={"d-flex"}>
                    <div className={"p-1 fw-bold d-flex align-items-center"}>
                        <Button variant={"outline-primary"} as={Link} to={GetDefaultBrandUrl()} onClick={this.OnClickItem}>
                            Start selling now
                        </Button>
                    </div>
                </div>
            </Dropdown.Item>
        </Dropdown.Menu>
    }

    OnSearchTextChange(event) {
        this.setState({
            search_text: event.target.value.toLowerCase()
        });
    }
    
    render() {
        if(isNullOrUndefinedObject(this.props.brands)) {
            return <></>
        }

        return (
            <Dropdown show={true} style={{minWidth: "75%"}}>
                <Dropdown.Toggle variant={"transparent"} className={"w-100 p-0 shadow-none"} bsPrefix={"g-0"}>
                    <div className={"w-100 position-relative"}>
                        <div className={"position-absolute h-100 d-flex align-items-center"} style={{left: "0.32rem", top: "0"}}>
                            <div className={"p-1 bg-px-blue rounded-circle d-flex align-items-center justify-content-center"} style={{width: 30, height: 30}}>
                                <FontAwesomeIcon icon={"search"} color={"white"} />
                            </div>
                        </div>
                        <FormControl value={this.state.search_text} onChange={this.OnSearchTextChange} placeholder={"Search for your brand or model"} className={"rounded-pm-1 w-100"} style={{ paddingLeft: "2.8rem"}} />
                        <div className={"position-absolute h-100 d-flex align-items-center"} style={{right: "0.32rem", top: "0"}}>
                            {
                                (!isEmptyString(this.state.search_text)) &&
                                <div className={"p-1 rounded-circle d-flex align-items-center justify-content-center cursor-pointer"} style={{width: 30, height: 30}} onClick={this.OnClickItem}>
                                    <FontAwesomeIcon icon={"times"} color={"grey"} />
                                </div>
                            }
                        </div>
                    </div>
                </Dropdown.Toggle>

                <this.SearchComponent />
            </Dropdown>
        );
    }
}

export default SearchBarComponent;