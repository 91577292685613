import React, {Component} from 'react';
import {Badge} from "react-bootstrap";
import "./addressviewcomponent.css";

class AddressViewComponent extends Component {
    render() {
        return (
            <div className={"px-2 position-relative address_view_component h-100 w-100"}>
                <div className={"fw-bold"}>{this.props.address.firstName} {this.props.address.lastName}</div>

                <div className={"p-2 small text-secondary"}>
                    <div>
                        {this.props.address.mobile}
                    </div>
                    <div>
                        {this.props.address.addressLine1}
                    </div>
                    <div>
                        {this.props.address.addressLine2}
                    </div>
                    <div>
                        {this.props.address.landmark}
                    </div>
                    <div>
                        {this.props.address.city}, {this.props.address.state}, {this.props.address.pinCode}
                    </div>
                    <div>
                        {this.props.address.instruction}
                    </div>
                    <div className={"py-2"}>
                        <Badge bg={"secondary"}>{this.props.address.addressType}</Badge>
                    </div>
                </div>
            </div>
        );
    }
}

export default AddressViewComponent;