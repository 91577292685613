import React, {Component} from 'react';
import {Button, Col, Container, FormControl, Row, Spinner} from "react-bootstrap";
import {BackendPOSTRequest, BackendPOSTRequestWithImage} from "../../../../webrequests/BackendRequest";
import {CREATE_BRAND_API, UPDATE_BRAND_IMAGE} from "../../../../constants/backend/APIConstant";
import {Connector} from "../../../../../../redux/Connector";
import {COL} from "../../../../constants/application/ApplicationUrl";
import {BreadCrumb} from "../../../panels/breadcrum/BreadCrumb";
import {ShowOkButtonErrorModal} from "../../../modals/centermodal/CenterModalScreenManager";
import {BASE_SELLER_URL, SELLER_SHOW_SINGLE_BRAND_URL} from "../urls/SellerUrl";
import {CreteImageForm, JsonFromFormEvent} from "../../../../utils/FormManager";
import {DEFAULT_ERROR_MSG} from "../../../../constants/Constant";
import {BRAND_ID_PARAM} from "../../../../constants/ParamConstant";

class SellerCreateItem extends Component {
    state = {
        imageUrl: "",
        imageBlob: null,
        is_loading: false
    }

    constructor(props) {
        super(props);
        this.onChangeImage = this.onChangeImage.bind(this);
        this.HandlerSubmitBrandCreateForm = this.HandlerSubmitBrandCreateForm.bind(this);
        this.OnSuccessCreateBrand = this.OnSuccessCreateBrand.bind(this);
        this.OnFailureCreateBrand = this.OnFailureCreateBrand.bind(this);

        this.image_input = React.createRef();
    }

    onChangeImage(event) {
        if(event.target.files.length  <= 0 ) {
            return this.setState({
                imageUrl: "",
                imageBlob: null
            });
        }

        this.setState({
            imageUrl: URL.createObjectURL(event.target.files[0]),
            imageBlob: event.target.files[0]
        });
    }

    HandlerSubmitBrandCreateForm(event) {
        event.preventDefault();

        this.setState({
            is_loading: true
        });

        BackendPOSTRequest(this.props, CREATE_BRAND_API, JsonFromFormEvent(event), this.OnSuccessCreateBrand, this.OnFailureCreateBrand);
        return false;
    }

    OnSuccessCreateBrand(response) {
        let formData = CreteImageForm("brandId", response.body.id, this.state.imageBlob);

        BackendPOSTRequestWithImage(this.props, UPDATE_BRAND_IMAGE.replace(COL + BRAND_ID_PARAM, response.body.id), formData, () => {
            this.setState({
                is_loading: false
            });
            window.location.href = SELLER_SHOW_SINGLE_BRAND_URL.replace(COL + BRAND_ID_PARAM, response.body.id);
        }, this.OnFailureCreateBrand);
    }

    OnFailureCreateBrand(error) {
        this.setState({
            is_loading: false
        });

        let content = DEFAULT_ERROR_MSG;
        if(error.response !== undefined && error.response.data !== null) {
            content = error.response.data.message;
        }

        ShowOkButtonErrorModal(this.props, "Brand Create Issue", content);
    }

    render() {
        return (
            <div>
                <Container className={"pt-5p"}>
                    <h3>Create New Brand</h3>
                    <div className={"py-2"}>
                        <BreadCrumb base_url={BASE_SELLER_URL} />
                    </div>

                    <div className={"bg-white shadow-sm p-2 position-relative rounded"}>
                        <form onSubmit={this.HandlerSubmitBrandCreateForm}>
                            <Row className={"g-0"}>
                                <Col md={3} className={"p-2"}>
                                    <div className={"d-flex justify-content-center"}>
                                        <div style={{height: 264, width: 264}} className={"mb-3 border border-secondary bg-light rounded"} onClick={()=> {this.image_input.current.click()}}>
                                            {
                                                this.state.imageUrl &&
                                                <img src={this.state.imageUrl} alt={"selected-item"}
                                                     className={"img-object-fit-cover"}/>
                                            }
                                        </div>
                                    </div>
                                    <FormControl required ref={this.image_input} type={"file"} name={"image"} onChange={this.onChangeImage} />
                                </Col>
                                <Col md={9} className={"p-2"}>
                                    <label>
                                        Name of the brand
                                    </label>
                                    <FormControl name={"name"} placeholder={"Eg. Firefox, Atlas"} required minLength={3} />

                                    <div className={"py-4"}>
                                        <Button variant={"primary"} type={"submit"} className={"shadow-none"}>
                                            Submit
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </form>

                        {
                            this.state.is_loading &&
                            <div className={"wrapper d-flex flex-column text-white justify-content-center align-items-center position-absolute top-0 start-0 w-100 h-100"}
                                 style={{backgroundColor: "rgba(0,0,0,0.7)"}} >
                                <Spinner animation={"border"} />
                                <p className={"p-2"}>
                                    Wait for creating new brand
                                </p>
                            </div>
                        }
                    </div>
                </Container>
            </div>
        );
    }
}

export default Connector(SellerCreateItem);