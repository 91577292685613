import {BRAND_ID_PARAM, ITEM_ID_PARAM} from "../ParamConstant";

export const ROOT_SLASH_URL = "/";
export const HOME_URL = "/";
export const CREATE_NEW_URL = "/new/create";
export const SHOW_ITEM_SINGLE_URL  = "/item";
export const COL = ":";
export const PARAM_SEPARATOR = ROOT_SLASH_URL + COL;

export const ITEM_BASE_URL = "/item";
export const ITEM_URL = ITEM_BASE_URL + PARAM_SEPARATOR + ITEM_ID_PARAM;

export const SELL_NOW_PAGE_URL = "/user/item/sell";
export const SHOW_ALL_USER_BRANDS_URL = SELL_NOW_PAGE_URL + "/brands";
export const SHOW_ALL_USER_MODELS_FOR_BRAND_URL = SHOW_ALL_USER_BRANDS_URL + PARAM_SEPARATOR + BRAND_ID_PARAM + "/models";

export const BUY_NOW_PAGE_URL = "/user/item/buy";
export const SELL_BICYCLE_NOW_PAGE_URL = SELL_NOW_PAGE_URL + "/bicycle";
export const GET_SELL_BICYCLE_API = SELL_BICYCLE_NOW_PAGE_URL + "/:A";
export const BUY_BICYCLE_NOW_PAGE_URL = BUY_NOW_PAGE_URL + "/bicycle";

export const LOGIN_PAGE_URL = "/user/login";
