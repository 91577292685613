import React, {Component} from 'react';
import {Button, Container, FormControl, Spinner} from "react-bootstrap";
import {BreadCrumb} from "../../../panels/breadcrum/BreadCrumb";
import LoadingErrorOrComponent from "../../../general/templates/LoadingErrorOrComponent";
import {BASE_APPROVAL_URL} from "../urls/ApprovalsUrls";
import {Connector} from "../../../../../../redux/Connector";
import {BackendGETRequest, BackendPOSTRequest} from "../../../../webrequests/BackendRequest";
import {
    APPROVE_ORDER_FOR_PAYMENT_API,
    GET_ALL_ORDERS_AVAILABLE_FOR_APPROVAL_API
} from "../../../../constants/backend/APIConstant";
import OrderViewComponent from "../../account/orders/OrderViewComponent";
import {ShowErrorModalFromError} from "../../../modals/centermodal/CenterModalScreenManager";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {JsonFromFormEvent} from "../../../../utils/FormManager";

class PendingForPaymentApprovals extends Component {

    state = {
        is_loading: true,
        is_loading_fail: false,
        orders: []
    }

    constructor(props) {
        super(props);
        this.PendingForPaymentApprovalComponent = this.PendingForPaymentApprovalComponent.bind(this);
        this.OnSubmitApproval = this.OnSubmitApproval.bind(this);
    }

    componentDidMount() {
        if(this.props.user.username) {
            BackendGETRequest(this.props, GET_ALL_ORDERS_AVAILABLE_FOR_APPROVAL_API, (response) => {
                this.setState({orders: response.body["orders"], is_loading: false});
            }, (error) => {
                this.setState({
                    is_loading: false,
                    is_loading_fail: true
                });
                ShowErrorModalFromError(this.props, "Approval Orders", error);
            });
        }
    }

    OnSubmitApproval(event) {
        event.preventDefault();
        let orders = this.state.orders;
        let singleOrder = orders[event.target.getAttribute("data-index")];
        singleOrder.loading = true;
        singleOrder.failed = false;

        this.setState({
            orders: orders
        });

        BackendPOSTRequest(this.props, APPROVE_ORDER_FOR_PAYMENT_API, JsonFromFormEvent(event), (response) => {
            singleOrder.completed = true;
        }, (error) => {
            singleOrder.loading = false;
            singleOrder.failed = true;
            this.setState({
                orders: orders
            });
        });
        return false;
    }

    PendingForPaymentApprovalComponent() {
        if(this.state.orders.length <=0 ){
            return <div className={"flex-fill d-flex justify-content-center align-items-center"}>
                <div className={"text-muted"}>
                    No orders for approval
                </div>
            </div>
        }

        return <div className={"flex-fill row g-0 py-2"}>
            {
                this.state.orders.filter(order => !order.completed).map((order, index) => <div key={"order_i_" + index} className={"col-md-4 p-1 d-flex"}>
                    <div className={"flex-fill bg-white rounded shadow-sm d-flex flex-column"}>
                        <div className={"flex-fill"}>
                            <OrderViewComponent key={"order_index_" + index} order={order} withoutLink={true} />
                        </div>
                        <div className={"p-2"}>
                            <div className={"pb-2"}>
                                <div className={"bg-light-primary p-2 rounded"}>
                                    <div className={"text-muted fw-bold"}>
                                        Estimated price
                                    </div>
                                    <h3 className={"fw-bold"}>
                                        <FontAwesomeIcon icon={"rupee-sign"} className={"me-1"} />
                                        {order.estimatedPriceByInspector}
                                    </h3>
                                </div>
                            </div>

                            {
                                order.failed && <div className={"text-danger small"}>
                                    Failed to approve this order
                                </div>
                            }

                            <form onSubmit={this.OnSubmitApproval} data-index={index} >
                                <FormControl className={"visually-hidden"} name="id" defaultValue={order.id} />
                                <FormControl className={"visually-hidden"} name="estimatedPriceByInspector" defaultValue={order.estimatedPriceByInspector} />
                                <Button variant={"success"} type={"submit"} disabled={order.loading}>
                                    {
                                        order.loading? <Spinner animation={"border"} size={"sm"} className={"me-1"} />:<FontAwesomeIcon icon={"check-circle"} className={"me-1"} />
                                    }
                                    Approve Payment
                                </Button>
                            </form>
                        </div>
                    </div>
                </div>)
            }
        </div>
    }

    render() {
        return (
            <div className={"flex-fill flex-column d-flex"}>
                <Container className={"pt-3 flex-fill d-flex flex-column"}>
                    <h3 className={""}>Bicycles available to inspect</h3>
                    <div className={"py-2"}>
                        <BreadCrumb base_url={BASE_APPROVAL_URL} />
                    </div>
                    <LoadingErrorOrComponent is_loading={this.state.is_loading}
                                             loading_text={"Loading payment approvals"}
                                             is_loading_fail={this.state.is_loading_fail}
                                             component={this.PendingForPaymentApprovalComponent} />
                </Container>
            </div>
        );
    }
}

export default Connector(PendingForPaymentApprovals);