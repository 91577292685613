import {Container} from "react-bootstrap";
import {SellBicycleNowButton} from "../sections/SellPurchaseButtons";
import web_c_1_image from "../../../statics/img/home/web_c_1.webp";
import React from "react";
import "./carouselhome.css";

export function TradeUpAndCashIn() {
    return <div className={""}>
        <div className={"w-100 h-100 p-2 pb-4"} style={{background: `url(${web_c_1_image})`, backgroundSize: "auto 100%", backgroundPosition: "top left"}}>
            <Container>
                <div className={"overflow-hidden py-4 home_container"}>
                    <h1 className={"fw-bold fs_heading pb-3"}>
                        Cash In on Your Ride: Sell Your Bicycle Fast & Easy
                    </h1>
                    <p className={"fs_75c_10 m-0"}>
                        We purchase bikes of all types, from mountain bikes to road bikes.
                    </p>
                    <p className={"fs_75c_10"}>
                        Our simple, fast, and fair process ensures you get paid quickly, with no hassle.
                        Sell your bike today and unlock cash for your next adventure.
                    </p>
                    <SellBicycleNowButton />
                </div>
            </Container>
        </div>
    </div>;
}

export function EffortLessComponent() {
    return <div className={""}>
        <div className={"w-100 h-100 p-2 pb-4"} style={{background: `url(${web_c_1_image})`, backgroundSize: "auto 100%", backgroundPosition: "top left"}}>
            <Container>
                <div className={"overflow-hidden py-4 home_container"}>
                    <h2 className={"fw-bold fs_heading pb-3"}>
                        Effortless Transactions for smooth & safe rides
                    </h2>
                    <p className={"fs_75c_10 m-0"}>
                        Have to upgrade to a new size every few months.
                    </p>
                    <p className={"fs_75c_10"}>
                        Your little one deserves the best experience.
                    </p>
                    {/*<SellPurchaseButtons />*/}
                    <SellBicycleNowButton />
                </div>
            </Container>
        </div>
    </div>;
}
