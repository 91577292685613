import React, {Component} from 'react';
import {Button} from "react-bootstrap";
import {BrandSelectComponent} from "../../../spages/sell/BrandSelectComponent";
import {Link} from "react-router-dom";
import {SELL_BICYCLE_NOW_PAGE_URL} from "../../../../constants/application/ApplicationUrl";
import "./sellyourbicycle.css";

class SellYourBicycle extends Component {
    render() {
        return (
            <section className={"d-flex justify-content-center bg-white pb-4 px-2"}>
                <div className={"bg-white shadow rounded-pm-1 border p-4 sell_your_bicycle_box overflow-hidden"} >
                    <div className={"mb-4"}>
                        <h2 className={"text-center p-2 fw-bold mt-3 fs_75c_10"}>Are you looking for to sell your old bicycle</h2>
                        <div className={"d-flex justify-content-center"}>
                            <p className={"text-muted text-center fs_75c_3"}>
                                Start selling your second hand bicycle
                            </p>
                        </div>
                    </div>
                    <div className={"my-3 position-relative justify-content-center overflow-hidden"} style={{height: 74}}>
                        <div className={"d-flex scroll-element primary"}>
                            {
                                this.props.brands.map((brand, index) => <div key={"brand_i_" + index}
                                                                             className={"p-1"}
                                                                             style={{width: 74}}>
                                    <BrandSelectComponent brand={brand} />
                                </div>)
                            }
                        </div>
                        <div className={"d-flex scroll-element secondary position-absolute"}>
                            {
                                this.props.brands.map((brand, index) => <div key={"brand_i_" + index}
                                                                             className={"p-1 flex-shrink-0"}
                                                                             style={{width: 74}}>
                                    <BrandSelectComponent brand={brand} />
                                </div>)
                            }
                        </div>
                        <div className={"position-absolute h-100 w-100"}></div>
                    </div>
                    <div className={"text-center"}>
                        <Button as={Link} to={SELL_BICYCLE_NOW_PAGE_URL}>
                            Start selling bicycle now
                        </Button>
                    </div>
                </div>
            </section>
        );
    }
}

export default SellYourBicycle;
