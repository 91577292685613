import React, {Component} from 'react';
import {Route, Switch} from "react-router-dom";
import {
    INSPECTOR_ITEMS_URL,
    INSPECTOR_SHOW_ALL_PENDING_ITEMS_URL,
    INSPECTOR_SHOW_SINGLE_PENDING_BICYCLE_URL,
    INSPECTOR_URL
} from "./InspectorUrl";
import InspectorHome from "../InspectorHome";
import InspectorItems from "../bicycles/InspectorItems";
import InspectorPendingItems from "../bicycles/InspectorPendingItems";
import ShowSinglePendingItem from "../bicycles/ShowSinglePendingItem";

class InspectorUrlComponent extends Component {
    render() {
        return (
            <Switch>
                <Route path={INSPECTOR_URL} exact={true} component={InspectorHome} />
                <Route path={INSPECTOR_ITEMS_URL} exact={true} component={InspectorItems} />
                <Route path={INSPECTOR_SHOW_ALL_PENDING_ITEMS_URL} exact={true} component={InspectorPendingItems} />
                <Route path={INSPECTOR_SHOW_SINGLE_PENDING_BICYCLE_URL} exact={true} component={ShowSinglePendingItem} />
            </Switch>
        );
    }
}

export default InspectorUrlComponent;