import React, {Component} from 'react';
import {Button, Container} from "react-bootstrap";
import {BASE_INSPECTOR_URL, INSPECTOR_SHOW_SINGLE_PENDING_BICYCLE_URL} from "../urls/InspectorUrl";
import {BreadCrumb} from "../../../panels/breadcrum/BreadCrumb";
import {Link} from "react-router-dom";
import {
    GET_ORDERS_AVAILABLE_FOR_MY_INSPECTION_API
} from "../../../../constants/backend/APIConstant";
import {COL} from "../../../../constants/application/ApplicationUrl";
import {BackendGETRequest} from "../../../../webrequests/BackendRequest";
import {ShowErrorModalFromError} from "../../../modals/centermodal/CenterModalScreenManager";
import {Connector} from "../../../../../../redux/Connector";
import InspectionOrder from "./InspectionOrder";
import {ORDER_ID_PARAM} from "../../../../constants/ParamConstant";
import LoadingErrorOrComponent from "../../../general/templates/LoadingErrorOrComponent";

class InspectorPendingItems extends Component {
    state = {
        is_loading: true,
        is_loading_fail: false,
        response: {
            pendingInspection: 0,
            orderResponses: {
                orders: []
            }
        }
    }

    constructor(props) {
        super(props);
        this.OnClickDiscardButton = this.OnClickDiscardButton.bind(this);
        this.PendingItemComponent = this.PendingItemComponent.bind(this);
    }

    componentDidMount() {
        BackendGETRequest(this.props, GET_ORDERS_AVAILABLE_FOR_MY_INSPECTION_API, (response) => {
            this.setState({
                is_loading: false,
                response: response.body
            });
        }, (error) => {
            this.setState({
                is_loading: false,
                is_loading_fail: true
            });

            ShowErrorModalFromError(this.props, "Inspection Error", error);
        });
    }

    OnClickDiscardButton() {
        alert("This feature is coming soon!");
    }

    PendingItemComponent() {
        return <div>
            {
                this.state.response.orderResponses !== null && this.state.response.orderResponses.orders.length > 0 ?
                    this.state.response.orderResponses.orders.map((order, index) => <div key={"order_i_" + index} className={"no-text-decor py-2"}>
                        <div className={"shadow p-2 bg-white rounded-3"}>
                            <InspectionOrder order={order} />
                            <div className={"mt-4"}>
                                <div className={"text-secondary"}>
                                    <p className={""}> Please reach at location and start the inspection,
                                        this bicycle will move back to available inspection automatically after 24 hrs from when you add in your inspection list</p>
                                </div>

                                <Button onClick={this.OnClickDiscardButton} variant={"danger"} className={"me-2"}>
                                    Discard inspection
                                </Button>

                                <Button as={Link} to={INSPECTOR_SHOW_SINGLE_PENDING_BICYCLE_URL.replace(COL + ORDER_ID_PARAM, order.id)}>
                                    Start the inspection
                                </Button>
                            </div>
                        </div>
                    </div>): <div className={"d-flex justify-content-center p-5"}>
                        <p className={"text-muted"}>
                            No pending inspections are available
                        </p>
                    </div>
            }
        </div>;
    }

    render() {
        return (
            <div className={"flex-fill flex-column d-flex"}>
                <Container className={"pt-3 flex-fill d-flex flex-column"}>
                    <h3 className={""}>Pending Bicycles inspection</h3>
                    <div className={"py-2"}>
                        <BreadCrumb base_url={BASE_INSPECTOR_URL} />
                    </div>

                    <LoadingErrorOrComponent isLoading={this.state.is_loading}
                                             loading_text={"Loading pending inspection"}
                                             is_loading_fail={this.state.is_loading_fail}
                                             component={this.PendingItemComponent} />
                </Container>
            </div>
        );
    }
}

export default Connector(InspectorPendingItems);