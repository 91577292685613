import React from "react";
import {Container} from "react-bootstrap";

export function SellFAQ() {
    return <section className={"py-5 p-2 bg-light-blue2"}>
        <Container>
            <h3 className={"fw-bold text-center pb-4 fs_heading"}>
                Frequently Asked Questions
            </h3>
            <div>
                <h4 className={"fw-bold fs_75c_10"}>
                    Why to sell Bicycle Company?
                </h4>
                <p className={"fs_75c_4"}>
                    First is the best price and expert inspection, our inspector will ensure that you'll get the best selling price affordable in the market.
                    You don't need to worry about the selling your bicycle, as we are directly purchasing it and quick payment at pickup time and all process are smooth.
                    Complete refund back in case you feel to get back your cycle within 2 days of selling.
                </p>
            </div>
            <div>
                <h4 className={"fw-bold fs_75c_10"}>
                    How much time it'll take to complete selling process ?
                </h4>
                <p className={"fs_75c_4"}>
                    Usually based on your location, our inspector will try visit your place as soon as possible. Once the inspection will complete the pickup process will happen within 7 days max in normal case.
                    (Normal case - dont have any issues or defects in bicycle).
                </p>
            </div>
        </Container>
    </section>;
}