import React, {Component, createRef} from 'react';
import Indicators from "../../../general/indicators/Indicators";
import {Button, FormControl, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Connector} from "../../../../../../redux/Connector";
import {ImageUploadPreview} from "../../../general/ImageUploadPreview";
import {CreteImageForm} from "../../../../utils/FormManager";
import {BackendPOSTRequest, BackendPOSTRequestWithImage} from "../../../../webrequests/BackendRequest";
import {
    ADD_INSPECTION_QUE_ORDER_IMAGE_API,
    COMPLETE_BICYCLE_FOR_INSPECTION_API
} from "../../../../constants/backend/APIConstant";
import {ShowErrorModalFromError} from "../../../modals/centermodal/CenterModalScreenManager";
import {isNullOrUndefinedObject} from "../../../../utils/validators/Validator";
import {ORDER_STATE} from "../../../../constants/SellOrderState";

class InspectQuestionSection extends Component {
    state = {
        inspectionData: {},
        current_question_index: 0,
        image_upload_progress: 0,
        is_loading: false,
        imageUrl: "",
        imageBlob: null
    }

    constructor(props) {
        super(props);
        this.image_input = createRef();
        this.OnChangeImage = this.OnChangeImage.bind(this);
        this.StartImageUpload = this.StartImageUpload.bind(this);
        this.OnClickPrev = this.OnClickPrev.bind(this);
        this.OnSubmitQuestion = this.OnSubmitQuestion.bind(this);
        this.OnSuccessImageUpload = this.OnSuccessImageUpload.bind(this);
        this.OnFailureImageUpload = this.OnFailureImageUpload.bind(this);
        this.OnImageUploadProgress = this.OnImageUploadProgress.bind(this);
        this.OnUpdateAnswer = this.OnUpdateAnswer.bind(this);
        this.OnSuccessInspection = this.OnSuccessInspection.bind(this);
        this.OnFailureInspection = this.OnFailureInspection.bind(this);
    }

    componentDidMount() {
        let inspectionData = this.props.inspectionData;
        inspectionData.inspectionQuestions.forEach(inspectionQuestion => {
            if(isNullOrUndefinedObject(inspectionQuestion.answer)) {
                inspectionQuestion.answer = "";
            }
        });

        this.setState({
            inspectionData: inspectionData
        });
    }

    OnChangeImage(event) {
        if (event.target.files.length <= 0) return;

        this.setState({
            imageUrl: URL.createObjectURL(event.target.files[0]),
            imageBlob: event.target.files[0],
            is_loading: true,
            image_upload_progress: 0
        });

        this.StartImageUpload(event.target.files[0]);
    }

    StartImageUpload(imageBlob) {
        this.setState({
            is_loading: true,
            image_upload_progress: 0
        });

        let formData = CreteImageForm("orderId", this.props.orderId, imageBlob);
        formData.set("index", this.state.current_question_index);
        BackendPOSTRequestWithImage(this.props, ADD_INSPECTION_QUE_ORDER_IMAGE_API, formData, this.OnSuccessImageUpload, this.OnFailureImageUpload, this.OnImageUploadProgress);
    }

    OnSuccessImageUpload(response) {
        let localInspectionData = this.state.inspectionData;
        if(localInspectionData.inspectionQuestions[this.state.current_question_index].images === undefined) {
            localInspectionData.inspectionQuestions[this.state.current_question_index].images = [];
        }
        localInspectionData.inspectionQuestions[this.state.current_question_index].images.push(response.body);

        this.setState({
            is_loading: false,
            imageUrl: "",
            imageBlob: null,
            localInspectionData: localInspectionData
        });
    }

    OnFailureImageUpload(error) {
        this.setState({
            is_loading: false
        });

        ShowErrorModalFromError(this.props, "Image Upload Error", error);
    }

    OnImageUploadProgress(percent) {
        this.setState({
            image_upload_progress: percent
        });
    }

    OnSubmitQuestion(event) {
        event.preventDefault();

        if(this.state.current_question_index >= this.props.inspectionData.inspectionQuestions.length -1) {
            this.setState({
                is_loading: true
            });

            BackendPOSTRequest(this.props, COMPLETE_BICYCLE_FOR_INSPECTION_API, this.props.inspectionData, this.OnSuccessInspection, this.OnFailureInspection);
            return;
        }

        this.setState({
            current_question_index: this.state.current_question_index + 1
        });

        return false;
    }

    OnSuccessInspection(response) {
        let inspectionData = this.state.inspectionData;
        inspectionData.orderResponse.state = ORDER_STATE.INSPECTION_COMPLETED;

        this.setState({
            inspectionData: inspectionData
        }, () => this.props.OnClickCancel(this.state.inspectionData));
    }

    OnFailureInspection(error) {
        this.setState({
            is_loading: false
        });

        ShowErrorModalFromError(this.props, "Inspection complete error", error);
    }

    OnClickPrev() {
        if(this.state.current_question_index === 0 && this.props.OnClickCancel) {
            this.props.OnClickCancel(this.state.inspectionData);
        } else {
            this.setState({
                current_question_index: this.state.current_question_index - 1
            });
        }
    }

    OnUpdateAnswer(event) {
        let inspectionData = this.state.inspectionData;
        inspectionData.inspectionQuestions[this.state.current_question_index].answer = event.target.value;

        this.setState({
            inspectionData: inspectionData
        });
    }

    render() {
        if(isNullOrUndefinedObject(this.state.inspectionData.inspectionQuestions)) {
            return <></>;
        }

        let currentQuestion = this.state.inspectionData.inspectionQuestions[this.state.current_question_index];
        return (
            <div className={"shadow p-2 bg-white rounded-3"}>
                <Indicators activeIndex={this.state.current_question_index} circles={this.props.inspectionData.inspectionQuestions} />

                <form onSubmit={this.OnSubmitQuestion}>
                    <div className={"p-2 pt-4"}>
                        <h5>
                            {currentQuestion.question}
                        </h5>

                        <div className={"py-1"}>
                            <FormControl as={"textarea"} name={"answer"} className={"shadow-none validate_input"}
                                         minLength={2} maxLength={200}
                                         value={currentQuestion.answer} onChange={this.OnUpdateAnswer}
                                         placeholder={"Any comments, specifications, note"} required />
                        </div>

                        <div className={"py-1"}>
                            <label className={"fw-bold small"}>
                                Please rate from 1 to 10
                            </label>
                            <FormControl type={"number"} name={"rating"} defaultValue={8} placeholder={"Rate from 1 to 10"} min={1} max={10} required />
                        </div>

                        <div className={"py-1"}>
                            <FormControl ref={this.image_input} type={"file"} className={"visually-hidden"} name={"image"} onChange={this.OnChangeImage} />
                            {
                                <div className={"d-flex"}>
                                    {
                                        currentQuestion.images && currentQuestion.images.map((image, index) => <div key={"image_i_" + index} style={{height: 150}} className={"me-1"}>
                                            <img alt={"upload_bicycle"} src={image} className={"h-100 rounded"} />
                                        </div>)
                                    }
                                    {
                                        this.state.imageUrl &&
                                        <div style={{height: 150}} className={"me-1"}>
                                            <ImageUploadPreview imageUrl={this.state.imageUrl}
                                                                imageBlob={this.state.imageBlob}
                                                                showProgress={this.state.is_loading}
                                                                progress={this.state.image_upload_progress}
                                                                retryUploadCallback={this.StartImageUpload}/>
                                        </div>
                                    }

                                    {
                                        (!this.state.is_loading && (!currentQuestion.images ||
                                            currentQuestion.images.length < 2)) &&
                                        <div
                                            className={"border hover-shadow bg-light rounded d-flex flex-column justify-content-center align-items-center"}
                                            onClick={() => {
                                                this.image_input.current.click()
                                            }} style={{height: 150, width: 150}}>
                                            <FontAwesomeIcon icon={"plus"} size={"2x"}/>
                                            Add image
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    </div>

                    {
                        (!currentQuestion.images || currentQuestion.images.length < 1) &&
                        <div className={"p-2"}>
                            <div className={"bg-info rounded p-2"}>
                                Please add at least 1 image to continue
                            </div>
                        </div>
                    }

                    <div className={"w-100 p-2"}>
                        <Button variant={"outline-primary"} className={"shadow-none"} onClick={this.OnClickPrev} disabled={this.state.is_loading} >
                            {this.state.is_loading && <Spinner animation={"border"} size={"sm"} />} {this.state.current_question_index === 0 ? "Cancel": "Prev"}
                        </Button>
                        <Button type={"submit"} variant={"primary"} className={"shadow-none float-end"} disabled={this.state.is_loading || !currentQuestion.images || currentQuestion.images.length < 1} >
                            {this.state.is_loading && <Spinner animation={"border"} size={"sm"} />} {this.state.current_question_index === this.props.inspectionData.inspectionQuestions.length -1 ? "Finish": "Next"}
                        </Button>
                    </div>
                </form>
            </div>
        );
    }
}

export default Connector(InspectQuestionSection);