export const ITEM_NUMBER_PARAM = "ITEM_NUMBER";

// ADDRESS
export const ADDRESS_ID_PARAM = "ADDRESS_ID";

// ORDERS API
export const ORDER_ID_PARAM = "ORDER_ID";

// ITEMS (BICYCLE)
export const ITEM_ID_PARAM = "ITEM_ID";

// BRAND (BICYCLE) - SELLER
export const BRAND_ID_PARAM = "BRAND_ID";
export const MODEL_ID_PARAM = "MODEL_ID";

// INSPECTOR
export const INSPECTOR_ID_PARAM = "INSPECTOR_ID";