import React, {Component} from 'react';
import {Container, FormControl} from "react-bootstrap";
import {BackendGETRequest} from "../../../webrequests/BackendRequest";
import {GET_ALL_BRANDS_API} from "../../../constants/backend/APIConstant";
import {ShowErrorModalFromError} from "../../modals/centermodal/CenterModalScreenManager";
import {Connector} from "../../../../../redux/Connector";
import TopSellingBrandsAndModels from "../../home/sections/TopSellingBrandsAndModels";
import {BrandSelectComponent} from "./BrandSelectComponent";
import {Link} from "react-router-dom";
import {RoundedPlaceHolder} from "../../general/PlaceHolder";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {isEmptyString} from "../../../utils/validators/Validator";
import {GetDefaultBrandUrl} from "../../../utils/PixieMartUtil";
import ArrowComponent from "./arrow_components/ArrowComponent";
import SellFaq from "./sell_faq/SellFAQ";
import {SetDocumentTitle} from "../../../managers/DocumentTitleManager";

class ShowAllBrands extends Component {
    state = {
        is_loading_brands: true,
        brands: [],
        search_text: "",
        faq: [{
            question: "Why to select brand?",
            answer: "Selecting the right brand will help you to get better price instead going with the random brand as the popular brands have most demanding value in the market.\n"
        }]
    }

    constructor(props) {
        super(props);
        this.OnSearchTextChange = this.OnSearchTextChange.bind(this);
        this.ClearBrandSearch = this.ClearBrandSearch.bind(this);
    }

    componentDidMount() {
        SetDocumentTitle("Sell with bicycle brands", "Start selling bicycle from the existing brand that you have and want to sell in the market and looking for the great price");
        BackendGETRequest(this.props, GET_ALL_BRANDS_API, (response) => this.setState({brands: response.body["cycleBrands"], is_loading_brands: false}), (error) => ShowErrorModalFromError(this.props, "Requesting brands", error));
    }

    OnSearchTextChange(event) {
        this.setState({
            search_text: event.target.value
        });
    }

    ClearBrandSearch() {
        this.setState({
            search_text: ""
        });
    }

    render() {
        return (
            <div>
                <Container className={"mb-4"}>
                    <div>
                        <ArrowComponent color={["svg-blue", "svg-grey", "svg-grey"]} />
                        <div className={"bg-white rounded my-3 p-2 py-3"}>
                            <div className={"position-relative p-2 bg-light-blue2 d-flex justify-content-between align-items-center"}>
                                <div>
                                    <h5 className={"fw-bold text-primary m-0"}>Select from brand to start selling your bicycle</h5>
                                </div>
                                <div className={"position-relative"} style={{minWidth: "26%"}}>
                                    <div className={"position-absolute h-100 d-flex align-items-center"} style={{left: "0.32rem", top: "0"}}>
                                        <div className={"p-1 bg-px-blue rounded-circle d-flex align-items-center justify-content-center"} style={{width: 30, height: 30}}>
                                            <FontAwesomeIcon icon={"search"} color={"white"} />
                                        </div>
                                    </div>
                                    <FormControl value={this.state.search_text} onChange={this.OnSearchTextChange} placeholder={"Search for your brand"} className={"rounded-pm-1 w-100"} style={{paddingLeft: "2.8rem"}} />
                                    <div className={"position-absolute h-100 d-flex align-items-center"} style={{right: "0.32rem", top: "0"}}>
                                        {
                                            (!isEmptyString(this.state.search_text)) &&
                                            <div className={"p-1 rounded-circle d-flex align-items-center justify-content-center cursor-pointer"} style={{width: 30, height: 30}} onClick={this.ClearBrandSearch}>
                                                <FontAwesomeIcon icon={"times"} color={"grey"} />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={"d-flex flex-wrap mt-3 position-relative overflow-auto"} style={{maxHeight: "80vh"}}>
                                {
                                    this.state.is_loading_brands &&
                                    [...Array(12)].map((_, index) => <div key={"brand_replacer_" + index} className={"p-2"} style={{height: 134, width: 134}}>
                                        <RoundedPlaceHolder />
                                    </div>)
                                }
                                {
                                    this.state.brands.filter(brand => isEmptyString(this.state.search_text) || brand.name.toLowerCase().replace(" ","").includes(this.state.search_text.toLowerCase().replace(" ","")))
                                        .map((brand, index) => <div key={"brand_" + index} className={"p-2 col-4 col-sm-3 col-md-3 col-lg-2"}>
                                            <BrandSelectComponent brand={brand} />
                                        </div>)
                                }
                            </div>
                            <div className={"p-2"}>
                                <Link className={"no-text-decor text-primary fw-bold"}
                                      to={GetDefaultBrandUrl()}>
                                    I'm not sure which brand to select, please proceed to sell directly
                                </Link>
                            </div>
                        </div>
                    </div>
                </Container>

                <div className={"p-2 bg-white"}>
                    <SellFaq faq={this.state.faq} />
                </div>

                <div className={"pb-5 bg-white"}>
                    <TopSellingBrandsAndModels brands={this.state.brands} dont_show_more_brands={true} />
                </div>
            </div>
        );
    }
}

export default Connector(ShowAllBrands);