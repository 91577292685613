import React, {Component} from 'react';
import {BASE_ACCOUNT_URL} from "../urls/AccountUrl";
import {BackendGETRequest} from "../../../../webrequests/BackendRequest";
import {Button, Container, Form} from "react-bootstrap";
import FormTitleComponent from "../../../general/FormTitleComponent";
import {Connector} from "../../../../../../redux/Connector";
import {GET_SECURITY_INFO_API} from "../../../../constants/backend/APIConstant";
import GeneralBreadCrumb from "../../../general/breadcrumb/GeneralBreadCrumb";

class Security extends Component {
    state = {
        my_data: {},
        is_loading: true,
        enable_name_edit: false,
        enable_gender_edit: false,
        enable_email_edit: false,
        enable_password_edit: false,
        enable_mobile_edit: false,
        navigator_links: [
            {
                title: "My Account",
                link: BASE_ACCOUNT_URL
            },
            {
                title: "Security"
            }
        ]
    }

    constructor(props) {
        super(props);
        this.OnFailureRequest = this.OnFailureRequest.bind(this);
        this.OnSuccessRequest = this.OnSuccessRequest.bind(this);
        this.OnClickEditButton = this.OnClickEditButton.bind(this);
    }

    componentDidMount() {
        BackendGETRequest(this.props, GET_SECURITY_INFO_API, this.OnSuccessRequest, this.OnFailureRequest);
    }

    OnSuccessRequest(response) {
        this.setState({
            my_data: response.body,
            is_loading: false
        });
    }

    OnFailureRequest() {
        this.setState({
            is_loading: false
        });
    }

    OnClickEditButton(type) {
        switch (type) {
            case "NAME":
                this.setState({
                   enable_name_edit: !this.state.enable_name_edit
                });
                break;
            case "GENDER":
                this.setState({
                   enable_gender_edit: !this.state.enable_gender_edit
                });
                break;
            case "EMAIL":
                this.setState({
                   enable_email_edit: !this.state.enable_email_edit
                });
                break;
            case "PASSWORD":
                this.setState({
                   enable_password_edit: !this.state.enable_password_edit
                });
                break;
            case "MOBILE":
                this.setState({
                   enable_mobile_edit: !this.state.enable_mobile_edit
                });
                break;
            default:
                break;
        }
    }

    render() {
        return (
            <div className={"flex-fill d-flex flex-column"}>
                <Container>
                    <div className={"bg-white rounded my-2 p-2"}>
                        <GeneralBreadCrumb items={this.state.navigator_links} />
                    </div>

                    <div className={"d-flex flex-column bg-white p-2 my-2 rounded shadow-sm"}>
                        <FormTitleComponent title={"Security"}
                                            content={"Manage security settings for your account"}/>
                        <div>
                            <h5>
                                Personal information
                            </h5>
                            <div className={"d-flex"}>
                                <div className={"pe-2"}>
                                    <label className={"fw-bold"}>
                                        First Name
                                    </label>
                                    <Form.Control name={"firstName"} required minLength={3} disabled={!this.state.enable_name_edit}
                                                  value={this.state.my_data.firstName}
                                                  placeholder={"Enter your first name"} className={"text-capitalize"}/>
                                </div>
                                <div className={"pe-2"}>
                                    <label className={"fw-bold"}>
                                        Last Name
                                    </label>
                                    <Form.Control name={"lastName"} required minLength={3} disabled={!this.state.enable_name_edit}
                                                  value={this.state.my_data.lastName}
                                                  placeholder={"Enter your first name"} className={"text-capitalize"}/>
                                </div>
                                <div className={"pe-2"}>
                                    <br/>
                                    <Button className={"shadow-none"} onClick={()=> this.OnClickEditButton("NAME")}>
                                        {
                                            this.state.enable_name_edit? "Update": "Edit"
                                        }
                                    </Button>
                                </div>
                            </div>

                            <div className={"d-flex my-3"}>
                                <div className={"pe-2"}>
                                    <label className={"fw-bold"}>
                                        Gender
                                    </label>
                                    <Form.Select name={"gender"} required disabled={!this.state.enable_gender_edit} value={this.state.my_data.gender}>
                                        <option value={"Male"}>Male</option>
                                        <option value={"Female"}>Female</option>
                                    </Form.Select>
                                </div>
                                <div className={"pe-2"}>
                                    <br/>
                                    <Button className={"shadow-none"} onClick={()=> this.OnClickEditButton("GENDER")}>
                                        {
                                            this.state.enable_gender_edit? "Update": "Edit"
                                        }
                                    </Button>
                                </div>
                            </div>

                            <div className={"d-flex my-3"}>
                                <div className={"pe-2"}>
                                    <label className={"fw-bold"}>
                                        Email
                                    </label>
                                    <Form.Control name={"email"} required minLength={3} disabled={!this.state.enable_email_edit}
                                                  value={this.state.my_data.email}
                                                  placeholder={"Enter your first name"} className={"text-capitalize"}/>
                                </div>
                                <div className={"pe-2"}>
                                    <br/>
                                    <Button className={"shadow-none"} onClick={()=> this.OnClickEditButton("EMAIL")}>
                                        {
                                            this.state.enable_email_edit? "Update": "Edit"
                                        }
                                    </Button>
                                </div>
                            </div>

                            <div className={"d-flex my-3"}>
                                <div className={"pe-2"}>
                                    <label className={"fw-bold"}>
                                        Password
                                    </label>
                                    <Form.Control name={"password"} required minLength={3} disabled={!this.state.enable_password_edit}
                                                  type={"password"} value={"****"}
                                                  placeholder={"Enter password"} className={"text-capitalize"}/>
                                </div>
                                {
                                    this.state.enable_password_edit &&
                                    <div className={"pe-2"}>
                                        <label className={"fw-bold"}>
                                            Enter password again
                                        </label>
                                        <Form.Control name={"password"} required minLength={3}
                                                      disabled={!this.state.enable_password_edit}
                                                      type={"password"}
                                                      placeholder={"Enter password again"}
                                                      className={"text-capitalize"}/>
                                    </div>
                                }
                                <div className={"pe-2"}>
                                    <br/>
                                    <Button className={"shadow-none"} onClick={()=> this.OnClickEditButton("PASSWORD")}>
                                        {
                                            this.state.enable_password_edit? "Update": "Edit"
                                        }
                                    </Button>
                                </div>
                            </div>

                            <div className={"d-flex my-3"}>
                                <div className={"pe-2"}>
                                    <label className={"fw-bold"}>
                                        Mobile
                                    </label>
                                    <Form.Control name={"mobile"} required minLength={3} disabled={!this.state.enable_mobile_edit}
                                                  value={this.state.my_data.mobileNumber}
                                                  placeholder={"Enter your first name"} className={"text-capitalize"}/>
                                </div>
                                <div className={"pe-2"}>
                                    <br/>
                                    <Button className={"shadow-none"} onClick={()=> this.OnClickEditButton("MOBILE")}>
                                        {
                                            this.state.enable_mobile_edit? "Update": "Edit"
                                        }
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}

export default Connector(Security);