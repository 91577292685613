import React, {Component} from 'react';
import {Button, Container, Spinner} from "react-bootstrap";
import {BASE_INSPECTOR_URL, INSPECTOR_SHOW_ALL_PENDING_ITEMS_URL} from "../urls/InspectorUrl";
import {BreadCrumb} from "../../../panels/breadcrum/BreadCrumb";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {BackendGETRequest, BackendPOSTRequest} from "../../../../webrequests/BackendRequest";
import {Connector} from "../../../../../../redux/Connector";
import {ShowErrorModalFromError} from "../../../modals/centermodal/CenterModalScreenManager";
import {
    BLOCK_BICYCLE_FOR_INSPECTION_API, GET_ORDERS_AVAILABLE_FOR_INSPECTION_API
} from "../../../../constants/backend/APIConstant";
import InspectionOrder from "./InspectionOrder";
import LoadingErrorOrComponent from "../../../general/templates/LoadingErrorOrComponent";

class InspectorItems extends Component {
    constructor(props) {
        super(props);
        this.OnClickBlockForInspection = this.OnClickBlockForInspection.bind(this);
        this.InspectorItemComponent = this.InspectorItemComponent.bind(this);
    }

    state = {
        is_loading: true,
        is_loading_fail: false,
        response: {
            pendingInspection: 0,
            orderResponses: {
                orders: []
            }
        }
    }

    componentDidMount() {
        BackendGETRequest(this.props, GET_ORDERS_AVAILABLE_FOR_INSPECTION_API, (response) => {
            this.setState({
                is_loading: false,
                response: response.body
            });
        }, (error) => {
            this.setState({
                is_loading: false,
                is_loading_fail: true
            });

            ShowErrorModalFromError(this.props, "Inspection Error", error);
        });
    }

    OnClickBlockForInspection(index) {
        let bicycles_response = this.state.response;
        bicycles_response.orderResponses.orders[index]["loading"] = true;
        this.setState({
            response: bicycles_response
        });

        BackendPOSTRequest(this.props, BLOCK_BICYCLE_FOR_INSPECTION_API, {"id": bicycles_response.orderResponses.orders[index]["id"]}, (response) => this.OnSuccessBlockRequest(response, index), (error) => this.OnFailureBlockResponse(error, index))
    }

    OnSuccessBlockRequest(response, index) {
        let bicycles_response = this.state.response;
        bicycles_response.orderResponses.orders[index]["added"] = true;
        this.setState({
            response: bicycles_response
        });
    }

    OnFailureBlockResponse(error, index) {
        let bicycles_response = this.state.response;
        bicycles_response.orderResponses.orders[index]["loading"] = false;
        this.setState({
            response: bicycles_response
        });

        ShowErrorModalFromError(this.props, "Inspection Error", error);
    }

    InspectorItemComponent() {
        return <>
            {
                this.state.response.pendingInspection > 0 &&
                <Link to={INSPECTOR_SHOW_ALL_PENDING_ITEMS_URL} className={"no-text-decor"}>
                    <div className={"p-2 bg-info rounded"}>
                        <p className={"m-0"}>
                            There are {this.state.response.pendingInspection} pending
                            bicycle{this.state.response.pendingInspection > 1 && "s"} in your inspection.
                        </p>
                        <i className={"small"}> click here to check now ...</i>
                    </div>
                </Link>
            }
            <div>
                {
                    this.state.response.orderResponses.orders.length > 0 ?
                        this.state.response.orderResponses.orders.map((order, index) => <div key={"inspection_i_" + index} className={"no-text-decor py-2"}>
                            <div className={"shadow p-2 bg-white rounded-3"}>
                                <InspectionOrder order={order} />
                                {
                                    order.added ? <div className={"mt-2"}>
                                            <div className={"d-flex align-items-center"}>
                                                <FontAwesomeIcon color={"green"} icon={"check-circle"} />
                                                <div className={"text-secondary small ps-1"}>
                                                    This bicycle has been added in your inspection list
                                                </div>
                                            </div>
                                            <div className={"mt-2"}>
                                                <Button variant={"success"} as={Link}
                                                        to={INSPECTOR_SHOW_ALL_PENDING_ITEMS_URL}
                                                        className={"shadow-none"}>
                                                    Check my inspections
                                                </Button>
                                            </div>
                                        </div>:
                                        <div className={"mt-4"}>
                                            <Button disabled={order.loading}
                                                    onClick={() => this.OnClickBlockForInspection(index)}
                                                    className={"shadow-none"}>
                                                {
                                                    order.loading ? <div>
                                                        Adding for inspection <Spinner animation={"border"}
                                                                                       size={"sm"}/>
                                                    </div> : "Block for inspection"
                                                }
                                            </Button>
                                            <p className={"text-secondary small"}>
                                                Note: Block bicycle inspection for next 24 hrs,
                                                blocking bicycle unnecessarily will reduce your inspection score.
                                            </p>
                                        </div>
                                }
                            </div>
                        </div>): <div className={"d-flex justify-content-center p-5"}>
                            <p className={"text-muted"}>
                                No inspections are available right now, please try looking after some time
                            </p>
                        </div>
                }
            </div>
        </>
    }

    render() {
        return (
            <div className={"flex-fill flex-column d-flex"}>
                <Container className={"pt-3 flex-fill d-flex flex-column"}>
                    <h3 className={""}>Bicycles available to inspect</h3>
                    <div className={"py-2"}>
                        <BreadCrumb base_url={BASE_INSPECTOR_URL} />
                    </div>
                    <LoadingErrorOrComponent is_loading={this.state.is_loading}
                                             loading_text={"Loading inspection items"}
                                             is_loading_fail={this.state.is_loading_fail}
                                             component={this.InspectorItemComponent} />
                </Container>
            </div>
        );
    }
}

export default Connector(InspectorItems);