import React, {Component} from 'react';
import {Button, Col, Container, Form, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {BackendGETRequest, BackendPOSTRequest} from "../../../webrequests/BackendRequest";
import {
    GET_ADDRESS_API,
    CREATE_SELL_ORDER_API,
    CREATE_ADDRESS_API, GET_ALL_MODELS_FOR_BRAND_API
} from "../../../constants/backend/APIConstant";
import {JsonFromFormEvent} from "../../../utils/FormManager";
import {ShowErrorModalFromError, ShowOkButtonErrorModal} from "../../modals/centermodal/CenterModalScreenManager";
import {Connector} from "../../../../../redux/Connector";
import {
    COL
} from "../../../constants/application/ApplicationUrl";
import {
    DEFAULT_BRAND_VALUE,
    DEFAULT_ERROR_MSG, DEFAULT_MODEL_VALUE
} from "../../../constants/Constant";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {
    MY_SINGLE_ORDER_URL
} from "../../user/account/urls/AccountUrl";
import LocationPositionGrant from "../../../google/LocationPositionGrant";
import AddressForm from "../../user/account/address/AddressForm";
import {BRAND_ID_PARAM, ORDER_ID_PARAM} from "../../../constants/ParamConstant";
import AddressViewComponent from "../../user/account/address/AddressViewComponent";
import {GetLoginPageUrl} from "../../../utils/PixieMartUtil";
import {SetDocumentTitle} from "../../../managers/DocumentTitleManager";
import MoreInOrders from "./more_in_orders/MoreInOrders";
import DropDownSelect from "../../general/dropdownselect/DropDownSelect";
import {SellTitle} from "./title/SellTitle";

class SellBicycleNow extends Component {
    state = {
        show_create_new_address: false,
        selected_images: [],
        is_loading: false,
        address: [],
        selected_address: 0,
        bikeSizes: [
            {
                name: "Children's Bikes",
                value: "Children",
                sizes: [
                    {
                        name: "[Runner Bikes,	2 - 5 Years] RiderHeight(85 - 110cm)",
                        value: "[Runner Bikes,	2 - 5 Years] RiderHeight(85 - 110cm)"
                    },
                    {
                        name: "[12\" Wheel,	2 - 4 Years] RiderHeight(85 - 100cm)",
                        value: "[12\" Wheel,	2 - 4 Years] RiderHeight(85 - 100cm)"
                    },
                    {
                        name: "[14\" Wheel,	3 - 5 Years] RiderHeight(95 - 110cm)",
                        value: "[14\" Wheel,	3 - 5 Years] RiderHeight(95 - 110cm)"
                    },
                    {
                        name: "[16\" Wheel,	5 - 7 Years] RiderHeight(110 - 120cm)",
                        value: "[16\" Wheel,	5 - 7 Years] RiderHeight(110 - 120cm)"
                    },
                    {
                        name: "[20\" Wheel,	7 - 9 Years] RiderHeight(120 - 135cm)",
                        value: "[20\" Wheel,	7 - 9 Years] RiderHeight(120 - 135cm)"
                    },
                    {
                        name: "[24\" Wheel,	9 - 11 Years] RiderHeight(135 - 145cm)",
                        value: "[24\" Wheel,	9 - 11 Years] RiderHeight(135 - 145cm)"
                    },
                    {
                        name: "[26\" Wheel, (13/14/15\" Frame) 11 Above] RiderHeight(145cm Above)",
                        value: "[26\" Wheel, (13/14/15\" Frame) 11 Above] RiderHeight(145cm Above)"
                    },
                ]
            },
            {
                name: "Mountain Bikes",
                value: "Mountain",
                sizes: [
                    {
                        name: "[13-14\"   X Small] RiderHeight(148 - 158cm)",
                        value: "[13-14\"   X Small] RiderHeight(148 - 158cm)"
                    },
                    {
                        name: "[15-16\"   Small] RiderHeight(158 - 168cm)",
                        value: "[15-16\"   Small] RiderHeight(158 - 168cm)"
                    },
                    {
                        name: "[17-18\"   Medium] RiderHeight(168 - 178cm)",
                        value: "[17-18\"   Medium] RiderHeight(168 - 178cm)"
                    },
                    {
                        name: "[19-20\"   Large] RiderHeight(178 - 185cm)",
                        value: "[19-20\"   Large] RiderHeight(178 - 185cm)"
                    },
                    {
                        name: "[21-22\"   X Large] RiderHeight(185 - 193cm)",
                        value: "[21-22\"   X Large] RiderHeight(185 - 193cm)"
                    },
                    {
                        name: "[23-24\"   XX Large] RiderHeight(193 - 198cm)",
                        value: "[23-24\"   XX Large] RiderHeight(193 - 198cm)"
                    },
                ]
            },
            {
                name: "Road Bikes",
                value: "Road",
                sizes: [
                    {
                        name: "[12-13\"   XX Small] RiderHeight(148 - 152cm)",
                        value: "[12-13\"   XX Small] RiderHeight(148 - 152cm)"
                    },
                    {
                        name: "[13-14\"	X Small] RiderHeight(152 - 160cm)",
                        value: "[13-14\"	X Small] RiderHeight(152 - 160cm)"
                    },
                    {
                        name: "[15-16\"	Small] RiderHeight(160 - 168cm)",
                        value: "[15-16\"	Small] RiderHeight(160 - 168cm)"
                    },
                    {
                        name: "[17-18\"	Medium] RiderHeight(168 - 175cm)",
                        value: "[17-18\"	Medium] RiderHeight(168 - 175cm)"
                    },
                    {
                        name: "[19-20\"	Large] RiderHeight(175 - 183cm)",
                        value: "[19-20\"	Large] RiderHeight(175 - 183cm)"
                    },
                    {
                        name: "[21-22\"	X Large] RiderHeight(183 - 191cm)",
                        value: "[21-22\"	X Large] RiderHeight(183 - 191cm)"
                    },
                    {
                        name: "[23-24\"	XX Large] RiderHeight(191 - 198cm)",
                        value: "[23-24\"	XX Large] RiderHeight(191 - 198cm)"
                    },
                ]
            },
            {
                name: "Hybrid Bikes",
                value: "Hybrid",
                sizes: [
                    {
                        name: "[13-14\"	X Small] RiderHeight(147 - 155cm)",
                        value: "[13-14\"	X Small] RiderHeight(147 - 155cm)"
                    },
                    {
                        name: "[15-16\"	Small] RiderHeight(155 - 165cm)",
                        value: "[15-16\"	Small] RiderHeight(155 - 165cm)"
                    },
                    {
                        name: "[17-18\"	Medium] RiderHeight(165 - 175cm)",
                        value: "[17-18\"	Medium] RiderHeight(165 - 175cm)"
                    },
                    {
                        name: "[19-20\"	Large] RiderHeight(175 - 183cm)",
                        value: "[19-20\"	Large] RiderHeight(175 - 183cm)"
                    },
                    {
                        name: "[21-22\"	X Large] RiderHeight(183 - 191cm)",
                        value: "[21-22\"	X Large] RiderHeight(183 - 191cm)"
                    },
                    {
                        name: "[23-25\"	XX Large] RiderHeight(191 - 198cm)",
                        value: "[23-25\"	XX Large] RiderHeight(191 - 198cm)"
                    },
                ]
            },
            {
                name: "Jump Bikes/Folding Bikes/BMX Bikes",
                value: "BMX",
                sizes: [
                    {
                        name: "ONE SIZE FITS ALL",
                        value: "ONE SIZE FITS ALL"
                    }
                ]
            },
            {
                name: "Other",
                value: "Other",
                sizes: [
                    {
                        name: "[12-13\"   XX Small] RiderHeight(148 - 152cm)",
                        value: "[12-13\"   XX Small] RiderHeight(148 - 152cm)"
                    },
                    {
                        name: "[13-14\"	X Small] RiderHeight(152 - 160cm)",
                        value: "[13-14\"	X Small] RiderHeight(152 - 160cm)"
                    },
                    {
                        name: "[15-16\"	Small] RiderHeight(160 - 168cm)",
                        value: "[15-16\"	Small] RiderHeight(160 - 168cm)"
                    },
                    {
                        name: "[17-18\"	Medium] RiderHeight(168 - 175cm)",
                        value: "[17-18\"	Medium] RiderHeight(168 - 175cm)"
                    },
                    {
                        name: "[19-20\"	Large] RiderHeight(175 - 183cm)",
                        value: "[19-20\"	Large] RiderHeight(175 - 183cm)"
                    },
                    {
                        name: "[21-22\"	X Large] RiderHeight(183 - 191cm)",
                        value: "[21-22\"	X Large] RiderHeight(183 - 191cm)"
                    },
                    {
                        name: "[23-24\"	XX Large] RiderHeight(191 - 198cm)",
                        value: "[23-24\"	XX Large] RiderHeight(191 - 198cm)"
                    },
                ]
            }
        ],
        selected_bike_type: "Children",
        latLong: "",
        selected_brand_name: "Continue without brand",
        models: [{
            id: DEFAULT_MODEL_VALUE,
            modelName: "Other"
        }],
    }

    constructor(props) {
        super(props);
        this.onChangeImage = this.onChangeImage.bind(this);
        this.OnSubmitForm = this.OnSubmitForm.bind(this);
        this.OnSuccessFormSubmit = this.OnSuccessFormSubmit.bind(this);
        this.OnFailureFormSubmit = this.OnFailureFormSubmit.bind(this);
        this.OnSuccessAddressReceived = this.OnSuccessAddressReceived.bind(this);
        this.OnLocationReceived = this.OnLocationReceived.bind(this);
        this.OnClickCreateNewAddress = this.OnClickCreateNewAddress.bind(this);
        this.HideCreateNewAddress = this.HideCreateNewAddress.bind(this);
        this.OnSuccessAddressCreate = this.OnSuccessAddressCreate.bind(this);
        this.OnChangeBikeType = this.OnChangeBikeType.bind(this);
        this.OnChangeSelectedAddress = this.OnChangeSelectedAddress.bind(this);
        this.CreateNewAddressComponent = this.CreateNewAddressComponent.bind(this);
        this.FormComponent = this.FormComponent.bind(this);
        this.OnBrandChange = this.OnBrandChange.bind(this);
    }

    componentDidMount() {
        SetDocumentTitle("Sell my bicycle in online", "Start selling your second hand bicycle at Bicycle Company | Pixie Mart with best price guarantee in Bengaluru");
        this.setState({
            is_loading: true
        });

        if(this.props.user.username) {
            BackendGETRequest(this.props, GET_ADDRESS_API, this.OnSuccessAddressReceived);
        }
    }

    OnSuccessAddressReceived(response) {
        this.setState({
            is_loading: false,
            address: response.body.address
        });
    }

    onChangeImage(event) {
        if(event.target.files.length  <= 0 ) {
            return;
        }

        this.setState({
            selected_images: [...this.state.selected_images, URL.createObjectURL(event.target.files[0])]
        });
    }

    OnSubmitForm(event) {
        event.preventDefault();

        this.setState({
            is_loading: true
        });

        BackendPOSTRequest(this.props, CREATE_SELL_ORDER_API, JsonFromFormEvent(event), this.OnSuccessFormSubmit, this.OnFailureFormSubmit);
        return false;
    }

    OnSuccessFormSubmit(response) {
        window.location.href = MY_SINGLE_ORDER_URL.replace(COL + ORDER_ID_PARAM, response.body.id);
    }

    OnFailureFormSubmit(error) {
        ShowOkButtonErrorModal(this.props, "Sell Bicycle Error",error.response === undefined || error.response.data === undefined? DEFAULT_ERROR_MSG: error.response.data.message);
        this.setState({
            is_loading: false
        });
    }

    OnLocationReceived(position) {
        this.setState({
            latLong: position.latitude + "," + position.longitude + "," + position.accuracy
        });
    }

    OnClickCreateNewAddress() {
        this.setState({
            show_create_new_address: true
        });
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    HideCreateNewAddress() {
        this.setState({
            show_create_new_address: false
        });
    }

    OnSuccessAddressCreate(response) {
        let localAddress = this.state.address;
        localAddress.push(response.body);

        this.setState({
            show_create_new_address: false,
            address: localAddress
        });
    }

    OnChangeBikeType(index) {
        this.setState({
            selected_bike_type: this.state.bikeSizes[index].value
        });
    }

    GetBikeTypeSizes() {
        return this.state.bikeSizes.find(bikeType => bikeType.value === this.state.selected_bike_type).sizes;
    }

    OnChangeSelectedAddress(event) {
        this.setState({
           selected_address: event.target.selectedIndex
        });
    }

    OnBrandChange(index, selectedBrandName) {
        let brandId = DEFAULT_BRAND_VALUE;
        if(index < this.props.brands.length) {
            brandId = this.props.brands[index].id;
        }

        this.setState({
            is_loading: brandId !== DEFAULT_BRAND_VALUE,
            selected_brand_name: selectedBrandName,
            models: [{
                id: DEFAULT_MODEL_VALUE,
                modelName: "Other"
            }]
        });

        if( brandId === DEFAULT_BRAND_VALUE ) {
            return;
        }

        BackendGETRequest(this.props, GET_ALL_MODELS_FOR_BRAND_API.replace(BRAND_ID_PARAM, brandId), (response) => {
            let models = response.body["models"];
            this.setState({
                is_loading: false,
                models: [...models, {
                    id: DEFAULT_MODEL_VALUE,
                    modelName: "Other"
                }]
            });
        }, (error) => {
            this.setState({
                is_loading: false,
            });
            ShowErrorModalFromError(this.props, "Requesting models", error);
        });
    }

    GetBrandsToSelect() {
        let brands = this.props.brands.map(brand => ({
            name: brand.name,
            value: brand.name,
            image: brand.image
        }));
        brands.push({
            name: "Other",
            value: DEFAULT_BRAND_VALUE
        });

        return brands;
    }

    GetModelsToSelect() {
        if(this.state.models.length <= 1) {
            return [];
        }

        return this.state.models.map(model => ({
            name: model.modelName? model.modelName: this.state.selected_brand_name,
            value: model.modelName? model.modelName: this.state.selected_brand_name
        }));
    }

    CreateNewAddressComponent() {
        return <div className={"bg-white rounded my-3 p-2 py-3"}>
            <div className={"position-relative"}>
                <div className={"position-absolute p-2 top-0 end-0"}>
                    <Button variant={"danger"} className={"rounded-0"} onClick={this.HideCreateNewAddress}>
                        <FontAwesomeIcon icon={"times"} />
                    </Button>
                </div>
                <AddressForm title={"Create New Address"}
                             subtitle={"Create new address to buy or sell bicycle"}
                             btnText={"Create Address"}
                             url={CREATE_ADDRESS_API} OnSuccessFormSubmit={this.OnSuccessAddressCreate} />
            </div>
        </div>;
    }

    FormComponent() {
        return <div className={"my-3"}>
            {
                this.state.show_create_new_address &&
                <this.CreateNewAddressComponent />
            }

            <form onSubmit={this.OnSubmitForm} className={`w-100 ${this.state.show_create_new_address && "visually-hidden"}`}>
                <div>
                    <div>
                        <Form.Control name={"latLong"} className={"visually-hidden"} defaultValue={this.state.latLong} />
                    </div>

                    <div>
                        <div className={"bg-white rounded p-3 my-3"}>
                            <SellTitle title={"Bicycle Details | Sell My Bicycle"} />
                            <Row className={"g-0"}>
                                <Col className={"p-1 py-2"} md={6}>
                                    <DropDownSelect items={this.GetBrandsToSelect()} name={"brand"} label={"Brand"} required={true}
                                                    placeholder={"Select brand"} onChange={this.OnBrandChange} />
                                </Col>
                                <Col className={"p-1 py-2"} md={6}>
                                    <DropDownSelect items={this.GetModelsToSelect()} name={"model"} label={"Model"} required={true}
                                                    placeholder={"Select model"} />
                                </Col>
                                <Col className={"p-1 py-2"} md={6}>
                                    <DropDownSelect items={this.state.bikeSizes} name={"bikeType"} label={"Bike Type"} required={true}
                                                    placeholder={"Select bike type"} onChange={this.OnChangeBikeType} />
                                </Col>

                                <Col className={"p-1 py-2"} md={6}>
                                    <DropDownSelect items={this.GetBikeTypeSizes()} name={"frameSize"} label={"Frame Size"} required={true} placeholder={"Select frame size"} />
                                </Col>

                                <Col className={"p-1 py-2"} md={6}>
                                    <label className={"fw-bold d-flex small"}>
                                        Preferred inspection slot
                                        <div className={"ps-2"}>
                                            <OverlayTrigger
                                                delay={{hide: 400}}
                                                placement="top"
                                                overlay={<Tooltip id={"tooltip-top"}>
                                                    <div>Preferred slot time is when inspector
                                                        can visit
                                                        your address and inspect your bicycle.
                                                        We'll try the best possible to visit on
                                                        your
                                                        preferred time slot but it might be
                                                        change on
                                                        different condition that inspector will
                                                        notify you
                                                        before visit
                                                    </div>
                                                </Tooltip>}>
                                                {({ref, ...triggerHandler}) => (
                                                    <div
                                                        {...triggerHandler}
                                                        className="d-inline position-relative"
                                                        ref={ref}
                                                    >
                                                        <FontAwesomeIcon icon={"info-circle"}/>
                                                    </div>
                                                )}
                                            </OverlayTrigger>
                                        </div>
                                    </label>
                                    <Form.Control name={"inspectionSlotTime"}
                                                  disabled={this.state.is_loading}
                                                  defaultValue={"Any time"}
                                                  placeholder={"Preferred inspection slot time"}/>
                                </Col>

                                <Col className={"p-1 py-2"} md={6}>
                                    <label className={"fw-bold small d-flex"}>
                                        Description (optional)
                                    </label>
                                    <Form.Control name={"description"}
                                                  disabled={this.state.is_loading}
                                                  placeholder={"Eg. how old is your bicycle, any specific things about your bicycle"}/>
                                </Col>
                            </Row>
                        </div>

                        <div className={"bg-white rounded mb-3"}>
                            <LocationPositionGrant
                                callbackFunction={this.OnLocationReceived}/>
                        </div>

                        {
                            this.state.address.length > 0 &&
                            <div className={"bg-white p-2 rounded"}>
                                <SellTitle title={"Pickup Address"} />
                                <div className={"d-flex flex-wrap p-1"}>
                                    {
                                        this.state.address.map((addressElement, index) => {
                                            return <div key={"address_" + index} className={"border-bottom col-md-6 flex-fill"}>
                                                <label className={"d-flex p-2 cursor-pointer h-100"}>
                                                    <Form.Check type={"radio"} name={"addressId"} className={"me-1"} defaultValue={addressElement.id} defaultChecked={index === 0} />
                                                    <AddressViewComponent address={addressElement} />
                                                </label>
                                            </div>;
                                        })
                                    }
                                </div>

                                <Button variant={"outline-primary"} className={"d-flex fw-bold align-items-center rounded px-2"} onClick={this.OnClickCreateNewAddress} >
                                    <FontAwesomeIcon icon={"map-marker-alt"} className={"me-1"} />
                                    Add new address
                                </Button>
                            </div>
                        }

                        <div className={"mt-2 py-2 text-center"}>
                            {
                                this.state.address.length > 0 ?
                                    <Button disabled={this.state.is_loading}
                                            variant={"primary"}
                                            className={"fw-bold shadow-none"}
                                            type={"submit"}>
                                        Start Sell Now
                                    </Button> : <div>
                                        <Button disabled={this.state.is_loading}
                                                variant={"primary"}
                                                onClick={this.OnClickCreateNewAddress}>Create New Address</Button>
                                        <p className={"text-muted"}>
                                            Create new address to start selling your bicycle
                                        </p>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </form>
        </div>;
    }

    render() {
        if(!this.props.user?.username) {
            return <div className={"py-3 d-flex flex-column justify-content-center align-items-center"}>
                <div className={"d-flex flex-column align-items-center my-3"}>
                    <label className={"bg-light-primary p-1 rounded"}>
                        <FontAwesomeIcon icon={"unlock"} className={"me-1"} />
                        Please do sign in to start selling your bicycle
                    </label>
                    <label className={"text-muted small"}>
                        You're just an one step behind, from selling your bicycle
                    </label>
                </div>
                <Button variant={"outline-primary"}
                        className={"my-3"}
                        as={Link}
                        to={GetLoginPageUrl()}>
                    <FontAwesomeIcon icon={"sign-in-alt"} className={"me-1"} />
                    Sign In
                </Button>
            </div>
        }

        return (
            <div>
                <Container className={"mb-4"}>
                    <this.FormComponent />
                    <MoreInOrders />
                </Container>
            </div>
        );
    }
}

export default Connector(SellBicycleNow);
