import React, {Component} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class WhatWePurchase extends Component {
    state = {
        items: [
            {
                icon: "bicycle",
                title:"Tricycle",
                subtitle: "Age- 2 year, 3year"
            },
            {
                icon: "bicycle",
                title:"Bicycle",
                subtitle: "Age- 2 year- 7 year\n Wheel Size- 36\"-54\""
            },
            {
                icon: "bicycle",
                title:"Balance Cycle",
                subtitle: "Age- 2 year- 4 year"
            },
            {
                icon: "bicycle",
                title: "Scooters",
                subtitle: "Age- 2 year- 7 year"
            }
        ]
    }

    render() {
        return (
            <section className={"py-2"}>
                <Container className={"py-4"}>
                    <h2 className={"text-center p-2 fw-bold fs_heading2"}>
                        What we purchase?
                    </h2>
                    <p className={"text-center small pb-3 text-muted"}>
                        We purchase rides for 2 - 7 Year Old
                    </p>
                    <Row className={"g-0"}>
                        {
                            this.state.items.map((item, index) => {
                                return <Col xs={6} className={"d-flex justify-content-center"}
                                            key={"cycle_sell_" + index}>
                                    <div className={"text-center w-100 rounded m-1"}>
                                        <FontAwesomeIcon icon={item.icon} className={"m-2"} size={"3x"}/>
                                        <p className={"fw-bold fs_75c_10 pt-2 mb-0"}>
                                            {item.title}
                                        </p>
                                        <p className={"text-muted fs_75c_4 small"}>
                                            {item.subtitle}
                                        </p>
                                    </div>
                                </Col>
                            })
                        }
                    </Row>
                </Container>
            </section>
        );
    }
}

export default WhatWePurchase;