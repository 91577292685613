import React, {Component} from 'react';
import SquarePanelComponent from "./SquarePanelComponent";
import {Container} from "react-bootstrap";

class SquarePanelsWithTitle extends Component {
    render() {
        return (
            <div>
                <Container className={"pt-5p"}>
                    <h3 className={"px-2 m-0"}>{this.props.title}</h3>
                    <p className={"fw-bold text-muted px-2"}>{this.props.subtitle}</p>
                    <SquarePanelComponent render={this.props.render} base_url={this.props.base_url} />
                </Container>
            </div>
        );
    }
}

export default SquarePanelsWithTitle;