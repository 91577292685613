import {GetAppBackendUrl} from "../../utils/PixieMartUtil";
import {ADDRESS_ID_PARAM, BRAND_ID_PARAM, ITEM_ID_PARAM, MODEL_ID_PARAM, ORDER_ID_PARAM} from "../ParamConstant";
import {PARAM_SEPARATOR, ROOT_SLASH_URL} from "../application/ApplicationUrl";

const API_PREFIX = "/api";

// AUTHENTICATE AND AUTHORIZATION
export const GET_USER_API = GetAppBackendUrl() + API_PREFIX + "/user";
export const GET_USER_ACCOUNT_API = GET_USER_API + "/account";
export const LOGOUT_API = GetAppBackendUrl() + API_PREFIX + "/logout/";
export const GET_TOKEN_API = GetAppBackendUrl() + "/auth/authenticate/token/google_oauth2";


// ADDRESS
export const GET_ADDRESS_API = GetAppBackendUrl() + API_PREFIX + "/user/account/address";
export const GET_SINGLE_ADDRESS_API = GET_ADDRESS_API + ROOT_SLASH_URL + ADDRESS_ID_PARAM;
export const CREATE_ADDRESS_API = GET_ADDRESS_API + "/address/create";
export const UPDATE_ADDRESS_API = GET_ADDRESS_API + "/address/update";
export const DELETE_ADDRESS_API = GET_ADDRESS_API + "/address/delete";

// ORDERS API
export const GET_ORDERS_API =  GetAppBackendUrl() + API_PREFIX + "/user/account/orders";
export const GET_SINGLE_ORDER_API =  GET_ORDERS_API + "/" + ORDER_ID_PARAM;
export const CREATE_SELL_ORDER_API = GET_ORDERS_API + "/sell/order/create";
export const SENT_FOR_INSPECTION_API = GET_ORDERS_API + "/sell/order/state/start_inspection";
export const ADD_SELL_ORDER_IMAGE = GET_ORDERS_API + "/sell/order/image";

// SECURITY
export const GET_SECURITY_INFO_API = GetAppBackendUrl() + API_PREFIX + "/user/account/security";

// ITEMS (BICYCLE)
const GET_ALL_BICYCLES_API = GetAppBackendUrl() + API_PREFIX + "/seller/cycle/items";
export const GET_ALL_INACTIVE_BICYCLES_API = GetAppBackendUrl() + API_PREFIX + "/seller/cycle/items/inactive";
export const GET_ALL_ACTIVE_BICYCLES_API = GetAppBackendUrl() + API_PREFIX + "/seller/cycle/items/active";
export const GET_SINGLE_BICYCLE_API = GET_ALL_BICYCLES_API + PARAM_SEPARATOR + ITEM_ID_PARAM;
export const GET_RECOMMENDED_BICYCLE_API = GET_ALL_BICYCLES_API + "/recommended";
export const CREATE_BICYCLE_API = GET_ALL_BICYCLES_API + "/new/create";
export const UPDATE_BICYCLE_IMAGE_API = GET_SINGLE_BICYCLE_API + "/image";
export const UPDATE_BICYCLE_API = GET_SINGLE_BICYCLE_API + "/update";
export const DELETE_BICYCLE_API = GET_SINGLE_BICYCLE_API + "/delete";

// BRAND (BICYCLE) - SELLER
export const GET_ALL_ADMIN_BRANDS_API = GetAppBackendUrl() + API_PREFIX + "/seller/cycle/brands";
export const GET_SINGLE_BRAND_API = GET_ALL_ADMIN_BRANDS_API + PARAM_SEPARATOR + BRAND_ID_PARAM;
export const CREATE_BRAND_API = GET_ALL_ADMIN_BRANDS_API + "/new/create";
export const UPDATE_BRAND_IMAGE = GET_SINGLE_BRAND_API + "/image";
export const UPDATE_BRAND_API = GET_SINGLE_BRAND_API + "/update";
export const DELETE_BRAND_API = GET_SINGLE_BRAND_API + "/delete";

export const GET_MODEL_FROM_AMAZON_ASIN_API = GetAppBackendUrl() + API_PREFIX + "/seller/cycle/amazon/bicycle/asin/";
export const CREATE_MODEL_FROM_AMAZON_ASIN_API = GetAppBackendUrl() + API_PREFIX + "/seller/cycle/amazon/bicycle/add";

// BRAND (BICYCLE) - USER
export const GET_ALL_BRANDS_API = GetAppBackendUrl() + API_PREFIX + "/bicycle/brands";
export const GET_ALL_MODELS_FOR_BRAND_API = GET_ALL_BRANDS_API + ROOT_SLASH_URL + BRAND_ID_PARAM + "/models";
export const GET_SINGLE_MODELS_FOR_BRAND_API = GET_ALL_BRANDS_API + ROOT_SLASH_URL + BRAND_ID_PARAM + "/models/" + MODEL_ID_PARAM;
export const GET_TOP_SELLING_MODELS_API = GET_ALL_BRANDS_API + "/topSelling/models";
export const GET_TOP_SELLING_BRANDS_API = GET_ALL_BRANDS_API + "/topSelling/brands";

// INSPECTOR
export const GET_INSPECTION_INFO_API = GetAppBackendUrl() + API_PREFIX + "/user/account/inspector/cycle";
export const GET_ORDERS_AVAILABLE_FOR_INSPECTION_API = GET_INSPECTION_INFO_API + "/bicycle";
export const GET_ORDERS_AVAILABLE_FOR_MY_INSPECTION_API = GET_INSPECTION_INFO_API + "/my_inspections";
export const GET_SINGLE_ORDER_AVAILABLE_FOR_MY_INSPECTION_API = GET_ORDERS_AVAILABLE_FOR_MY_INSPECTION_API + ROOT_SLASH_URL + ORDER_ID_PARAM;
export const ADD_INSPECTION_QUE_ORDER_IMAGE_API = GET_INSPECTION_INFO_API + "/image";
export const BLOCK_BICYCLE_FOR_INSPECTION_API = GET_INSPECTION_INFO_API + "/bicycle/block";
export const COMPLETE_BICYCLE_FOR_INSPECTION_API = GET_INSPECTION_INFO_API + "/bicycle/complete";
export const PAYMENT_ESTIMATE_BICYCLE_FOR_INSPECTION_API = GET_INSPECTION_INFO_API + "/bicycle/payment/estimate";
export const PAYMENT_COMPLETE_BICYCLE_FOR_INSPECTION_API = GET_INSPECTION_INFO_API + "/bicycle/payment/complete";

// APPROVALS
export const GET_ALL_ORDERS_AVAILABLE_FOR_APPROVAL_API = GetAppBackendUrl() + API_PREFIX + "/user/account/approval/cycle/sell";
export const APPROVE_ORDER_FOR_PAYMENT_API = GetAppBackendUrl() + API_PREFIX + "/user/account/approval/cycle/approve";

// BUY ITEMS API
export const GET_ALL_ITEM_FOR_SELL_API = GetAppBackendUrl() + API_PREFIX + "/sell/items";
export const GET_ITEM_FOR_SELL_API = GetAppBackendUrl() + API_PREFIX + "/sell/item/";
