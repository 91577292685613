import React, {Component} from 'react';
import AddressForm from "./AddressForm";
import {Col, Row} from "react-bootstrap";
import {BASE_ACCOUNT_URL, MY_ADDRESS_URL} from "../urls/AccountUrl";
import {GET_SINGLE_ADDRESS_API, UPDATE_ADDRESS_API} from "../../../../constants/backend/APIConstant";
import {BackendGETRequest} from "../../../../webrequests/BackendRequest";
import {ShowOkButtonErrorModal} from "../../../modals/centermodal/CenterModalScreenManager";
import {DEFAULT_ERROR_MSG} from "../../../../constants/Constant";
import {Connector} from "../../../../../../redux/Connector";
import {ADDRESS_ID_PARAM} from "../../../../constants/ParamConstant";
import AreYouLookingForSomethingElse from "../../../general/AreYouLookingForSomethingElse";

class UpdateAddress extends Component {
    state = {
        address: [],
        is_loading: true,
        data: {},
        links: [
            {
                link: BASE_ACCOUNT_URL,
                title: "My Account"
            },
            {
                link: MY_ADDRESS_URL,
                title: "My Address"
            }
        ]
    }

    constructor(props) {
        super(props);
        this.OnSuccessFormSubmit = this.OnSuccessFormSubmit.bind(this);
        this.OnSuccessGetData = this.OnSuccessGetData.bind(this);
        this.OnFailureGetData = this.OnFailureGetData.bind(this);
    }

    componentDidMount() {
        BackendGETRequest(this.props, GET_SINGLE_ADDRESS_API.replace(ADDRESS_ID_PARAM, this.props.match.params[ADDRESS_ID_PARAM]), this.OnSuccessGetData, this.OnFailureGetData);
    }

    OnSuccessFormSubmit(response) {

    }

    OnSuccessGetData(response) {
        this.setState({
            data: response.body,
            is_loading: false
        });
    }

    OnFailureGetData(error) {
        ShowOkButtonErrorModal(this.props, "Create Address Error",error.response === undefined || error.response.data === undefined?
            DEFAULT_ERROR_MSG: error.response.data.message);
        this.setState({
            is_loading: false
        });
    }

    render() {
        return (
            <div className={"flex-fill d-flex py-2"}>
                <Row className={"g-0"}>
                    <Col lg={9} xl={10} className={"px-2 pt-1 mb-3"}>
                        {
                            this.state.data["firstName"] &&
                            <AddressForm title={"Update Address"}
                                         data={this.state.data}
                                         subtitle={"Edit required fields and then click on update address button"}
                                         btnText={"Update Address"}
                                         deleteBtn
                                         url={UPDATE_ADDRESS_API}
                                         enableDeleteBtn={true}
                                         OnSuccessFormSubmit={this.OnSuccessFormSubmit}/>
                        }
                    </Col>
                    <Col lg={3} xl={2} className={"px-2 flex-shrink-0"}>
                        <AreYouLookingForSomethingElse links={this.state.links} />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Connector(UpdateAddress);