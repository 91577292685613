import React, {Component} from 'react';
import {Col, Row} from "react-bootstrap";
import delivery from "../../../../statics/img/home/howitworks/delivery.png";
import pixie_mart_shop from "../../../../statics/img/home/howitworks/pixie_mart_shop.png";
import product_refurbish from "../../../../statics/img/home/howitworks/product_refurbish.png";
import purchase from "../../../../statics/img/home/howitworks/purchase.png";
import we_sell from "../../../../statics/img/home/howitworks/we_sell.png";
import "./howitworks.css";

class HowItWorks extends Component {
    state = {
        sections: [
            {
                image: purchase,
                title: "We Purchase",
                subtitle: "Post safety and quality checks",
            },
            {
                image: product_refurbish,
                title: "We Refurbish",
                subtitle: "Good as new",
            },
            {
                image: we_sell,
                title: "We Sell",
                subtitle: "Post refurbishing and passing all quality checks, we publish the product on Pixie",
            },
            {
                image: delivery,
                title: "We Deliver",
                subtitle: "You get the delivery of the purchased item at your doorstep",
            }
        ]
    }

    constructor(props) {
        super(props);
        this.ImageComponent = this.ImageComponent.bind(this);
    }

    ImageComponent({section}) {
        return <div className={"text-center bg-white rounded m-2 p-2 shadow-sm rounded"}>
            <div className={"py-2"}>
                <div style={{height: 64}}>
                    <img src={section.image} className={"img-object-fit-contain"} alt={"how_it_works"} />
                </div>
            </div>
            <div className={"p-2"}>
                <p className={"fw-bold fs_75c_4"}>
                    {section.title}
                </p>
                <p className={"m-0 fs_45c_4"} style={{height: 30}}>
                    {section.subtitle}
                </p>
            </div>
        </div>
    }

    render() {
        return (
            <section className={"bg-light-blue2 p-2 py-5"}>
                <h2 className={"text-center fw-bold pb-2 fs_heading2"}>How it Works</h2>
                <Row className={"g-0"}>
                    <Col md={6}>
                        <div className={"p-2 px-5 h-100 text-center"}>
                            <img src={pixie_mart_shop} alt={"pixie_mart_shop"} className={"img-object-fit-contain"} style={{width: "74%"}} />
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className={"p-2 d-flex justify-content-center"}>
                            <div style={{maxWidth: 560}}>
                                <Row className={"g-0"}>
                                    <Col xs={6} className={"p-0"}>
                                        <div className={"d-flex flex-column how-it-works-pt-5"}>
                                            <this.ImageComponent section={this.state.sections[0]} />
                                            <this.ImageComponent section={this.state.sections[2]} />
                                        </div>
                                    </Col>
                                    <Col xs={6} className={"p-0"}>
                                        <div className={"d-flex flex-column"}>
                                            <this.ImageComponent section={this.state.sections[1]}/>
                                            <this.ImageComponent section={this.state.sections[3]}/>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </section>
        );
    }
}

export default HowItWorks;
