import React, {createRef} from 'react';
import {
    Badge,
    Button,
    Col,
    FormControl,
    Row,
    Table, Container
} from "react-bootstrap";
import SpinLoaderWithText from "../../../general/loader/SpinLoaderWithText";
import {BASE_ACCOUNT_URL, MY_ORDERS_URL} from "../urls/AccountUrl";
import {BackendGETRequest, BackendPOSTRequest} from "../../../../webrequests/BackendRequest";
import {
    GET_SINGLE_ORDER_API,
    SENT_FOR_INSPECTION_API
} from "../../../../constants/backend/APIConstant";
import {Connector} from "../../../../../../redux/Connector";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {isEmptyString, isNullOrUndefinedObject} from "../../../../utils/validators/Validator";
import SingleOrderImageUpload from "./SingleOrderImageUpload";
import {ShowErrorModalFromError} from "../../../modals/centermodal/CenterModalScreenManager";
import {ORDER_ID_PARAM} from "../../../../constants/ParamConstant";
import {ImageUploadPreview} from "../../../general/ImageUploadPreview";
import {ORDER_STATE} from "../../../../constants/SellOrderState";
import {
    DEFAULT_BRAND_VALUE,
    DEFAULT_MODEL_VALUE,
    BICYCLE_COMPANY_EMAIL,
    SELL_ORDER_TYPE
} from "../../../../constants/Constant";
import AddressViewComponent from "../address/AddressViewComponent";
import GeneralBreadCrumb from "../../../general/breadcrumb/GeneralBreadCrumb";
import {CONTINUE_TO_INSPECTION_TEXT, UPLOAD_IMAGE_TEXT} from "../../../../constants/StringConstant";

class SingleOrder extends SingleOrderImageUpload {
    state = {
        order: {},

        imageUrl: "",
        imageBlob: null,
        is_uploading_image: false,
        image_upload_progress: 0,

        is_loading: true,
        navigator_links: [
            {
                title: "My Account",
                link: BASE_ACCOUNT_URL
            },
            {
                title: "My Orders",
                link: MY_ORDERS_URL
            },
            {
                title: this.props.match.params[ORDER_ID_PARAM]?.slice(-6)
            }
        ]
    }

    IMAGE_LIMIT = 4;

    constructor(props) {
        super(props);
        this.image_input = createRef();
        this.OrdersComponent = this.OrdersComponent.bind(this);
        this.OnClickContinue = this.OnClickContinue.bind(this);
        this.OrderStatus = this.OrderStatus.bind(this);
    }

    componentDidMount() {
        BackendGETRequest(this.props, GET_SINGLE_ORDER_API.replace(ORDER_ID_PARAM, this.props.match.params[ORDER_ID_PARAM]), (response) => {
            this.setState({
                order: response.body,
                is_loading: false
            });
        }, (error) => {
            this.setState({
                is_loading: false
            });
        });
    }

    OrderStatus() {
        switch (this.state.order.state) {
            case ORDER_STATE.START_SELL:
                return <div>
                    <p className={"small rounded fw-bold bg-light-success p-2"}>
                        <FontAwesomeIcon icon={"check-circle"} className={"me-1"} />
                        {
                            this.state.order.images.length < 2 && UPLOAD_IMAGE_TEXT
                        }
                        {
                            this.state.order.images.length >= 2 && CONTINUE_TO_INSPECTION_TEXT
                        }
                    </p>

                    <Button
                        disabled={!isNullOrUndefinedObject(this.state.order.images) && this.state.order.images.length < 2}
                        onClick={this.OnClickContinue}>
                        Continue
                    </Button>
                </div>
            case ORDER_STATE.SENT_FOR_INSPECTION:
                return <div>
                    <p className={"small rounded fw-bold bg-light-success p-2 m-0"}>
                        <FontAwesomeIcon icon={"check-circle"} className={"me-1"} />
                        Your request is submitted, we will allocate an inspector soon
                    </p>
                </div>
            case ORDER_STATE.INSPECTOR_ALLOCATED:
                return <div>
                    <p className={"small rounded fw-bold bg-light-success p-2 m-0"}>
                        <FontAwesomeIcon icon={"check-circle"} className={"me-1"} />
                        Inspector allocated, inspector will visit to your place for the inspection
                    </p>
                    <div className={"text-muted fw-bold small"}>
                        If you do have any concern about this order, please feel free to write a mail us at {BICYCLE_COMPANY_EMAIL}
                    </div>
                </div>
            case ORDER_STATE.PAYMENT_COMPLETED:
                return <div>
                    <p className={"small rounded fw-bold bg-light-success p-2 m-0"}>
                        <FontAwesomeIcon icon={"check-circle"} className={"me-1"} />
                        Payment completed
                    </p>
                    <div className={"text-muted fw-bold small"}>
                        If you do have any concern about this order, please feel free to write a mail us at {BICYCLE_COMPANY_EMAIL}
                    </div>
                </div>
            default:
                return <div></div>
        }
    }

    OnClickContinue() {
        BackendPOSTRequest(this.props, SENT_FOR_INSPECTION_API, {"id": this.state.order.id}, ()=> {
            let order = this.state.order;
            order.state = ORDER_STATE.SENT_FOR_INSPECTION;
            this.setState({order: order});
        }, (error)=> {ShowErrorModalFromError(this.props, "Inspect Error", error)});
    }

    OrdersComponent() {
        return <div className={"d-flex flex-column flex-fill"}>
            <div className={"position-relative shadow-sm hover-shadow rounded p-1"}>
                <div className={"position-relative px-1"}>
                    <div className={"p-2 position-absolute top-0 right-0"}>
                        <Badge bg={"success"}>
                            {this.state.order.orderType === SELL_ORDER_TYPE && "SELL"}
                        </Badge>
                    </div>

                    <div className={"p-1"}>
                        <h5 className={"fw-bold d-flex"}>
                            {this.state.order.brand === DEFAULT_BRAND_VALUE? "General Brand": this.state.order.brand}
                            <div className={"text-secondary mx-1"}>
                                {this.state.order.model !== DEFAULT_MODEL_VALUE && ("| " + this.state.order.model) }
                            </div>
                        </h5>
                        <div className={"text-secondary mb-2"}>
                            <div className={"text-secondary small"}>
                                {this.state.order.description}
                            </div>
                        </div>
                    </div>
                </div>

                <Row className={"g-0"}>
                    <Col md={6}>
                        <div className={"p-2"}>
                            <h5 className={"fw-bold small"}>
                                Bicycle pickup address
                            </h5>
                            <div className={"rounded bg-light"}>
                                {
                                    this.state.order.address &&
                                    <AddressViewComponent address={this.state.order.address}/>
                                }
                            </div>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className={"p-2"}>
                            <h5 className={"fw-bold small"}>
                                Bicycle information
                            </h5>

                            <Table striped bordered>
                                <tbody>
                                <tr>
                                    <th>
                                        Frame Size
                                    </th>
                                    <td>
                                        {this.state.order.frameSize}
                                    </td>
                                </tr>
                                <tr>
                                    <th>
                                        Color
                                    </th>
                                    <td>
                                        {this.state.order.color}
                                    </td>
                                </tr>
                                </tbody>
                            </Table>

                            <div className={"small"}>
                                <p>
                                    {this.state.order.preferredTimeSlot}
                                </p>
                            </div>
                        </div>
                    </Col>
                </Row>
                {
                    !isNullOrUndefinedObject(this.state.order.images) && this.state.order.images.length > 0 &&
                    <div className={"p-1"}>
                        <h6 className={"fw-bold small px-1"}>
                            Image uploaded
                        </h6>
                        <div className={"d-flex flex-wrap"}>
                            {
                                this.state.order.images.map((image, index) => <div style={{height: 100}}
                                                                                   className={"p-1"}>
                                    <img key={"image_i_" + index} alt={"sell_im"} className={"img-object-fit-cover"}
                                         src={image}/>
                                </div>)
                            }
                        </div>
                    </div>

                }

                {
                    (this.state.order.images.length < this.IMAGE_LIMIT && this.state.order.state === ORDER_STATE.START_SELL) &&
                    <div className={"px-2 pt-3"}>
                        <div className={"d-flex"}>
                            {
                                (!isEmptyString(this.state.imageUrl)) &&
                                <div style={{height: 100}} className={"pe-1"}>
                                    <ImageUploadPreview imageUrl={this.state.imageUrl}
                                                        imageBlob={this.state.imageBlob}
                                                        showProgress={this.state.is_uploading_image}
                                                        progress={this.state.image_upload_progress}
                                                        retryUploadCallback={this.StartImageUpload}/>
                                </div>
                            }

                            {
                                (!this.state.is_uploading_image && !isNullOrUndefinedObject(this.state.order.images)) &&
                                <div
                                    className={"border hover-shadow bg-light rounded d-flex flex-column justify-content-center align-items-center"}
                                    onClick={() => {
                                        this.image_input.current.click()
                                    }} style={{height: 100, width: 200}}>
                                    <FontAwesomeIcon icon={"plus"} size={"2x"}/>
                                    Add new image
                                </div>
                            }
                        </div>
                        <FormControl className={"visually-hidden"} ref={this.image_input} type={"file"} name={"image"}
                                     onChange={this.OnChangeImage}/>
                    </div>
                }

                <div className={"mt-3 mb-2 px-2"}>
                    <this.OrderStatus />
                </div>
            </div>
        </div>;
    }

    render() {
        return (
            <Container className={"py-3"}>
                <div className={"bg-white rounded my-2 p-2"}>
                    <GeneralBreadCrumb items={this.state.navigator_links} />
                </div>
                <div className={"bg-white rounded"}>
                    {
                        this.state.is_loading? <SpinLoaderWithText content={"Loading order"} /> : <this.OrdersComponent />
                    }
                </div>
            </Container>
        );
    }
}

export default Connector(SingleOrder);