import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class GeneralBreadCrumb extends Component {
    render() {
        let item_len = this.props.items.length;
        
        return (
            <div className={"d-flex small"}>
                {
                    this.props.items.slice(0, item_len - 1).map((item, index) => <div key={"nav_link_" + index} className={"d-flex align-items-center"}><Link to={item.link} className={"no-text-decor"}>
                        <p className={"m-0 text-secondary"}>
                            {item.title}
                        </p>
                    </Link> <FontAwesomeIcon icon={"chevron-right"} className={"mx-1"} /> </div>)
                }
                <p className={"m-0 text-primary"}>
                    {this.props.items[item_len - 1].title}
                </p>
            </div>
        );
    }
}

export default GeneralBreadCrumb;