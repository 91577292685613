import React, {Component} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {SHOW_ALL_USER_BRANDS_URL} from "../../../constants/application/ApplicationUrl";
import {BrandSelectComponent, ModelSelectComponent} from "../../spages/sell/BrandSelectComponent";
import {BackendGETRequest} from "../../../webrequests/BackendRequest";
import {GET_TOP_SELLING_BRANDS_API, GET_TOP_SELLING_MODELS_API} from "../../../constants/backend/APIConstant";
import {Connector} from "../../../../../redux/Connector";
import {RoundedPlaceHolder, SquarePlaceHolder} from "../../general/PlaceHolder";

class TopSellingBrandsAndModels extends Component {
    state = {
        is_loading_brands: true,
        is_loading_models: true,
        models: [],
        brands: []
    }

    componentDidMount() {
        BackendGETRequest(this.props, GET_TOP_SELLING_BRANDS_API, (response) => this.setState({brands: response.body.cycleBrands, is_loading_brands: false}), (error) => {
            this.setState({is_loading_brands: false});
        });
        BackendGETRequest(this.props, GET_TOP_SELLING_MODELS_API, (response) => this.setState({models: response.body.models, is_loading_models: false}), (error) => {
            this.setState({is_loading_models: false});
        });
    }

    render() {
        return (
            <section>
                    {
                        (this.state.is_loading_brands || this.state.brands.length > 0) &&
                        <div className={"bg-light-blue2 py-5"} >
                            <Container>

                            <div className={"position-relative py-4"}>
                                <h2 className={"text-center fw-bold p-2 rounded fs_heading2"}>
                                    Top selling Brands
                                </h2>
                            </div>
                            <div className={"d-flex mt-3 position-relative"} style={{overflowY: "auto"}}>
                                {
                                    this.state.is_loading_brands &&
                                    [...Array(8)].map((_, index) => <div key={"tsm_" + index}
                                                                          className={"p-1 flex-shrink-0"}
                                                                          style={{height: 164, width: 164}}>
                                        <RoundedPlaceHolder/>
                                    </div>)
                                }
                                {
                                    this.state.brands.slice(0, 7).map((brand, index) => <div key={"brand_i_" + index}
                                                                                 className={"p-1 flex-shrink-0"}
                                                                                 style={{width: 164}}>
                                        <BrandSelectComponent brand={brand}/>
                                    </div>)
                                }
                                {
                                    !this.props.dont_show_more_brands &&

                                    <div className={"p-1 flex-shrink-0"} style={{width: 164}}>
                                        <Link className={"no-text-decor text-primary fw-bold"}
                                              to={SHOW_ALL_USER_BRANDS_URL}>

                                            <div className={"shadow-sm rounded overflow-hidden border hover-shadow d-flex justify-content-center flex-column align-content-center h-100 bg-white"}>
                                                    <div className={"p-4 text-center"}>
                                                        View all brands
                                                    </div>
                                            </div>
                                        </Link>
                                    </div>
                                }
                            </div>
                            </Container>
                        </div>
                    }
                    {
                        (this.state.is_loading_models || this.state.models.length > 0) &&
                        <div className={"py-5"}>
                            <Container>
                            <h2 className={"text-center fw-bold p-2 rounded fs_heading2"}>Top selling Models</h2>
                            <Row className={"g-0"}>
                                {
                                    this.state.is_loading_brands &&
                                    [...Array(4)].map((_, index) => <Col md={3} key={"tsm_" + index}
                                                                          className={"p-2"}
                                                                          style={{height: 300}}>
                                        <SquarePlaceHolder/>
                                    </Col>)
                                }
                                {

                                    this.state.models.slice(0,4).map((model, index) => <Col md={3} key={"tsm_" + index + model.brandId}
                                                                                 className={"p-2 px-1"} >
                                        <ModelSelectComponent model={model}
                                                              brand={{id: model.brandId, name: model.brand}}/>
                                    </Col>)
                                }
                            </Row>
                            </Container>
                        </div>
                    }
            </section>
        );
    }
}

export default Connector(TopSellingBrandsAndModels);