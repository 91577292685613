import React, {Component} from 'react';
import {BreadCrumb} from "../../../panels/breadcrum/BreadCrumb";
import {BASE_INSPECTOR_URL, INSPECTOR_SHOW_ALL_PENDING_ITEMS_URL} from "../urls/InspectorUrl";
import {Button, Col, Container, Form, FormControl, Row, Spinner} from "react-bootstrap";
import "./progress.css";
import {Link} from "react-router-dom";
import InspectionOrder from "./InspectionOrder";
import {BackendGETRequest, BackendPOSTRequest} from "../../../../webrequests/BackendRequest";
import {Connector} from "../../../../../../redux/Connector";
import {ShowErrorModalFromError} from "../../../modals/centermodal/CenterModalScreenManager";
import {
    GET_SINGLE_ORDER_AVAILABLE_FOR_MY_INSPECTION_API,
    PAYMENT_COMPLETE_BICYCLE_FOR_INSPECTION_API,
    PAYMENT_ESTIMATE_BICYCLE_FOR_INSPECTION_API
} from "../../../../constants/backend/APIConstant";
import {ORDER_ID_PARAM} from "../../../../constants/ParamConstant";
import InspectQuestionSection from "./InspectQuestionSection";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {JsonFromFormEvent} from "../../../../utils/FormManager";
import {ORDER_STATE} from "../../../../constants/SellOrderState";

class ShowSinglePendingItem extends Component {
    state = {
        is_loading: true,
        is_loading_progress: false,
        is_loading_fail: false,

        is_enabled_inspect_now: false,
        show_inspection_question: false,
        inspection_data: {
            orderResponse: {},
            inspectionQuestions: []
        },
        paymentModes: [
            {
                key: "CASH",
                value: "Cash"
            },
            {
                key: "PAYTM",
                value: "Paytm"
            },
            {
                key: "GPAY",
                value: "Google Payment (GPay)"
            },
            {
                key: "BANK",
                value: "BANK Transfer",
            },
            {
                key: "OTHER",
                value: "Other"
            }
        ]
    }

    constructor(props) {
        super(props);
        this.OnClickStartTheInspection = this.OnClickStartTheInspection.bind(this);
        this.OnChangeAreYouAtLocation = this.OnChangeAreYouAtLocation.bind(this);
        this.OnClickCancelInspectionQuestion = this.OnClickCancelInspectionQuestion.bind(this);
        this.OnReceiveInspection = this.OnReceiveInspection.bind(this);
        this.OnFailureResponse = this.OnFailureResponse.bind(this);
        this.InspectionSection = this.InspectionSection.bind(this);
        this.OnSubmitPaymentEstimateInformation = this.OnSubmitPaymentEstimateInformation.bind(this);
        this.OnSubmitPaymentCompleteInformation = this.OnSubmitPaymentCompleteInformation.bind(this);
    }

    componentDidMount() {
        BackendGETRequest(this.props, GET_SINGLE_ORDER_AVAILABLE_FOR_MY_INSPECTION_API.replace(ORDER_ID_PARAM, this.props.match.params[ORDER_ID_PARAM]), this.OnReceiveInspection, this.OnFailureResponse);
    }

    OnReceiveInspection(response) {
        this.setState({
            is_loading: false,
            inspection_data: {...response.body, orderId: this.props.match.params[ORDER_ID_PARAM]}
        });
    }

    OnFailureResponse(error) {
        this.setState({
            is_loading: false,
            is_loading_fail: true
        });

        ShowErrorModalFromError(this.props, "Inspection Error", error)
    }

    OnClickStartTheInspection() {
        this.setState({
            show_inspection_question: true
        });
    }

    OnClickCancelInspectionQuestion(inspectionData) {
        this.setState({
            show_inspection_question: false,
            inspection_data: inspectionData
        });
    }

    OnChangeAreYouAtLocation(event) {
        this.setState({
            is_enabled_inspect_now: event.target.checked
        });
    }

    InspectionSection() {
        return <div className={"shadow p-2 bg-white rounded-3"}>
            <InspectionOrder order={this.state.inspection_data.orderResponse} />
            {
                this.state.inspection_data.orderResponse.state === ORDER_STATE.INSPECTOR_ALLOCATED &&
                <div>
                    <div className={"p-2"}>
                        <h6 className={"fw-bold"}>
                            Are you at location ?
                        </h6>
                        <div className={"d-flex"}>
                            No <Form.Check type={"switch"} onChange={this.OnChangeAreYouAtLocation}
                                           checked={this.state.is_enabled_inspect_now} className={"ms-2"}
                                           label={"Yes"}/>
                        </div>
                    </div>
                    <div className={"mt-4"}>
                        <Button as={Link} to={INSPECTOR_SHOW_ALL_PENDING_ITEMS_URL} variant={"danger"}
                                className={"me-2"}>
                            Suspend for now
                        </Button>
                        <Button onClick={this.OnClickStartTheInspection} disabled={!this.state.is_enabled_inspect_now}>
                            Inspect now
                        </Button>
                    </div>
                </div>
            }
            {
                (this.state.inspection_data.orderResponse.state === ORDER_STATE.INSPECTION_COMPLETED
                    || this.state.inspection_data.orderResponse.state === ORDER_STATE.PRICE_ESTIMATED) &&
                <div className={"bg-light-success rounded-pm-1 p-2 mt-2"}>
                    <FontAwesomeIcon color={"green"} icon={"check-circle"} /> Inspection Completed
                </div>
            }
            {
                this.state.inspection_data.orderResponse.state === ORDER_STATE.PAYMENT_COMPLETED &&
                <div className={"bg-light-success rounded-pm-1 p-2 mt-2"}>
                    <FontAwesomeIcon color={"green"} icon={"check-circle"} /> Payment Completed
                </div>
            }
            {
                (this.state.inspection_data.orderResponse.state === ORDER_STATE.INSPECTION_COMPLETED
                    || this.state.inspection_data.orderResponse.state === ORDER_STATE.PRICE_ESTIMATED) &&
                <div className={"py-2"}>
                    <form onSubmit={this.OnSubmitPaymentEstimateInformation}>
                        <div className={"pt-3"}>
                            <div>
                                {
                                    this.state.inspection_data.orderResponse.state === ORDER_STATE.PRICE_ESTIMATED &&
                                    <div className={"p-1 d-flex flex-column"}>
                                        <div className={"d-flex"}>
                                            <div className={"bg-light-primary p-2 rounded"}>
                                                <div className={"text-muted fw-bold"}>
                                                    Your Estimated price
                                                </div>
                                                <h3 className={"fw-bold"}>
                                                    <FontAwesomeIcon icon={"rupee-sign"} className={"me-1"} />
                                                    {this.state.inspection_data.orderResponse.estimatedPriceByInspector}
                                                </h3>
                                            </div>
                                        </div>
                                        <div className={"py-1"}>
                                            <p className={"small rounded fw-bold bg-light-success p-2 m-0"}>
                                                <FontAwesomeIcon icon={"check-circle"} className={"me-1"} />
                                                Payment is sent for an approval
                                            </p>
                                        </div>
                                    </div>
                                }
                                <div className={"p-1"}>
                                    <label className={"fw-bold small"}>
                                        Enter estimated price (max 10,000 Rs.) <span className={"text-danger"}>*</span>
                                    </label>
                                    <p className={"text-muted small m-0"}>
                                        This price will be sent for an approval
                                    </p>
                                    <FormControl name={"estimatedPriceByInspector"} className={"shadow-none"} placeholder={"Enter Estimate Price"} type={"number"}
                                                 disabled={this.state.is_loading_progress}
                                                 defaultValue={this.state.inspection_data.orderResponse.estimatedPriceByInspector}
                                                 max={10000} required />
                                </div>
                            </div>

                            <div className={"p-1"}>
                                <Button type={"submit"} disabled={this.state.is_loading_progress}>
                                    {
                                        this.state.is_loading_progress &&
                                        <Spinner animation={"border"} size={"sm"} className={"me-1"} />
                                    }
                                    {
                                        this.state.inspection_data.orderResponse.state === ORDER_STATE.PRICE_ESTIMATED? "Re estimate price":"Estimate price"
                                    }
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            }

            {
                this.state.inspection_data.orderResponse.state === ORDER_STATE.PAYMENT_APPROVED &&
                <div className={"py-2"}>
                    <form onSubmit={this.OnSubmitPaymentCompleteInformation}>
                        <div className={"pt-3"}>
                            <div>
                                <div className={"p-1 d-flex flex-column"}>
                                    <div className={"d-flex"}>
                                        <div className={"bg-light-primary p-2 rounded"}>
                                            <div className={"text-muted fw-bold"}>
                                                Your Estimated price
                                            </div>
                                            <h3 className={"fw-bold"}>
                                                <FontAwesomeIcon icon={"rupee-sign"} className={"me-1"} />
                                                {this.state.inspection_data.orderResponse.estimatedPriceByInspector}
                                            </h3>
                                            <div className={"bg-white rounded border-success border"}>
                                                <div className={"bg-light-success p-1 rounded"}>
                                                    <FontAwesomeIcon color={"green"} icon={"check-circle"} className={"me-1"} />
                                                    Approved
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Row className={"g-0"}>
                                <Col md={6} className={"p-1"}>
                                    <label className={"fw-bold small"}>
                                        Select payment mode <span className={"text-danger"}>*</span>
                                    </label>
                                    <Form.Select name={"paymentMode"} className={"shadow-none"} defaultValue={"CASH"}>
                                        {
                                            this.state.paymentModes.map(paymentMode => <option key={paymentMode.key} value={paymentMode.key}>
                                                {paymentMode.value}
                                            </option>)
                                        }
                                    </Form.Select>
                                </Col>

                                <Col md={6} className={"p-1"} >
                                    <label className={"fw-bold small"}>
                                        Transaction Screenshot
                                    </label>
                                    <FormControl name={"image"} className={"shadow-none"} type={"file"} />
                                </Col>
                            </Row>

                            <div className={"px-1"}>
                                <p className={"text-muted small"}>
                                    This price have to be handover to seller and pick the bicycle
                                </p>
                                <Button type={"submit"}>
                                    Price received by seller
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            }
        </div>
    }

    OnSubmitPaymentEstimateInformation(event) {
        event.preventDefault();

        this.setState({
            is_loading_progress: true
        });

        let jsonFormData = JsonFromFormEvent(event);
        jsonFormData["id"] = this.props.match.params[ORDER_ID_PARAM];

        BackendPOSTRequest(this.props, PAYMENT_ESTIMATE_BICYCLE_FOR_INSPECTION_API, jsonFormData, (response) => {
            let inspectionData = this.state.inspection_data;
            inspectionData.orderResponse.state = ORDER_STATE.PRICE_ESTIMATED;
            inspectionData.orderResponse.estimatedPriceByInspector = response.body.estimatedPriceByInspector;

            this.setState({
                inspection_data: inspectionData,
                is_loading_progress: false
            });
        }, (error) => {
            this.setState({
                is_loading_progress: false
            });
            ShowErrorModalFromError(this.props, "Payment Submit Failure", error);
        });

        return false;
    }

    OnSubmitPaymentCompleteInformation(event) {
        event.preventDefault();

        let jsonFormData = JsonFromFormEvent(event);
        jsonFormData["id"] = this.props.match.params[ORDER_ID_PARAM];

        BackendPOSTRequest(this.props, PAYMENT_COMPLETE_BICYCLE_FOR_INSPECTION_API, jsonFormData, (response) => {
            let inspectionData = this.state.inspection_data;
            inspectionData.orderResponse.state = ORDER_STATE.PAYMENT_COMPLETED;

            this.setState({
                inspection_data: inspectionData
            });
        }, (error) => ShowErrorModalFromError(this.props, "Payment Submit Failure", error));

        return false;
    }

    render() {
        return (
            <div>
                <Container className={"pt-3"}>
                    <h3 className={""}>Inspecting Bicycle</h3>
                    <div className={"py-2"}>
                        <BreadCrumb base_url={BASE_INSPECTOR_URL} />
                    </div>

                    {
                        this.state.show_inspection_question ?
                            <div className={"py-2"}>
                                <InspectQuestionSection inspectionData={this.state.inspection_data}
                                                        OnClickCancel={this.OnClickCancelInspectionQuestion}
                                                        orderId={this.props.match.params[ORDER_ID_PARAM]} />
                            </div>: <div className={"py-2"}>
                                <this.InspectionSection />
                            </div>
                    }
                </Container>
            </div>
        );
    }
}

export default Connector(ShowSinglePendingItem);