import React, {Component} from 'react';
import {Button, Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {isEmptyObject} from "../../../../utils/validators/Validator";
import AddressViewComponent from "../../account/address/AddressViewComponent";

class InspectionOrder extends Component {
    render() {
        if(isEmptyObject(this.props.order)) {
            return <></>;
        }

        return (
            <Row className={"g-0"}>
                <Col sm={12} md={6} lg={4}>
                    <div className={"bg-light border rounded"}>
                        {
                            this.props.order.images &&
                            <img alt={"bicycle"} src={this.props.order.images[0]}
                                 className={"img-object-fit-cover"}/>
                        }
                    </div>
                </Col>
                <Col>
                    <div className={"p-2"}>
                        <h5 className={"fw-bold"}>
                            {this.props.order.brand}
                        </h5>
                        <p className={"text-secondary mb-0"}>
                            {this.props.order.model}
                        </p>
                        <p className={"text-secondary small mb-1"}>
                            {this.props.order.description}
                        </p>
                        <div className={"mt-2"}>
                            <h6 className={"fw-bold"}>
                                Address
                            </h6>
                            <div className={"ps-2"}>
                                {
                                    this.props.order.address &&
                                    <div className={"shadow-sm border rounded overflow-hidden bg-white"}>
                                        <AddressViewComponent address={this.props.order.address} />
                                    </div>
                                }

                                {
                                    this.props.order.latLong &&
                                    <a href={"https://maps.google.com/maps?q=" + this.props.order.latLong +"&z=17&hl=en"} target={"_blank"} rel={"noreferrer"}>
                                        <Button variant={"outline-primary"} className={"mt-2 shadow-none"}>
                                            <FontAwesomeIcon icon={"map"} className={"pe-2"}/>
                                            Show location on map
                                        </Button>
                                    </a>
                                }
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        );
    }
}

export default InspectionOrder;