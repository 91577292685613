import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {MY_SINGLE_ORDER_URL} from "../urls/AccountUrl";
import {COL} from "../../../../constants/application/ApplicationUrl";
import {ORDER_ID_PARAM} from "../../../../constants/ParamConstant";
import {Badge} from "react-bootstrap";
import {DEFAULT_BRAND_VALUE, DEFAULT_MODEL_VALUE, SELL_ORDER_TYPE} from "../../../../constants/Constant";
import {ORDER_STATE} from "../../../../constants/SellOrderState";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class OrderViewComponent extends Component {
    constructor(props) {
        super(props);
        this.OrderStatus = this.OrderStatus.bind(this);
        this.ViewComponent = this.ViewComponent.bind(this);
    }

    OrderStatus() {
        switch (this.props.order.state) {
            case ORDER_STATE.START_SELL:
                return <div className={"bg-light-danger rounded-pm-1 p-2 mt-2"}>
                    <FontAwesomeIcon color={"red"} icon={"check-circle"} /> Pending for upload images
                </div>;
            case ORDER_STATE.SENT_FOR_INSPECTION:
                return <div>
                    <div className={"bg-light-primary rounded-pm-1 p-2 mt-2"}>
                        <FontAwesomeIcon color={"blue"} icon={"check-circle"} /> Sent for inspection
                    </div>
                    <div className={"small text-muted fw-bold"}>
                        We'll allocate an inspector soon.
                    </div>
                </div>;
            case ORDER_STATE.INSPECTOR_ALLOCATED:
                return <div>
                    <div className={"bg-light-primary rounded-pm-1 p-2 mt-2"}>
                        <FontAwesomeIcon color={"blue"} icon={"check-circle"} /> Inspector allocated
                    </div>
                    <div className={"small text-muted fw-bold"}>
                        Inspector will visit to your place for the inspection.
                    </div>
                </div>;
            case ORDER_STATE.PAYMENT_COMPLETED:
                return <div className={"bg-light-success rounded-pm-1 p-2 mt-2"}>
                    <FontAwesomeIcon color={"green"} icon={"check-circle"} /> Payment Completed
                </div>;
            default:
                return <div></div>
        }
    }

    ViewComponent() {
        return <div className={"position-relative p-2 flex-fill"}>
            <div className={"p-2 position-absolute top-0 right-0"}>
                <Badge bg={"success"}>
                    {this.props.order.orderType === SELL_ORDER_TYPE ? "SELL" : "BUY"}
                </Badge>
            </div>

            <div>
                <p className={"fw-bold text-secondary m-0"}>
                    #ORDER {this.props.order.id?.slice(-7)?.toUpperCase()}
                </p>
            </div>
            <div className={"bg-light w-100 rounded p-1"} style={{height: 340}}>
                {
                    this.props.order.images?.length > 0 &&
                    <img src={this.props.order.images[0]} className={"img-object-fit-contain"} alt={"bicycle"} />
                }
            </div>
            <h5 className={"fw-bold"}>
                {this.props.order.brand === DEFAULT_BRAND_VALUE ? "General Brand" : this.props.order.brand}
            </h5>
            <div className={"text-secondary mb-2"}>
                {this.props.order.model !== DEFAULT_MODEL_VALUE && this.props.order.model}
                <div className={"text-secondary small"}>
                    {this.props.order.description}
                </div>
            </div>

            <div className={"small"}>
                <p>
                    {this.props.order.preferredTimeSlot}
                </p>
            </div>
            <div className={"small d-flex"}>
                <this.OrderStatus/>
            </div>
        </div>
    }

    render() {
        if(this.props.withoutLink) {
            return <this.ViewComponent />
        }

        return (
            <Link to={MY_SINGLE_ORDER_URL.replace(COL + ORDER_ID_PARAM, this.props.order.id)}
                  className={"no-text-decor d-flex flex-fill"}
                  key={"order_" + this.props.order.id}>
                <this.ViewComponent />
            </Link>
        );
    }
}

export default OrderViewComponent;