import React, {Component} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import best_price_image from "../../../../statics/img/home/whyus/best_price.webp";
import factory_grade_image from "../../../../statics/img/home/whyus/factory_grade.webp";
import instant_payment_image from "../../../../statics/img/home/whyus/instant_payment.webp";
import invoice_image from "../../../../statics/img/home/whyus/invoice.webp";
import simple_convenient_image from "../../../../statics/img/home/whyus/simple_convience.webp";
import truck_load_image from "../../../../statics/img/home/whyus/truck_load.webp";
import "./whyus.css";

class WhyUs extends Component {
    state = {
        sections: [
            {
                image: best_price_image,
                title:"Best Prices",
                content: "Price are decided by the experienced inspector"
            },
            {
                image: instant_payment_image,
                title:"Instant Payment",
                content: "Instant Transfer with your preferred mode"
            },
            {
                image: simple_convenient_image,
                title:"Simple & Convenient",
                content: "Check price, schedule pickup & get paid"
            },
            {
                image: truck_load_image,
                title:"Free Doorstep Pickup",
                content: "Frictionless pickup from your doorstep"
            },
            {
                image: factory_grade_image,
                title:"Data Wipe",
                content: "100% Safe and Data Security Guaranteed"
            },
            {
                image: invoice_image,
                title:"Valid Purchase Invoice",
                content: "Genuine Bill of Sale"
            },
        ]
    }
    render() {
        return (
            <section className={"py-4 bg-white"}>
                <h2 className={"text-center p-2 fw-bold mt-3 fs_heading2"}>Why Us</h2>
                <Container>
                    <Row className={"g-0"}>
                        {
                            this.state.sections.map((section, index) => <Col key={"why_us_" + index} xs={6} sm={4} md={2} className={"p-1"}>
                                <div className={"p-1 bg-white h-100 rounded shadow-sm border"}>
                                    <div className={"text-center why_us_item h-100 p-1 rounded"}>
                                        <div  className={"py-2"}>
                                            <div style={{height: 100}}>
                                                <img src={section.image} alt={"why_us_" + index} className={"img-object-fit-contain"} />
                                            </div>
                                        </div>
                                        <div className={"px-2"}>
                                            <h3 className={"fw-bold fs_75c_4 py-2"}>
                                                {section.title}
                                            </h3>
                                            <p className={"text-muted fs_75c_3"}>
                                                {section.content}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Col>)
                        }
                    </Row>
                </Container>
            </section>
        );
    }
}

export default WhyUs;
