import React, {Component} from "react";

import {Nav, Navbar} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser} from '@fortawesome/free-solid-svg-icons';
import {Connector} from "../../../../redux/Connector";
import {
    HOME_URL,
    LOGIN_PAGE_URL
} from "../../constants/application/ApplicationUrl";
import px_logo_blue from "../../statics/img/logo/px_logo_blue.webp";
import SidePanel from "../panels/sidebar/SidePanel";
import axios from "axios";
import {LOGOUT_API} from "../../constants/backend/APIConstant";
import {BASE_ACCOUNT_URL} from "../user/account/urls/AccountUrl";
import {GetLoginPageUrl} from "../../utils/PixieMartUtil";
import SearchBarComponent from "../home/sections/search/SearchBarComponent";

class NavbarComponent extends Component {
    constructor(props) {
        super(props);
        this.OnClickSidePanelShow = this.OnClickSidePanelShow.bind(this);
    }

    state = {
        showSidePanel: false
    }

    OnClickSidePanelShow() {
        this.setState({
            showSidePanel: !this.state.showSidePanel
        });
    }

    logout = () => {
            const options = {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token ' + this.props.user.token
                }
            };
            axios.post(LOGOUT_API,{},options)
                .then(res => {
                    console.log(res.data);
                })
                .catch(error => {
                    console.log(error);
                });
    }

    render() {

        return (
            <>
                <Navbar bg="white" expand="lg" fixed={"top"} sticky={"top"} className={"shadow-sm px-3"} id={"px-m-navbar"}>
                    <div className={"w-100 px-1 d-flex"}>
                        <div className={"d-flex"}>
                            {/*<div className={"me-2 d-flex align-items-center"}>*/}
                            {/*    <FontAwesomeIcon icon={"bars"} size={"2x"}*/}
                            {/*                     onClick={this.OnClickSidePanelShow} color={"black"} />*/}
                            {/*</div>*/}
                            <Navbar.Brand href={HOME_URL} className={"font-weight-bold text-white"}>
                                <img src={px_logo_blue} alt={"logo"} style={{height: 34}}/>
                            </Navbar.Brand>
                        </div>

                        {/*<Navbar.Collapse id="responsive-navbar-nav">*/}
                            {/*<Nav>*/}
                            {/*    <NavDropdown title="Address" id="basic-nav-dropdown">*/}
                            {/*        <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>*/}
                            {/*        <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>*/}
                            {/*        <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>*/}
                            {/*        <NavDropdown.Divider/>*/}
                            {/*        <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>*/}
                            {/*    </NavDropdown>*/}
                            {/*</Nav>*/}
                        {/*</Navbar.Collapse>*/}
                        <div className={"d-flex align-items-center justify-content-between w-100"}>
                            {/*<Nav className={"px-2 m-0 hide_on_small_devices"}>*/}
                            {/*    <div>*/}
                            {/*        <Dropdown>*/}
                            {/*            <Dropdown.Toggle variant="transparent" id="dropdown-basic" className={"d-flex align-items-center border rounded-pm-1 p-1 shadow-none"}>*/}
                            {/*                <FontAwesomeIcon icon={"map-marker-alt"} className={"me-1 color-px-blue"} />*/}
                            {/*                <div>*/}
                            {/*                    Banglore*/}
                            {/*                </div>*/}
                            {/*            </Dropdown.Toggle>*/}
                            {/*        </Dropdown>*/}
                            {/*    </div>*/}
                            {/*</Nav>*/}
                            <div className={"w-100 d-flex hide_on_small_devices"}>
                                <Nav className={"px-2 m-0 d-flex justify-content-center"}>
                                    <SearchBarComponent brands={this.props.brands} />
                                </Nav>
                            </div>
                        </div>
                        <div className={"d-flex align-items-center justify-content-end flex-shrink-0"}>
                            {/*<Nav className={"hide_on_small_devices w-100"}>*/}
                            {/*    <this.SearchPanel />*/}
                            {/*</Nav>*/}

                            {/*<Nav className={"m-0"}>*/}
                            {/*    <div className={"d-flex"}>*/}
                            {/*        <Nav.Link href={SELL_NOW_PAGE_URL}>*/}
                            {/*            <Button variant={"success"} className={"m-0 me-2 py-0 fw-bold"}>*/}
                            {/*                Sell*/}
                            {/*            </Button>*/}
                            {/*        </Nav.Link>*/}
                            {/*        <Nav.Link href={BUY_NOW_PAGE_URL}>*/}
                            {/*            <Button variant={"info"} className={"m-0 py-0 fw-bold"}>*/}
                            {/*                Buy*/}
                            {/*            </Button>*/}
                            {/*        </Nav.Link>*/}
                            {/*    </div>*/}
                            {/*</Nav>*/}
                            {/*<Nav className={"px-2 m-0"}>*/}
                            {/*    <Nav.Link href={BUY_NOW_PAGE_URL}>*/}
                            {/*        <FontAwesomeIcon icon={"search"} size={"lg"} color={"black"} />*/}
                            {/*    </Nav.Link>*/}
                            {/*</Nav>*/}

                            {/*<Nav className={"px-2 m-0 position-relative"}>*/}
                            {/*    <Nav.Link href={USER_CART_URL} >*/}
                            {/*        <Badge pill bg={"danger"} className={"position-absolute p-1 top-0 end-0"}>19</Badge>*/}
                            {/*        <FontAwesomeIcon icon={"shopping-cart"} size={"lg"} color={"black"} />*/}
                            {/*    </Nav.Link>*/}
                            {/*</Nav>*/}

                            {
                                window.location.pathname !== LOGIN_PAGE_URL &&
                                <Nav className={"px-2 m-0"}>
                                    <Nav.Link href={this.props.user.username ? BASE_ACCOUNT_URL : GetLoginPageUrl()}>
                                        <div className={"rounded-pm-1 border p-2 py-1"}>
                                            <FontAwesomeIcon icon={faUser} size={"lg"} className={"me-1 color-px-blue"}/>
                                            {
                                                this.props.user.username ? this.props.user.firstName : "Sign in"
                                            }
                                        </div>
                                    </Nav.Link>
                                </Nav>
                            }

                        </div>
                    </div>
                    {/*<div className={"w-100 show_on_small_devices px-1"}>*/}
                    {/*    <this.SearchPanel />*/}
                    {/*</div>*/}
                </Navbar>
                <SidePanel show={this.state.showSidePanel} onHide={this.OnClickSidePanelShow}/>
            </>
        );
    }
}

export default Connector(NavbarComponent);

