import React, {Component, createRef} from 'react';
import {Button, Col, Form, Row, Spinner} from "react-bootstrap";
import {BackendPOSTRequest} from "../../../../webrequests/BackendRequest";
import {JsonFromFormEvent} from "../../../../utils/FormManager";
import {ShowOkButtonErrorModal} from "../../../modals/centermodal/CenterModalScreenManager";
import {DEFAULT_ERROR_MSG} from "../../../../constants/Constant";
import {Connector} from "../../../../../../redux/Connector";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DELETE_ADDRESS_API} from "../../../../constants/backend/APIConstant";
import {MY_ADDRESS_URL} from "../urls/AccountUrl";
import {SellTitle} from "../../../spages/sell/title/SellTitle";
import DropDownSelect from "../../../general/dropdownselect/DropDownSelect";

class AddressForm extends Component {
    state = {
        is_loading: false,
        update_success: false
    }

    constructor(props) {
        super(props);
        this.addressForm = createRef();
        this.OnSubmitForm = this.OnSubmitForm.bind(this);
        this.OnSuccessWithCallBack = this.OnSuccessWithCallBack.bind(this);
        this.OnDeleteSuccessSubmit = this.OnDeleteSuccessSubmit.bind(this);
        this.OnFailureFormSubmit = this.OnFailureFormSubmit.bind(this);
        this.OnClickDeleteAddress = this.OnClickDeleteAddress.bind(this);
    }

    componentDidMount() {
        if(this.props.data !== undefined && this.props.data["firstName"] !== undefined) {
            for (const element of this.addressForm.current.elements) {
                element.value = this.props.data[element.name];
            }
        }
    }

    OnSubmitForm(event) {
        event.preventDefault();

        this.setState({
            is_loading: true
        });

        BackendPOSTRequest(this.props, this.props.url, JsonFromFormEvent(event), this.OnSuccessWithCallBack, this.OnFailureFormSubmit);
        return false;
    }

    OnSuccessWithCallBack(response) {
        this.setState({
            is_loading: false,
            update_success: true
        });

        setTimeout(() => {
            this.setState({
                update_success: false
            });
        }, 3000);

        if(this.props.OnSuccessFormSubmit) {
            this.props.OnSuccessFormSubmit(response);
        }
    }

    OnDeleteSuccessSubmit() {
        window.location.href = MY_ADDRESS_URL;
    }

    OnFailureFormSubmit(error) {
        ShowOkButtonErrorModal(this.props, "Create Address Error",error.response === undefined || error.response.data === undefined?
            DEFAULT_ERROR_MSG: error.response.data.message);
        this.setState({
            is_loading: false
        });
    }

    OnClickDeleteAddress() {
        this.setState({
            is_loading: true
        });

        BackendPOSTRequest(this.props, DELETE_ADDRESS_API, {id: this.props.data.id}, this.OnDeleteSuccessSubmit, this.OnFailureFormSubmit);
    }

    render() {
        return (
            <div>
                {/*<div className={"p-2 bg-white mb-2 fw-bold d-flex flex-column justify-content-center align-items-center"}>*/}
                {/*    <img alt={"bengaluru"} src={bengaluru_image} />*/}
                {/*    <div>*/}
                {/*        We are only serving in Bengaluru, Karnataka*/}
                {/*    </div>*/}
                {/*</div>*/}
                <form onSubmit={this.OnSubmitForm} ref={this.addressForm}>
                    <div className={"bg-white p-2"}>
                        <SellTitle title={this.props.title} />
                        {
                            this.state.update_success &&
                            <div className={"bg-success p-2 rounded text-white my-2"}>
                                Updated address successfully
                            </div>
                        }
                        <Row className={"g-0"}>
                            <Col className={"visually-hidden"} md={4}>
                                <label className={"fw-bold small"}>
                                    ID
                                </label>
                                <Form.Control name={"id"} placeholder={"address id"} />
                            </Col>

                            <Col className={"p-1"} md={4}>
                                <label className={"fw-bold small"}>
                                    First Name <span className={"text-danger"}>*</span>
                                </label>
                                <Form.Control name={"firstName"} required minLength={3} maxLength={20}
                                              defaultValue={this.props.user.firstName}
                                              placeholder={"Enter your first name"} className={"text-capitalize"}/>
                            </Col>
                            <Col className={"p-1"} md={4}>
                                <label className={"fw-bold small"}>
                                    Last Name
                                </label>
                                <Form.Control name={"lastName"} placeholder={"Enter your family name"} maxLength={20}
                                              defaultValue={this.props.user.lastName}
                                              className={"text-capitalize"}/>
                            </Col>
                            <Col className={"p-1"} md={4}>
                                <label className={"fw-bold small"}>
                                    Mobile Number <span className={"text-danger"}>*</span>
                                </label>
                                <Form.Control name={"mobile"} placeholder={"Enter mobile number"} required
                                              type={"number"} min={1000000000} max={9999999999}
                                              className={"text-capitalize"}/>
                            </Col>
                            <Col className={"p-1"} md={4}>
                                <label className={"fw-bold small"}>
                                    Address Line 1 <span className={"text-danger"}>*</span>
                                </label>
                                <Form.Control name={"addressLine1"} placeholder={"Address Line 1"} required maxLength={40} />
                            </Col>

                            <Col className={"p-1"} md={4}>
                                <label className={"fw-bold small"}>
                                    Address Line 2
                                </label>
                                <Form.Control name={"addressLine2"} placeholder={"Address Line 2"} maxLength={40}/>
                            </Col>

                            <Col className={"p-1"} md={4}>
                                <DropDownSelect items={[{name:"Home", value:"HOME"},{name:"Office", value: "OFFICE"}]} name={"addressType"} label={"Address Type"} required={true}
                                                placeholder={"Select address type"} defaultIndex={0} />
                            </Col>

                            <Col className={"p-1"} md={4}>
                                <label className={"fw-bold small"}>
                                    Pin Code <span className={"text-danger"}>*</span>
                                </label>
                                <Form.Control name={"pinCode"} type={"number"} placeholder={"Enter pin code"} required min={100000} max={999999} />
                            </Col>

                            <Col className={"p-1"} md={4}>
                                <label className={"fw-bold small"}>
                                    City <span className={"text-danger"}>*</span>
                                </label>
                                <Form.Control name={"city"} placeholder={"Enter city name"} disabled defaultValue={"Bengaluru"} required minLength={2} maxLength={30} />
                            </Col>

                            <Col className={"p-1"} md={4}>
                                <label className={"fw-bold small"}>
                                    State <span className={"text-danger"}>*</span>
                                </label>
                                <Form.Select name={"state"} placeholder={"Enter state name"} required minLength={2} maxLength={30} disabled >
                                    <option value={"KA"}>Karnataka</option>
                                </Form.Select>
                            </Col>

                            <Col className={"p-1"} md={6}>
                                <label className={"fw-bold small"}>
                                    Landmark
                                </label>
                                <Form.Control name={"landmark"} placeholder={"Enter landmark"} maxLength={50}/>
                            </Col>

                            <Col className={"p-1"} md={6}>
                                <label className={"fw-bold small"}>
                                    Special Instruction
                                </label>
                                <Form.Control name={"instruction"} placeholder={"Any special instruction"} maxLength={50}/>
                            </Col>

                            <Col className={"p-1 visually-hidden"} md={6} maxLength={40}>
                                <label className={"fw-bold small"}>
                                    Lat Long
                                </label>
                                <Form.Control name={"latLong"} placeholder={"Enter state name"}/>
                            </Col>
                        </Row>
                        <div className={"my-3"}>
                            {
                                this.props.deleteBtn &&
                                <Button disabled={this.state.is_loading} variant={"danger"}
                                        className={"m-1 fw-bold shadow-none"} type={"button"}
                                        onClick={this.OnClickDeleteAddress}>
                                    <FontAwesomeIcon icon={"trash"}/> Delete this address
                                </Button>
                            }

                            <Button disabled={this.state.is_loading} variant={"primary"}
                                    className={"m-1 fw-bold shadow-none"} type={"submit"}>
                                {this.state.is_loading && <Spinner animation={"border"} size={"sm"} />} {this.props.btnText}
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default Connector(AddressForm);