import {Link} from "react-router-dom";
import React from "react";
import "./breadcrub.css";

function getCurrentPathStack(base_url) {
    let pathname_list = window.location.pathname
        .replace(base_url + "/", "")
        .split("/");

    let global_link = base_url;
    let paths = [];

    for (const pathname of pathname_list) {
        global_link += "/" + pathname;
        if(pathname === "new" || pathname === "item") {
            continue;
        }

        paths.push({
            name: pathname,
            link: global_link
        })
    }

    return paths;
}

export function  BreadCrumb({base_url}) {
    let pathStack = getCurrentPathStack(base_url);
    return <div className={"bg-white overflow-hidden shadow-sm rounded text-uppercase d-flex"}>
        {
            pathStack.map((path, index) => <div className={"d-flex"} key={"path_" + index}><Link className={"no-text-decor"} to={path.link}>
                <div className={"text-light bg-primary p-1 hover-color-light-blue small"}>
                    {path.name}
                </div>
            </Link>
                {
                    (index >= 0) && (index < pathStack.length - 1) &&
                    <div className={"text-light bg-primary p-1 small"}>
                        &gt;
                    </div>
                }
            </div>)
        }
    </div>;
}