import React, {Component} from 'react';
import {BASE_ACCOUNT_URL} from "../urls/AccountUrl";
import {BackendGETRequest} from "../../../../webrequests/BackendRequest";
import {Col, Container, Form, Row} from "react-bootstrap";
import SpinLoaderWithText from "../../../general/loader/SpinLoaderWithText";
import {SellPurchaseButtons} from "../../../home/sections/SellPurchaseButtons";
import {Connector} from "../../../../../../redux/Connector";
import {GET_ORDERS_API} from "../../../../constants/backend/APIConstant";
import {isNullOrUndefinedObject} from "../../../../utils/validators/Validator";
import {
    ORDER_STATE
} from "../../../../constants/SellOrderState";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import OrderViewComponent from "./OrderViewComponent";
import GeneralBreadCrumb from "../../../general/breadcrumb/GeneralBreadCrumb";

class Orders extends Component {
    state = {
        orders: [],
        is_loading: true,
        all_disabled: true,
        filters: [
            {
                label: "Pending for my actions",
                enabled: false,
                match: ORDER_STATE.START_SELL
            },
            {
                label: "Sent for inspection",
                enabled: false,
                match: ORDER_STATE.SENT_FOR_INSPECTION
            },
            {
                label: "Completed order",
                enabled: false,
                match: ORDER_STATE.PAYMENT_COMPLETED
            }
        ],
        navigator_links: [
            {
                title: "My Account",
                link: BASE_ACCOUNT_URL
            },
            {
                title: "My Orders"
            }
        ]
    }

    constructor(props) {
        super(props);
        this.OnFailureRequest = this.OnFailureRequest.bind(this);
        this.OnSuccessRequest = this.OnSuccessRequest.bind(this);
        this.OrdersComponent = this.OrdersComponent.bind(this);
        this.OnChangeFilter = this.OnChangeFilter.bind(this);
    }

    OnChangeFilter(event, index) {
        let filters = this.state.filters;
        filters[index].enabled = event.target.checked;

        this.setState({
            filters: filters,
            all_disabled: filters.every(filter => !filter.enabled)
        });
    }

    componentDidMount() {
        BackendGETRequest(this.props, GET_ORDERS_API, this.OnSuccessRequest, this.OnFailureRequest);
    }

    OnSuccessRequest(response) {
        this.setState({
            orders: response.body["orders"],
            is_loading: false
        });
    }

    OnFailureRequest() {
        this.setState({
            is_loading: false
        });
    }

    OrdersComponent() {
        if(isNullOrUndefinedObject(this.state.orders) || this.state.orders.length === 0) {
            return <div className={"d-flex flex-column justify-content-center align-items-center flex-fill p-2 py-5"}>
                <p className={"text-secondary"}>
                    No orders found
                </p>
                <SellPurchaseButtons/>
            </div>;
        }

        return <Row className={"g-0 flex-fill"}>
            {
                this.state.orders.filter(order => this.state.filters.some(filter => this.state.all_disabled || (filter.enabled && order.state === filter.match)))
                    .map((order, index) => <Col md={4} key={"order_index_" + index} className={"p-1"}>
                        <div className={"border shadow-sm hover-shadow rounded bg-white h-100"}>
                            <OrderViewComponent order={order} />
                        </div>
                    </Col>)
            }
        </Row>;
    }

    render() {
        return (
            <div className={"flex-fill d-flex flex-column"}>
                <Row className={"g-0"}>
                    <Col lg={2} xl={2}>
                        <Container className={"p-2"}>
                            <div className={"d-flex flex-column mt-1 bg-white h-100"}>
                                <div className={"fw-bold p-2 bg-primary text-white"}>
                                    <FontAwesomeIcon icon={"filter"} className={"me-1"} />
                                    Filters
                                </div>
                                {
                                    this.state.filters.map((filter, index) => <div key={"filter_i_" + index} className={"small p-2"}>
                                        <Form.Check type={"switch"} checked={filter.enabled} onChange={(event) => this.OnChangeFilter(event, index)} label={filter.label}/>
                                    </div>)
                                }
                            </div>
                        </Container>
                    </Col>

                    <Col lg={10} xl={10}>
                        <Container>
                            <div className={"d-flex flex-column my-1 h-100 p-1"}>
                                <div className={"bg-white rounded mb-2 p-2 m-1"}>
                                    <GeneralBreadCrumb items={this.state.navigator_links} />
                                </div>

                                {
                                    this.state.is_loading? <SpinLoaderWithText content={"Loading address"} /> : <this.OrdersComponent />
                                }
                            </div>
                        </Container>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Connector(Orders);