import React, {Component} from 'react';
import {Button} from "react-bootstrap";
import {Connector} from "../../../redux/Connector";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class LocationPositionGrant extends Component {

    state = {
        show_location_grant_permission: false,
        manually_grant_permission: false
    }

    constructor(props) {
        super(props);
        this.OnGrantLocationPermission = this.OnGrantLocationPermission.bind(this);
    }

    componentDidMount() {
        if(navigator.permissions && navigator.permissions.query) {
            navigator.permissions.query({name: 'geolocation'}).then(permissions => {
                if (permissions.state === "granted") {
                    this.OnGrantLocationPermission();
                } else if(permissions.state === "prompt") {
                    this.setState({
                        show_location_grant_permission: true
                    });
                } else if(permissions.state === "denied") {
                    this.setState({
                        manually_grant_permission: true
                    });
                }
            });
        } else {
            this.OnGrantLocationPermission();
        }
    }

    OnGrantLocationPermission(){
        if(!navigator.geolocation) {return;}
        navigator.geolocation.getCurrentPosition((position) => {
            this.setState({
                show_location_grant_permission: false
            });
            if(this.props.callbackFunction !== undefined) {
                this.props.callbackFunction(position.coords);
            }
        }, (error) => {
            if (error.code === error.PERMISSION_DENIED) {
                this.setState({
                    show_location_grant_permission: false,
                    manually_grant_permission: true
                });
            }
        });
    }

    render() {
        return (
            <div>
                {
                    this.state.show_location_grant_permission &&
                    <div className={"p-3"}>
                        <Button variant={"outline-primary shadow-none"} onClick={this.OnGrantLocationPermission}>
                            <FontAwesomeIcon icon={"map"} className={"pe-1"}/>
                            Grant Location Permission
                        </Button>
                    </div>
                }
                {
                    this.state.manually_grant_permission &&
                    <p className={"text-danger m-0 p-3"}>
                        Please manually grant location permission to get the accurate address location
                    </p>
                }
            </div>
        );
    }
}


export default Connector(LocationPositionGrant);
