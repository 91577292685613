import {COL, LOGIN_PAGE_URL, SELL_BICYCLE_NOW_PAGE_URL} from "../constants/application/ApplicationUrl";
import {Base64} from 'js-base64';
import {BRAND_ID_PARAM, MODEL_ID_PARAM} from "../constants/ParamConstant";
import {DEFAULT_BRAND_VALUE, DEFAULT_MODEL_VALUE} from "../constants/Constant";

export function GetAppBackendUrl() {
    return process.env.REACT_APP_BACKEND_URL || "";
}

export function GetLoginPageUrl() {
    return LOGIN_PAGE_URL + "?redirect=" + Base64.encodeURI(window.location.pathname);
}

export function GetDefaultBrandUrl() {
    return SELL_BICYCLE_NOW_PAGE_URL.replace(COL + BRAND_ID_PARAM, DEFAULT_BRAND_VALUE).replace(COL + MODEL_ID_PARAM, DEFAULT_MODEL_VALUE);
}