import React, {Component} from 'react';
import {Badge, Col, Row, Spinner} from "react-bootstrap";
import {Link} from "react-router-dom";
import {BreadCrumb} from "../../panels/breadcrum/BreadCrumb";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class SquarePanelComponent extends Component {
    render() {
        return (
            <div>
                <Row className={"g-0"}>
                    <Col sm={12} className={"p-2"}>
                        <BreadCrumb base_url={this.props.base_url} />
                    </Col>
                    {
                        this.props.render.map((component, index) => {
                            return <Col key={"component_" + index} sm={6} md={4} lg={3} className={"p-2"}>
                                <Link to={component.link} className={"no-text-decor"}>
                                    <div className={"shadow-sm border position-relative overflow-hidden d-flex rounded-3 hover-shadow align-items-center bg-white h-100"}>
                                        <div className={"bg-primary text-white p-3 py-4"}>
                                            <FontAwesomeIcon icon={"bicycle"} size={"2x"} />
                                        </div>
                                        <div className={"p-2"}>
                                            <h6 className={"m-0"}>
                                                {component.name}
                                            </h6>
                                            <p className={"m-0 small"}>
                                                {component.subtitle}
                                            </p>
                                        </div>
                                        {
                                            component.count > 0 &&
                                            <div className={"position-absolute end-0 top-0 px-1"}>
                                                {
                                                    <Badge bg={"danger"}>
                                                        {this.props.isLoading? <Spinner animation={"border"} size={"sm"} /> : component.count}
                                                    </Badge>
                                                }
                                            </div>
                                        }
                                    </div>
                                </Link>
                            </Col>
                        })
                    }
                </Row>
            </div>
        );
    }
}

export default SquarePanelComponent;