import React, {Component} from 'react';
import {Button, Col, Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import best_price_icon from "../../statics/img/icons/best_price.png";
import refurbished_icon from "../../statics/img/icons/refburnised.png";
import service_icon from "../../statics/img/icons/service.png";
import truck_load_icon from "../../statics/img/icons/truck_load.png";
import warranty_icon from "../../statics/img/icons/warranty.png";
import {Link} from "react-router-dom";
import {BUY_BICYCLE_NOW_PAGE_URL} from "../../constants/application/ApplicationUrl";
import {Connector} from "../../../../redux/Connector";
import {GetLoginPageUrl} from "../../utils/PixieMartUtil";
import {SetDocumentTitle} from "../../managers/DocumentTitleManager";
import {BackendGETRequest} from "../../webrequests/BackendRequest";
import {ShowErrorModalFromError} from "../modals/centermodal/CenterModalScreenManager";
import {ITEM_ID_PARAM} from "../../constants/ParamConstant";
import {GET_ITEM_FOR_SELL_API} from "../../constants/backend/APIConstant";
import {isEmptyString} from "../../utils/validators/Validator";

class Item extends Component {
    state = {
        bicycle: {
            specifications: []
        },
        panels: [
            {
                icon: best_price_icon,
                name: "Best Price guarantee",
            },
            {
                icon: refurbished_icon,
                name: "Refurbished"
            },
            {
                icon: warranty_icon,
                name: "6 month warranty"
            },
            {
                icon: truck_load_icon,
                name: "Free delivery"
            },
            {
                icon: service_icon,
                name: "3 months free servicing"
            }
        ]
    }
    item_images = [
        {},{},{},{},{}
    ]
    
    componentDidMount() {
        SetDocumentTitle("Buy bicycle in Bengaluru, India");
        BackendGETRequest(this.props, GET_ITEM_FOR_SELL_API + this.props.match.params[ITEM_ID_PARAM], (response) => {
            this.setState({
                bicycle: response.body
            });
        }, (error) => ShowErrorModalFromError(this.props, "Requesting bicycle item", error));
        this.discountPercent = this.discountPercent.bind(this);
    }

    discountPercent() {
        if(isEmptyString(this.state.bicycle.price)) {
            return "";
        }

        let discount = Number(this.state.bicycle.price.replace(",",""))*100 / parseInt(this.state.bicycle.mrp.replace(",", "").replace("₹",""));
        return Math.round(discount * 100) / 100;
    }

    render() {
        let discountPrice = this.discountPercent();

        return (
            <div className={"pt-4"}>
                <Container>
                    <div className={"p-2"}>
                        <Row className={"g-0"}>
                            <Col xs={6}>
                                {
                                    this.state.bicycle.images?.length > 0 &&
                                    <img src={this.state.bicycle.images[0]} alt={"item"}
                                        className={"w-100 rounded"}/>
                                }
                                <div className={"d-flex w-100 overflow-scroll justify-content-center mt-3 shadow-sm p-3 rounded"} style={{height: 90}}>
                                    {
                                        this.state.bicycle.images?.map((image, index) =>{
                                           return <img key={"item_index_" + index} src={image} alt={"item"}
                                                        className={"h-100 mx-1 rounded shadow-sm"}/>
                                        })
                                    }
                                </div>
                            </Col>
                            <Col xs={6} className={"px-3"}>
                                <div className={"bg-white rounded p-2 shadow-sm h-100"}>
                                    <h6 className={"fw-bold"}>
                                        {this.state.bicycle.modelName}
                                    </h6>
                                    {/*<p className={"fw-bold text-muted small"}>927 ratings | 123 comments</p>*/}
                                    <b className={""}>
                                        <FontAwesomeIcon icon={"rupee-sign"}/> {this.state.bicycle.price}
                                    </b>
                                    <b className={"text-muted text-decoration-line-through small"}> {this.state.bicycle.mrp}</b>
                                    {
                                        discountPrice &&
                                        <b className={"text-success fs-12"}> {discountPrice}% off</b>
                                    }
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div>
                        <h6 className={"fw-bold"}>
                            Specifications
                        </h6>
                        <div>
                            <ul>
                                {
                                    this.state.bicycle.specifications.map((specification, index) => {
                                        return <li className={"m-0 small"} key={"product_index_" + index}>
                                            {specification}
                                        </li>
                                    })
                                }
                            </ul>
                        </div>
                    </div>

                    <div className={"py-2"}>
                        <div className={"d-flex"}>
                            {
                                this.state.panels.map((panel, index) => {
                                    return <div key={"panel_i_"+ index}>
                                        <div className={"m-1 d-flex flex-column justify-content-center"}>
                                            <div className={"d-flex justify-content-center w-100"}>
                                                <div style={{width: 30}} className={"mb-2"}>
                                                    <img alt={"panel_" + index} src={panel.icon} className={"img-object-fit-cover"} />
                                                </div>
                                            </div>
                                            <p className={"text-center"} style={{fontSize: 10}} >
                                                {panel.name}
                                            </p>
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>

                    <div className={"py-2 text-center"}>
                        {
                            this.props.user.username?
                                <Button variant={"success"} as={Link} to={BUY_BICYCLE_NOW_PAGE_URL}>
                                    Buy now
                                </Button>:
                                <Button variant={"success"} as={Link} to={GetLoginPageUrl()}>
                                    Login to buy now
                                </Button>
                        }
                    </div>

                    <div className={"mt-5"}>
                        <h5>
                            FAQ
                        </h5>
                        <div>
                            <b>
                                Is this item is made of plastic material or metallic ?
                            </b>
                            <p>
                                rs, as opposed to using 'Content here, content here', making it look like readable
                                English. Many desktop publishing packages and web page editors now use Lorem Ipsum
                                as their default model text, and a search for 'lorem ipsum' will uncover many web
                                sites still in their infancy. Various versions have evolved over the years,
                                sometimes by accident, sometimes on purpose (injected humour and the like)
                            </p>
                        </div>

                        <div>
                            <b>
                                Is this item is made of plastic material or metallic ?
                            </b>
                            <p>
                                rs, as opposed to using 'Content here, content here', making it look like readable
                                English. Many desktop publishing packages and web page editors now use Lorem Ipsum
                                as their default model text, and a search for 'lorem ipsum' will uncover many web
                                sites still in their infancy. Various versions have evolved over the years,
                                sometimes by accident, sometimes on purpose (injected humour and the like)
                            </p>
                        </div>
                    </div>

                    {/*<div className={"mt-5"}>*/}
                    {/*    <h5>*/}
                    {/*        Reviews and comments*/}
                    {/*    </h5>*/}
                    {/*    <p>*/}
                    {/*        rs, as opposed to using 'Content here, content here', making it look like readable*/}
                    {/*        English. Many desktop publishing packages and web page editors now use Lorem Ipsum*/}
                    {/*        as their default model text, and a search for 'lorem ipsum' will uncover many web*/}
                    {/*        sites still in their infancy. Various versions have evolved over the years,*/}
                    {/*        sometimes by accident, sometimes on purpose (injected humour and the like)*/}
                    {/*    </p>*/}
                    {/*</div>*/}
                    {/*<div>*/}
                    {/*    <SectionItems items={[{},{}]} label={"Similar products"} />*/}
                    {/*    <SectionItems items={[{},{}]} label={"Recently viewed"} />*/}
                    {/*</div>*/}
                </Container>
            </div>
        );
    }
}

export default Connector(Item);