import axios from "axios";
import {optionProvider} from "./RequestConfig";
import {FAILURE_STATUS, SUCCESS_STATUS} from "../constants/Constant";
import {ResetUserData} from "../../../redux/ConnectorWrapper";
import {GaEventTracker} from "../google/analytics/GoogleAnalyticsEventTracker";
import {ShowOkButtonErrorModal} from "../components/modals/centermodal/CenterModalScreenManager";

export function BackendGETRequest(props, URL, successCallback, failureCallback) {
    axios.get(URL, optionProvider(props))
        .then(response => {
            if(response.data.status === SUCCESS_STATUS) {
                successCallback(response.data);
                return;
            }
            if(failureCallback !== undefined && response.data.status === FAILURE_STATUS) {
                failureCallback(response);
                return;
            }
            console.warn("[GET] Something unexpected response: ", response);
        })
        .catch(error => {
            error.url = URL;
            console.error(error);
            GaEventTracker("[GET] Request failed: ", error);
            if(error.response !== undefined && error.response.status === 401 &&
                error.response.data !== undefined && error.response.data.status === FAILURE_STATUS &&
                error.response.data.message !== undefined && error.response.data.message.includes("authentication is required")) {
                ResetUserData(props);
            }

            if(failureCallback !== undefined) {
                failureCallback(error);
            }
        });
}

export function BackendPOSTRequest(props, URL, data, successCallback, failureCallback) {
    axios.post(URL, data, optionProvider(props))
        .then(response => {
            if(response.data.status === SUCCESS_STATUS) {
                successCallback(response.data);
                return;
            }
            if(failureCallback !== undefined && response.data.status === FAILURE_STATUS) {
                failureCallback(response);
                return;
            }
            console.warn("[POST] Something unexpected response: ", response);
        })
        .catch(error => {
            error.url = URL;
            console.error(error);
            GaEventTracker("[POST] Request failed: ", error);
            if(failureCallback !== undefined) {
                failureCallback(error);
            }
        });
}

function OnProgress(progressEvent, callback) {
    const percentComplete = Math.round((progressEvent.loaded / progressEvent.total) * 10000) / 100;
    callback(percentComplete);
}

export function BackendPOSTRequestWithImage(props, URL, data, successCallback, failureCallback, progressCallback) {
    let fileSize = Math.round(data.get("image")?.size/1024);
    if(fileSize > 4096) {
        ShowOkButtonErrorModal(props, "Image upload error", "Please select an image with size less than 4mb");
        if(failureCallback !== undefined) {
            failureCallback({});
        }
        return;
    }

    let options = optionProvider(props);
    options.headers["Content-Type"] = "multipart/form-data";
    if(progressCallback !== undefined) {
        options['onUploadProgress'] = (progressEvent) => OnProgress(progressEvent, progressCallback);
    }

    axios.post(URL, data, options)
        .then(response => {
            if(response.data.status === SUCCESS_STATUS) {
                successCallback(response.data);
                return;
            }
            if(failureCallback !== undefined) {
                failureCallback(response);
            }
        })
        .catch(error => {
            error.url = URL;
            console.log(error);
            GaEventTracker("[IMAGE] Request failed: ", error);
            if(failureCallback !== undefined) {
                failureCallback(error);
            }
        });
}