import React, {Component} from 'react';
import {Route, Switch} from "react-router-dom";
import {
    BASE_ACCOUNT_URL,
    CREATE_NEW_ADDRESS_URL,
    MY_ADDRESS_URL,
    MY_ORDERS_URL,
    MY_SECURITY_URL, MY_SINGLE_ORDER_URL,
    UPDATE_ADDRESS_URL
} from "./AccountUrl";
import Account from "../Account";
import Address from "../address/Address";
import Orders from "../orders/Orders";
import Security from "../security/Security";
import CreateNewAddress from "../address/CreateNewAddress";
import UpdateAddress from "../address/UpdateAddress";
import SingleOrder from "../orders/SingleOrder";

class AccountUrlComponent extends Component {
    render() {
        return (
            <Switch>
                <Route path={BASE_ACCOUNT_URL} exact={true} component={Account}/>

                {/*ORDER*/}
                <Route path={MY_ORDERS_URL} exact={true} component={Orders}/>
                <Route path={MY_SINGLE_ORDER_URL} exact={true} component={SingleOrder}/>

                {/*SECURITY*/}
                <Route path={MY_SECURITY_URL} exact={true} component={Security}/>

                {/*ADDRESS*/}
                <Route path={MY_ADDRESS_URL} exact={true} component={Address}/>
                <Route path={CREATE_NEW_ADDRESS_URL} exact={true} component={CreateNewAddress}/>
                <Route path={UPDATE_ADDRESS_URL} exact={true} component={UpdateAddress}/>
            </Switch>
        );
    }
}

export default AccountUrlComponent;