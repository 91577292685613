import React, {Component} from 'react';
import OrderViewComponent from "../../../user/account/orders/OrderViewComponent";
import {BackendGETRequest} from "../../../../webrequests/BackendRequest";
import {GET_ORDERS_API} from "../../../../constants/backend/APIConstant";
import {Connector} from "../../../../../../redux/Connector";
import {Accordion} from "react-bootstrap";
import "./moreinorders.css";
class MoreInOrders extends Component {

    state = {
        orders: []
    }

    componentDidMount() {
        if(this.props.user.username) {
            BackendGETRequest(this.props, GET_ORDERS_API, (response) => {
                this.setState({orders: response.body["orders"]});
            });
        }
    }

    render() {
        return (
            <div className={"d-flex flex-column"}>
                {
                    this.state.orders.length > 0 &&
                    <div className={"mt-3 bg-white p-0 rounded"}>
                        <Accordion>
                            <Accordion.Item eventKey="0" className={"border-0 m-1"}>
                                <Accordion.Header className={"rounded bg-light-blue2 p-0"}>
                                    <h5 className={"fw-bold text-primary m-0"}>My Past Orders</h5>
                                </Accordion.Header>
                                <Accordion.Body className={"p-0"}>
                                    <div className={"d-flex flex-wrap py-2"}>
                                        {
                                            this.state.orders.slice(0, 3).map((order, index) => <div className={"p-1 col-md-4"} key={"address_" + index}>
                                                <div className={"border shadow-sm hover-shadow rounded bg-white h-100"}>
                                                    <OrderViewComponent key={"order_index_" + index} order={order} />
                                                </div>
                                            </div>)
                                        }
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                }
            </div>
        );
    }
}

export default Connector(MoreInOrders);