export const ORDER_STATE = {
    START_SELL: "START_SELL",
    SENT_FOR_INSPECTION: "SENT_FOR_INSPECTION",
    INSPECTOR_ALLOCATED: "INSPECTOR_ALLOCATED",
    INSPECTION_STARTED: "INSPECTION_STARTED",
    INSPECTION_COMPLETED: "INSPECTION_COMPLETED",
    PRICE_ESTIMATED: "PRICE_ESTIMATED",
    PAYMENT_APPROVED: "PAYMENT_APPROVED",
    PAYMENT_COMPLETED: "PAYMENT_COMPLETED",
    PICKUP_COMPLETED: "PICKUP_COMPLETED"
}