import React, {Component} from 'react';
import {Button, Container} from "react-bootstrap";
import {BackendGETRequest, BackendPOSTRequest} from "../../../../webrequests/BackendRequest";
import {
    DELETE_BRAND_API,
    GET_SINGLE_BRAND_API
} from "../../../../constants/backend/APIConstant";
import {Connector} from "../../../../../../redux/Connector";
import {COL} from "../../../../constants/application/ApplicationUrl";
import {BreadCrumb} from "../../../panels/breadcrum/BreadCrumb";
import {BASE_SELLER_URL, SELLER_EDIT_SINGLE_BRAND_URL, SELLER_SHOW_ALL_BRANDS_URL} from "../urls/SellerUrl";
import {BRAND_ID_PARAM} from "../../../../constants/ParamConstant";

class SellerShowSingleBrand extends Component {
    state = {
        brand: {}
    }

    constructor(props) {
        super(props);
        this.onSingleBrandsSuccessCallback = this.onSingleBrandsSuccessCallback.bind(this);
        this.onBrandFailureCallback = this.onBrandFailureCallback.bind(this);
        this.onClickDeleteButton = this.onClickDeleteButton.bind(this);
        this.onClickEditButton = this.onClickEditButton.bind(this);
    }

    componentDidMount() {
        BackendGETRequest(this.props, GET_SINGLE_BRAND_API.replace(COL + BRAND_ID_PARAM, this.props.match.params[BRAND_ID_PARAM]), this.onSingleBrandsSuccessCallback, this.onBrandFailureCallback)
    }

    onSingleBrandsSuccessCallback(response) {
        this.setState({
            brand: response.body
        });
    }

    onBrandFailureCallback(error) {

    }

    onClickDeleteButton() {
        BackendPOSTRequest(this.props, DELETE_BRAND_API.replace(COL + BRAND_ID_PARAM, this.props.match.params[BRAND_ID_PARAM]),
            {
                "brandId": this.props.match.params[BRAND_ID_PARAM]
            },
            ()=> { window.location.href = SELLER_SHOW_ALL_BRANDS_URL;},
            this.onBrandFailureCallback);
    }

    onClickEditButton() {
        window.location.href = SELLER_EDIT_SINGLE_BRAND_URL.replace(COL + BRAND_ID_PARAM, this.props.match.params[BRAND_ID_PARAM]);
    }

    render() {
        return (
            <div>
                <Container className={"pt-5p"}>
                    <h3>Brand</h3>
                    <div className={"py-2"}>
                        <BreadCrumb base_url={BASE_SELLER_URL} />
                    </div>

                    <div className={"d-flex"}>
                        <div className={"shadow-sm p-2 bg-white col-6"}>
                            <div>
                                <img alt={"brand"} src={this.state.brand.image} className={"w-100"} />
                            </div>
                            <div>
                                {this.state.brand.name}
                            </div>
                        </div>
                        <div className={"p-2 d-flex flex-column"}>
                            <Button variant={"success"} className={"m-2"} onClick={this.onClickEditButton}>Edit this brand</Button>
                            <Button variant={"danger"} className={"m-2"} onClick={this.onClickDeleteButton}>Delete this brand</Button>
                        </div>
                    </div>
                </Container>
            </div>
        );
    }
}

export default Connector(SellerShowSingleBrand);