import React, {Component} from 'react';
import AccountLinkSection from "../user/account/AccountLinkSection";

class AreYouLookingForSomethingElse extends Component {
    render() {
        return (
            <div>
                <div className={"fw-bold small"}>
                    Are you looking for something else
                </div>
                {
                    this.props.links.map((link, index) => {
                        return <div className={"my-1"} key={"account_link_" + index}>
                            <AccountLinkSection link={link.link} title={link.title} />
                        </div>
                    })
                }
            </div>
        );
    }
}

export default AreYouLookingForSomethingElse;