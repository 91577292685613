import React, {Component} from 'react';
import {BASE_SELLER_URL, SELLER_BRAND_URL, SELLER_BUY_BICYCLE_URL, SELLER_SELL_BICYCLE_URL} from "./urls/SellerUrl";
import SquarePanelsWithTitle from "../../general/selectable_square/SquarePanelsWithTitle";

class SellerHome extends Component {
    state = {
        render: [
            {
                name:"Manage brands",
                link: SELLER_BRAND_URL
            },
            {
                name:"Manage selling models",
                subtitle: "Models which user buy from Pixie Mart",
                link: SELLER_SELL_BICYCLE_URL
            },
            {
                name:"Manage buying models",
                subtitle: "Models which user sell to Pixie Mart",
                link: SELLER_BUY_BICYCLE_URL
            },
            // {
            //     name:"Statistics",
            //     link: ""
            // },
            // {
            //     name:"Seller Account Settings",
            //     link: ""
            // }
        ]
    }

    render() {
        return (
            <SquarePanelsWithTitle title={"Seller/Buyer Account"}
                                   subtitle={"Manage all buying and selling bicycle"}
                                   render={this.state.render}
                                   base_url={BASE_SELLER_URL} />
        );
    }
}

export default SellerHome;