import React, {Component} from 'react';
import {Route, Switch} from "react-router-dom";
import SellerHome from "../SellerHome";
import SellerItems from "../bicycles/sell/SellerItems";
import SellerCreateItem from "../bicycles/sell/SellerCreateItem";
import SellerShowAllInactiveItems from "../bicycles/sell/SellerShowAllInactiveItems";
import SellerShowAllActiveItems from "../bicycles/sell/SellerShowAllActiveItems";
import SellerShowSingleItem from "../bicycles/sell/SellerShowSingleItem";
import SellerBrands from "../brands/SellerBrands";
import SellerCreateBrand from "../brands/SellerCreateBrand";
import SellerShowAllBrands from "../brands/SellerShowAllBrands";
import SellerShowSingleBrand from "../brands/SellerShowSingleBrand";
import {
    SELLER_BRAND_URL,
    SELLER_CREATE_NEW_BRAND_URL, SELLER_CREATE_NEW_ITEM_URL, SELLER_BUY_BICYCLE_URL, SELLER_SHOW_ALL_ACTIVE_ITEMS_URL,
    SELLER_SHOW_ALL_BRANDS_URL, SELLER_SHOW_ALL_INACTIVE_ITEMS_URL, SELLER_SHOW_SINGLE_BICYCLE_URL,
    SELLER_SHOW_SINGLE_BRAND_URL, SELLER_URL, SELLER_SELL_BICYCLE_URL, SELLER_ADD_MODEL_WITH_AMAZON_ASIN_URL
} from "./SellerUrl";
import SellerBuyBicycle from "../bicycles/buy/SellerBuyBicycle";
import ModelWithAmazonASIN from "../bicycles/buy/ModelWithAmazonASIN";

class SellerUrlComponent extends Component {
    render() {
        return (
            <Switch>
                <Route path={SELLER_URL} exact={true} component={SellerHome}/>
                <Route path={SELLER_SELL_BICYCLE_URL} exact={true} component={SellerItems}/>
                <Route path={SELLER_CREATE_NEW_ITEM_URL} exact={true} component={SellerCreateItem}/>
                <Route path={SELLER_SHOW_ALL_INACTIVE_ITEMS_URL} exact={true} component={SellerShowAllInactiveItems}/>
                <Route path={SELLER_SHOW_ALL_ACTIVE_ITEMS_URL} exact={true} component={SellerShowAllActiveItems}/>
                <Route path={SELLER_SHOW_SINGLE_BICYCLE_URL} exact={true} component={SellerShowSingleItem}/>

                <Route path={SELLER_BUY_BICYCLE_URL} exact={true} component={SellerBuyBicycle} />
                <Route path={SELLER_ADD_MODEL_WITH_AMAZON_ASIN_URL} exact={true} component={ModelWithAmazonASIN} />

                <Route path={SELLER_BRAND_URL} exact={true} component={SellerBrands}/>
                <Route path={SELLER_CREATE_NEW_BRAND_URL} exact={true} component={SellerCreateBrand} />
                <Route path={SELLER_SHOW_ALL_BRANDS_URL} exact={true} component={SellerShowAllBrands} />
                <Route path={SELLER_SHOW_SINGLE_BRAND_URL} exact={true} component={SellerShowSingleBrand} />
            </Switch>
        );
    }
}

export default SellerUrlComponent;