import React, {Component} from 'react';
import {Route, Switch} from "react-router-dom";
import PrivacyPolicy from "../PrivacyPolicy";
import {PRIVACY_POLICY_URL} from "./PoliciesUrl";

class PoliciesUrlComponent extends Component {
    render() {
        return (
            <Switch>
                {/*PRIVACY POLICY*/}
                <Route path={PRIVACY_POLICY_URL} exact={true} component={PrivacyPolicy}/>
            </Switch>
        );
    }
}

export default PoliciesUrlComponent;