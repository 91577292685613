import React, {Component} from 'react';
import {Spinner} from "react-bootstrap";

class SpinLoaderWithText extends Component {
    render() {
        return (
            <div className={"d-flex justify-content-center align-items-center flex-column flex-fill p-3"}>
                <Spinner animation={"border"} />
                <p className={"p-2 text-muted"}>
                    {this.props.content}
                </p>
            </div>
        );
    }
}

export default SpinLoaderWithText;