import React, {Component} from 'react';
import CarouselPanel from "../general/CarouselPanel";
import HowItWorks from "./sections/howitworks/HowItWorks";
import SellSteps from "./sections/SellSteps";
import WhyUs from "./sections/whyus/WhyUs";
import {SellFAQ} from "./sections/SellFAQ";
import {TradeUpAndCashIn} from "./carousel/CarouselComponents";
import {Connector} from "../../../../redux/Connector";
import SellYourBicycle from "./sections/sellbicycle/SellYourBicycle";
import SearchBarComponent from "./sections/search/SearchBarComponent";

class Home extends Component {

    items = [
        {
            component: TradeUpAndCashIn(),
            link: ""
        },
    ]

    sections = [
        {
            label: "Most trending"
        },
        {
            label: "Best selling"
        },
        {
            label: "Best offers"
        }
    ]

    render() {
        return (
            <section className={"bg-white"}>

                <div className={"p-2 d-flex justify-content-center show_on_small_devices"}>
                    <SearchBarComponent brands={this.props.brands} />
                </div>

                <div className={"rounded-pm-1-sd m-2-sd"}>
                    <CarouselPanel items={this.items} />
                </div>
                {
                    (this.props.brands.length !== 0) &&
                    <SellYourBicycle brands={this.props.brands}/>
                }

                <SellSteps />
                <HowItWorks />
                <WhyUs />
                {/*<div className={"bg-white"}>*/}
                {/*    <TopSellingBrandsAndModels />*/}
                {/*</div>*/}

                <SellFAQ />

                {/*<Container>*/}
                {/*    <PixieGuarantee />*/}
                {/*</Container>*/}
                {/*<Categories />*/}
                {/*{*/}
                {/*    this.sections.map((section, index) => {*/}
                {/*        return  <SectionItems key={"section_item_" + index} label={section.label} />;*/}
                {/*    })*/}
                {/*}*/}
            </section>
        );
    }
}

export default Connector(Home);
