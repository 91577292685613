import React, {Component} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {BackendGETRequest} from "../../../../webrequests/BackendRequest";
import {GET_ALL_ADMIN_BRANDS_API} from "../../../../constants/backend/APIConstant";
import {Connector} from "../../../../../../redux/Connector";
import {Link} from "react-router-dom";
import {COL} from "../../../../constants/application/ApplicationUrl";
import {BreadCrumb} from "../../../panels/breadcrum/BreadCrumb";
import {BASE_SELLER_URL, SELLER_SHOW_SINGLE_BRAND_URL} from "../urls/SellerUrl";
import {BRAND_ID_PARAM} from "../../../../constants/ParamConstant";

class SellerShowAllBrands extends Component {
    state = {
        brands: []
    }

    constructor(props) {
        super(props);
        this.onAllBrandsSuccessCallback = this.onAllBrandsSuccessCallback.bind(this);
        this.onBrandFailureCallback = this.onBrandFailureCallback.bind(this);
    }

    componentDidMount() {
        BackendGETRequest(this.props, GET_ALL_ADMIN_BRANDS_API, this.onAllBrandsSuccessCallback, this.onBrandFailureCallback)
    }

    onAllBrandsSuccessCallback(response) {
        this.setState({
            brands: response.body["cycleBrands"]
        });
    }

    onBrandFailureCallback(error) {

    }

    render() {
        return (
            <div>
                <Container className={"pt-5p"}>
                    <h3 className={"px-2"}>All Brands</h3>
                    <div className={"py-2 px-2"}>
                        <BreadCrumb base_url={BASE_SELLER_URL} />
                    </div>

                    <Row className={"g-0"}>
                        {
                            this.state.brands.map(brand =>
                                <Col xs={4} md={2} key={"brand_i_" + brand.id} className={"no-text-decor p-1"} as={Link} to={SELLER_SHOW_SINGLE_BRAND_URL.replace(COL + BRAND_ID_PARAM, brand.id)}>
                                    <div className={"shadow-sm hover-shadow rounded p-2 bg-white"}>
                                        <img alt={"brand"} src={brand.image} className={"w-100"} />
                                        <div className={"small fw-bold text-truncate"}>
                                            {brand.name}
                                        </div>
                                    </div>
                                </Col>
                            )
                        }
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Connector(SellerShowAllBrands);