import React, {Component} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {BackendGETRequest} from "../../../../../webrequests/BackendRequest";
import {
    GET_ALL_ACTIVE_BICYCLES_API
} from "../../../../../constants/backend/APIConstant";
import {Connector} from "../../../../../../../redux/Connector";
import {Link} from "react-router-dom";
import {
    COL,

} from "../../../../../constants/application/ApplicationUrl";
import {BreadCrumb} from "../../../../panels/breadcrum/BreadCrumb";
import {BASE_SELLER_URL, SELLER_SHOW_SINGLE_BICYCLE_URL} from "../../urls/SellerUrl";
import {ITEM_ID_PARAM} from "../../../../../constants/ParamConstant";

class SellerShowAllActiveItems extends Component {
    state = {
        items: []
    }

    constructor(props) {
        super(props);
        this.onAllBicyclesSuccessCallback = this.onAllBicyclesSuccessCallback.bind(this);
        this.onBicycleFailureCallback = this.onBicycleFailureCallback.bind(this);
    }

    componentDidMount() {
        BackendGETRequest(this.props, GET_ALL_ACTIVE_BICYCLES_API, this.onAllBicyclesSuccessCallback, this.onBicycleFailureCallback)
    }

    onAllBicyclesSuccessCallback(response) {
        this.setState({
            items: response.body["items"]
        });
    }

    onBicycleFailureCallback(error) {

    }

    render() {
        return (
            <div>
                <Container className={"pt-5p"}>
                    <h3 className={"px-2"}>All Inactive Bicycles</h3>
                    <div className={"py-2 px-2"}>
                        <BreadCrumb base_url={BASE_SELLER_URL} />
                    </div>

                    <Row className={"g-0"}>
                        {
                            this.state.items.map(item =>
                                <Col md={3} key={"item_i_" + item.id} className={"no-text-decor p-2"} as={Link} to={SELLER_SHOW_SINGLE_BICYCLE_URL.replace(COL + ITEM_ID_PARAM, item.id)}>
                                    <div className={"shadow-sm hover-shadow p-2 bg-white"}>
                                        <div className={"bg-light"} style={{height: 160}}>
                                            {
                                                item.images.length > 0 &&
                                                <img alt={"item"} src={item.images[0]} className={"w-100 img-object-fit-cover"}/>
                                            }
                                        </div>
                                        <div className={"fw-bold"}>
                                            {item.name}
                                        </div>
                                        <div className={"text-primary small"}>
                                            Move this item to inactive to remove from sell
                                        </div>
                                    </div>
                                </Col>
                            )
                        }
                        {
                            this.state.items.length <= 0 &&
                            <Col md={12} className={"p-2"}>
                                <div className={"rounded text-center shadow-sm hover-shadow py-4 bg-light"}>
                                    No active items in production for sell
                                </div>
                            </Col>
                        }
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Connector(SellerShowAllActiveItems);