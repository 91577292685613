import {Placeholder} from "react-bootstrap";
import React from "react";

export function RoundedPlaceHolder() {
    return <Placeholder animation="glow">
        <Placeholder className={"w-100 h-100 rounded-pm-1"} />
    </Placeholder>
}

export function SquarePlaceHolder() {
    return <Placeholder animation="glow">
        <Placeholder className={"w-100 h-100 rounded"} />
    </Placeholder>
}