import {isPlatform} from "@ionic/react";
import GoogleAuthenticateAndroid from "./GoogleAuthenticateAndroid";
import GoogleAuthenticateWeb from "./GoogleAuthenticateWeb";

export function GoogleAuthProvider() {
    if(isPlatform("capacitor")) {
        console.log("[GoogleAuthProvider] capacitor");
        return <GoogleAuthenticateAndroid />
    }

    console.log("[GoogleAuthProvider] web");
    return <GoogleAuthenticateWeb />
}