import React, {Component} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import WhatWePurchase from "./WhatWePurchase";
import {SellBicycleNowButton} from "../../home/sections/SellPurchaseButtons";
import {Connector} from "../../../../../redux/Connector";
import {SellFAQ} from "../../home/sections/SellFAQ";
import {SetDocumentTitle} from "../../../managers/DocumentTitleManager";

class SellNowPage extends Component {

    constructor(props) {
        super(props);
        this.HowItWorksSell = this.HowItWorksSell.bind(this);
    }

    state = {
        steps: [
            {
                title: "Click the Sell Now button anywhere on the app/website"
            },
            {
                title: "Select your brand and model, select other in case if you don't find your brand or model"
            },
            {
                title: "A Pixie guide will schedule an appointment within 48 working hours to review the product"
            },
            {
                title: "You will get a call within 48 working hours of the inspection with an offer based on brand, safety, and condition standard"
            },
            {
                title: "Get instant payment to your account while pickup your bicycle"
            },
            {
                title: " A Pixie guide will visit your place and lock the ride. It will also be covered, however, the ride stays at your place till it is sold out."
            }
        ]
    }

    componentDidMount() {
        SetDocumentTitle("Sell my old bicycle in Bengaluru", "Are you looking into sell your old bicycle and get the best price in exchange with the home pickup experience");
    }

    HowItWorksSell() {
        return <div className={"p-2 bg-light-green py-5"}>
            <h2 className={"fw-bold text-center pb-4 fs_heading2"}>How it works</h2>
            <div className={"py-3 container"}>
                {
                    this.state.steps.map((step, index) => {
                        return <div key={"step_" + index} className={"d-flex p-1 align-items-center"}>
                            <div className={"rounded-circle bg-white m-2 flex-shrink-0 d-flex justify-content-center align-items-center"} style={{width: 34, height: 34}}>
                                {index + 1}
                            </div>
                            <div className={""}>
                                {
                                    step.title
                                }
                            </div>
                        </div>
                    })
                }
            </div>
            <div className={"p-2 text-center"}>
                <p className={"fw-bold my-2"}>
                    Hurray...... It's done!!!
                </p>
                <p className={"fw-bold px-4"} >
                    Pixie takes care of the ride from here including the pick-up and delivery to the buyer
                </p>
            </div>
            <SellBicycleNowButton username={this.props.user.username} />
        </div>
    }

    render() {
        return (
            <section className={"bg-light"}>
                <Row className={"g-0"}>
                    <Col md={12}>
                        <div className={"bg-light-green p-2"}>
                            <Container>
                                <h1 className={"py-3 fw-bold fs_heading"}>
                                    Want to sell your kid's pre loved rides? We've got you covered!!
                                </h1>
                                <div style={{minHeight: 90}} className={"overflow-hidden"}>
                                    <p className={"fs_75c_10 m-0"}>
                                        Cycles, Tricycles or Balance cycles? No matter what you want to sell,
                                    </p>
                                    <p className={"fs_75c_10"}>
                                        you're just a few steps away from a hassle-free transaction
                                    </p>
                                </div>
                                <div className={"w-100 d-flex"}>
                                    <SellBicycleNowButton username={this.props.user.username} />
                                </div>
                            </Container>
                        </div>
                    </Col>
                </Row>
                <div>
                    <WhatWePurchase />

                    <this.HowItWorksSell />

                    <SellFAQ />
                </div>
            </section>
        );
    }
}

export default Connector(SellNowPage);