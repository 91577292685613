import React, {Component} from "react";
import './App.css';
import Home from "../home/Home";
import Footer from "../footer/Footer";
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import NavbarComponent from "../navbar/NavbarComponent";
import {
    BUY_NOW_PAGE_URL,
    HOME_URL,
    ITEM_URL,
    SELL_BICYCLE_NOW_PAGE_URL,
    SELL_NOW_PAGE_URL,
    LOGIN_PAGE_URL, SHOW_ALL_USER_BRANDS_URL, SHOW_ALL_USER_MODELS_FOR_BRAND_URL
} from "../../constants/application/ApplicationUrl";
import Item from "../item/Item";
import SellNowPage from "../spages/sell/SellNowPage";
import SellBicycleNow from "../spages/sell/SellBicycleNow";
import BuyNowPage from "../spages/buy/BuyNowPage";
import LoginPage from "../login/LoginPage";
import {Connector} from "../../../../redux/Connector";
import {GetUser} from "../../webrequests/requestwrapper/Authenticate";
import CenterScreenModal from "../modals/centermodal/CenterScreenModal";
import SellerUrlComponent from "../user/seller/urls/SellerUrlComponent";
import {SELLER_URL} from "../user/seller/urls/SellerUrl";
import {INSPECTOR_URL} from "../user/inspector/urls/InspectorUrl";
import InspectorUrlComponent from "../user/inspector/urls/InspectorUrlComponent";
import {BASE_ACCOUNT_URL} from "../user/account/urls/AccountUrl";
import AccountUrlComponent from "../user/account/urls/AccountUrlComponent";
import ShowAllBrands from "../spages/sell/ShowAllBrands";
import ShowAllModelsForBrand from "../spages/sell/ShowAllModelsForBrand";
import {GOOGLE_ANALYTICS_UA} from "../../constants/Config";
import ReactGA from "react-ga4";
import {APPROVAL_URL} from "../user/approvers/urls/ApprovalsUrls";
import ApprovalsUrlsComponent from "../user/approvers/urls/ApprovalsUrlsComponent";
import PoliciesUrlComponent from "../policies/urls/PoliciesUrlComponent";
import {POLICIES_BASE_URL} from "../policies/urls/PoliciesUrl";
import {BackendGETRequest} from "../../webrequests/BackendRequest";
import {GET_ALL_BRANDS_API} from "../../constants/backend/APIConstant";
import {isNullOrUndefinedObject} from "../../utils/validators/Validator";

ReactGA.initialize(GOOGLE_ANALYTICS_UA);

class App extends Component {

    state = {
        brands: []
    }

    constructor(props) {
        super(props);
        this.AuthenticatedRouteComponent = this.AuthenticatedRouteComponent.bind(this);
    }

    componentDidMount() {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: window.document.title });
        GetUser(this.props);
        BackendGETRequest(this.props, GET_ALL_BRANDS_API, (response) => {
            if(!isNullOrUndefinedObject(response.body["cycleBrands"])) {
                response.body["cycleBrands"].sort((a, b) => a.name.localeCompare(b.name));
                this.setState({brands: response.body["cycleBrands"]});
            }
        });
    }


    AuthenticatedRouteComponent() {
        if(!this.props.user.username) {
            return <Redirect to={HOME_URL} exact={true} />;
        }

        return <>
            <Route path={SELLER_URL} component={SellerUrlComponent} />
            <Route path={INSPECTOR_URL} component={InspectorUrlComponent} />
            <Route path={APPROVAL_URL} component={ApprovalsUrlsComponent} />
            <Route path={BASE_ACCOUNT_URL} component={AccountUrlComponent}/>
        </>;
    }

    render() {
        return (
            <div className="App d-flex flex-column">
                <Router>
                    <Switch>
                        <Route path={""} render={() => <NavbarComponent brands={this.state.brands} />} />
                    </Switch>

                    <div className={"flex-fill d-flex flex-column"}>
                        <Switch>
                            <Route path={HOME_URL} exact={true} render={() => <Home brands={this.state.brands} />} />
                            <Route path={ITEM_URL} exact={true} component={Item}/>

                            <Route path={SELL_NOW_PAGE_URL} exact={true} component={SellNowPage} />
                            <Route path={SHOW_ALL_USER_BRANDS_URL} exact={true} component={ShowAllBrands} />
                            <Route path={SHOW_ALL_USER_MODELS_FOR_BRAND_URL} exact={true} component={ShowAllModelsForBrand} />
                            <Route path={SELL_BICYCLE_NOW_PAGE_URL} exact={true} render={() => <SellBicycleNow  brands={this.state.brands} />} />

                            <Route path={BUY_NOW_PAGE_URL} exact={true} component={BuyNowPage} />
                            <Route path={LOGIN_PAGE_URL} exact={true} component={LoginPage} />

                            <Route path={POLICIES_BASE_URL} component={PoliciesUrlComponent} />

                            <this.AuthenticatedRouteComponent />

                            {/*<Route path={USER_CART_URL} exact={true} component={UserAccountCart}/>*/}
                        </Switch>
                    </div>

                    {/*<BottomNavs />*/}
                    <Footer/>
                </Router>
                <CenterScreenModal />
            </div>
        );
    }
}

export default Connector(App);