import React, {Component} from 'react';
import {Link} from "react-router-dom";
import {SHOW_ALL_USER_BRANDS_URL} from "../../../../constants/application/ApplicationUrl";
import {Button} from "react-bootstrap";

class ArrowComponent extends Component {
    constructor(props) {
        super(props);
        this.ArrowBlock = this.ArrowBlock.bind(this);
    }

    ArrowBlock({title, svgClass, subTitle, link}) {
        return (
            <div className={"col-4"}>
                <div style={{height: "8vw"}} className={"position-relative d-flex justify-content-center align-items-center mx-2"}>
                    <div style={{zIndex: 1}} className={"d-flex flex-column"}>
                        <h4 className={`${svgClass === "svg-grey"?"text-black": "text-white"} m-0 fw-bold`} style={{fontSize: "1.2vw"}}>
                            {title}
                        </h4>
                        {
                            subTitle &&
                            <Button variant={"transparent"} className={"p-0 w-100 text-center text-white"} style={{fontSize: "1vw"}} as={Link} to={link}>
                                {subTitle}
                            </Button>
                        }
                    </div>
                    <svg viewBox="0 0 975 210" className={"h-100 w-100 position-absolute " + svgClass}>
                        <polygon points="0,0,894,0,975,105, 894,210, 0,210, 54, 105" />
                        Sorry, your browser does not support inline SVG.
                    </svg>
                </div>
            </div>
        );
    }

    render() {
        return (<div className={"d-flex bg-white rounded mt-4"} style={{padding: "0 5%"}}>
                <this.ArrowBlock svgClass={this.props.color[0]} title={"Bicycle Brand"} subTitle={this.props.brand} link={SHOW_ALL_USER_BRANDS_URL} />
                <this.ArrowBlock svgClass={this.props.color[1]} title={"Bicycle Model"} subTitle={this.props.model} link={this.props.modelLink} />
                <this.ArrowBlock svgClass={this.props.color[2]} title={"Address & Bicycle information"} />
            </div>);
    }
}

export default ArrowComponent;