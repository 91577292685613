import React, {Component} from 'react';
import {Container} from "react-bootstrap";
import {
    BASE_INSPECTOR_URL,
    INSPECTOR_ITEMS_URL,
    INSPECTOR_SHOW_ALL_PENDING_ITEMS_URL, INSPECTOR_SHOW_ALL_STATS_URL
} from "./urls/InspectorUrl";
import SquarePanelComponent from "../../general/selectable_square/SquarePanelComponent";
import {BackendGETRequest} from "../../../webrequests/BackendRequest";
import {GET_INSPECTION_INFO_API} from "../../../constants/backend/APIConstant";
import {Connector} from "../../../../../redux/Connector";
import {isNullOrUndefinedObject} from "../../../utils/validators/Validator";

class InspectorHome extends Component {
    state = {
        is_loading: true,
        render: [
            {
                name:"Bicycles available to inspect",
                count: 0,
                key: "availableForInspectionCount",
                link: INSPECTOR_ITEMS_URL
            },
            {
                name:"My Pending Inspections",
                count: 0,
                key: "myInspectionCount",
                link: INSPECTOR_SHOW_ALL_PENDING_ITEMS_URL
            },
            {
                name:"Statistics",
                key: "statistics",
                link: INSPECTOR_SHOW_ALL_STATS_URL
            }
        ]
    }

    componentDidMount() {
        BackendGETRequest(this.props, GET_INSPECTION_INFO_API, (response) => {
            let localRender = this.state.render;
            localRender.forEach(render => {
                if(!isNullOrUndefinedObject(response.body[render.key])) {
                    render.count = response.body[render.key];
                }
            });

            this.setState({
                is_loading: false
            });
        }, (error) => this.setState({
            is_loading: false
        }));
    }


    render() {
        return (
            <div>
                <Container className={"pt-5p"}>
                    <h3 className={"px-2"}>Inspector Account</h3>
                    <SquarePanelComponent render={this.state.render} base_url={BASE_INSPECTOR_URL} isLoading={this.state.is_loading} />
                </Container>
            </div>
        );
    }
}

export default Connector(InspectorHome);