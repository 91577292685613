import React, {Component} from 'react';
import AddressForm from "./AddressForm";
import {Col, Row} from "react-bootstrap";
import {
    BASE_ACCOUNT_URL,
    MY_ADDRESS_URL,
} from "../urls/AccountUrl";
import {CREATE_ADDRESS_API} from "../../../../constants/backend/APIConstant";
import AreYouLookingForSomethingElse from "../../../general/AreYouLookingForSomethingElse";

class CreateNewAddress extends Component {
    state = {
        address: [],
        is_loading: true,
        links: [
            {
                link: BASE_ACCOUNT_URL,
                title: "My Account"
            },
            {
                link: MY_ADDRESS_URL,
                title: "My Address"
            }
        ]
    }

    constructor(props) {
        super(props);
        this.OnSuccessFormSubmit = this.OnSuccessFormSubmit.bind(this);
    }

    OnSuccessFormSubmit() {
        window.location.href = MY_ADDRESS_URL;
        // window.location.href = UPDATE_ADDRESS_URL.replace(COL + ADDRESS_ID_PARAM, response.body.id);
    }

    render() {
        return (
            <div className={"flex-fill d-flex py-2"}>
                <Row className={"g-0"}>
                    <Col lg={9} xl={10} className={"px-2 pt-1 mb-3"}>
                        <AddressForm title={"Create New Address"}
                                     subtitle={"Create new address to buy or sell bicycle"}
                                     btnText={"Create Address"}
                                     url={CREATE_ADDRESS_API} OnSuccessFormSubmit={this.OnSuccessFormSubmit} />
                    </Col>
                    <Col lg={3} xl={2} className={"px-2 flex-shrink-0"}>
                        <AreYouLookingForSomethingElse links={this.state.links} />
                    </Col>
                </Row>
            </div>
        );
    }
}

export default CreateNewAddress;