export function isNullOrUndefinedObject(object) {
    return object === null || object === undefined;
}

export function isEmptyString(string) {
    return isNullOrUndefinedObject(string) || string === '';
}

export function isEmptyObject(object) {
    return isNullOrUndefinedObject(object) || object === {};
}

export function isEmptyArray(array) {
    return isNullOrUndefinedObject(array) || array.length <= 0;
}