import {Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import {
    BUY_NOW_PAGE_URL, SELL_BICYCLE_NOW_PAGE_URL
} from "../../../constants/application/ApplicationUrl";
import React from "react";
import "./sellbuybtn.css";

export function SellButton({as}) {
    return <Button variant={"success"} className={"mr-3 fw-bold button_w100 fs_75c_4"} as={as? as:Link} to={SELL_BICYCLE_NOW_PAGE_URL}>
        Sell
    </Button>;
}

export function BuyButton({as}) {
    return <Button variant={"info"} className={"ms-3 fw-bold button_w100 fs_75c_4"} as={as? as:Link} to={BUY_NOW_PAGE_URL}>
        Buy
    </Button>;
}

export function SellPurchaseButtons() {
    return <div className={"py-2"}>
        <SellButton />
        <BuyButton />
    </div>;
}

export function SellBicycleNowButton({username}) {
    return <div className={"my-2 text-center"}>
        <Button variant={"success"} as={Link} to={SELL_BICYCLE_NOW_PAGE_URL}>
            Sell my bicycle now
        </Button>
    </div>;
}
