import React, {Component} from 'react';
import LoginPage from "../../login/LoginPage";
import {Col, Container, Row} from "react-bootstrap";
import {SELLER_URL} from "../seller/urls/SellerUrl";
import {INSPECTOR_URL} from "../inspector/urls/InspectorUrl";
import {MY_ADDRESS_URL, MY_ORDERS_URL, MY_SECURITY_URL} from "./urls/AccountUrl";
import {BackendGETRequest} from "../../../webrequests/BackendRequest";
import AccountLinkSection from "./AccountLinkSection";
import {Connector} from "../../../../../redux/Connector";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {APPROVAL_URL} from "../approvers/urls/ApprovalsUrls";
import {GET_USER_ACCOUNT_API} from "../../../constants/backend/APIConstant";

class Account extends Component {
    state = {
        login: true,
        sections: [
            {
                title: "My orders",
                subtitle: "Your bicycle orders, sells, track, return",
                link: MY_ORDERS_URL
            },
            {
                title: "Login and Security",
                subtitle: "Manage account, password, 2fa",
                link: MY_SECURITY_URL
            },
            {
                title: "My address",
                subtitle: "edit, delete, add address",
                link: MY_ADDRESS_URL
            }
        ]
    }

    constructor(props) {
        super(props);
        this.OnSuccessRequest = this.OnSuccessRequest.bind(this);
    }

    componentDidMount() {
        BackendGETRequest(this.props, GET_USER_ACCOUNT_API, this.OnSuccessRequest);
    }

    OnSuccessRequest(response) {
        if(response.body) {
            if(response.body["seller"].enabled) {
                this.state.sections.push({
                    title: "Seller/Buyer",
                    subtitle: "Manage your selling/buying bicycles",
                    link: SELLER_URL
                });
            }

            if(response.body["inspector"].enabled) {
                this.state.sections.push({
                    title: "Inspector",
                    subtitle: "Inspect bicycles",
                    link: INSPECTOR_URL
                });
            }

            if(response.body["approvals"].enabled) {
                this.state.sections.push({
                    title: "Approvals",
                    subtitle: "Approve requests for payments",
                    link: APPROVAL_URL
                });
            }

            this.setState({
                sections: this.state.sections
            });
        }
    }

    render() {
        return (
            <section>
                {
                    this.state.login ?
                        <Container>
                            <div className={"px-2 pt-3"}>
                                <h4 className={"fw-bold"}>
                                    Hi, <FontAwesomeIcon icon={"user"}/> {this.props.user.firstName} {this.props.user.lastName}
                                </h4>
                            </div>
                            <div className={"p-2"}>
                                <Row>
                                    {
                                        this.state.sections.map((section, index) => {
                                            return <Col key={"account_" + index} md={4} sm={6} xs={12} className={"p-2"}>
                                                <AccountLinkSection link={section.link} title={section.title} subtitle={section.subtitle} />
                                            </Col>
                                        })
                                    }
                                </Row>
                            </div>
                        </Container>:
                        <div>
                            <div className={"text-center p-2 bg-light-green"}>
                                Login to access your account
                            </div>
                            <div className={"mt-4"}>
                                <LoginPage />
                            </div>
                        </div>
                }
            </section>
        );
    }
}

export default Connector(Account);