import {
    COL,
    SELL_BICYCLE_NOW_PAGE_URL,
    SHOW_ALL_USER_MODELS_FOR_BRAND_URL
} from "../../../constants/application/ApplicationUrl";
import {Link} from "react-router-dom";
import React from "react";
import {BRAND_ID_PARAM, MODEL_ID_PARAM} from "../../../constants/ParamConstant";
import {DEFAULT_IMAGE_LINK} from "../../../constants/Constant";
import square_white_image from "../../../statics/img/square_white.webp";

export function BrandSelectComponent({brand}) {
    return <Link to={SHOW_ALL_USER_MODELS_FOR_BRAND_URL.replace(COL + BRAND_ID_PARAM, brand.id)} className={"no-text-decor"} >
        <div className={"shadow-sm rounded overflow-hidden border hover-shadow d-flex flex-column h-100"}>
            {
                brand.image? <img src={brand.image} alt={"bicycle"} className={"img-object-fit-cover"} />:
                    <div className={"position-relative"}>
                        <img src={square_white_image} alt={"bicycle"} className={"img-object-fit-cover"} />
                        <div className={"position-absolute top-0 start-0 w-100 h-100 d-flex justify-content-center text-center align-items-center text-capitalize fw-bold"}>
                            {brand.name}
                        </div>
                    </div>
            }
        </div>
    </Link>
}

export function ModelSelectComponent({model, brand}) {
    return <Link to={SELL_BICYCLE_NOW_PAGE_URL.replace(COL + BRAND_ID_PARAM, brand.id).replace(COL + MODEL_ID_PARAM, model.id)} className={"no-text-decor"} >
        <div className={"shadow-sm rounded overflow-hidden border hover-shadow bg-white p-1"}>
            <div className={"rounded overflow-hidden"}>
                <div style={{height: 200}} className={"p-1"}>
                    <img src={(model.images && model.images.length > 0)? model.images[0]: DEFAULT_IMAGE_LINK} alt={"bicycle"} className={"img-object-fit-contain"}/>
                </div>
                <div className={"p-2 bg-light-blue3 text-center"}>
                    <p className={"fw-bold fs_75c_4 m-0"}>
                        {model.modelName? model.modelName: brand.name}
                    </p>
                </div>
            </div>
        </div>
    </Link>
}