import React, {Component} from 'react';
import {Container} from "react-bootstrap";

class SellFaq extends Component {
    render() {
        return (
            <Container>
                <div className={"py-4"}>
                    <h3 className={"fw-bold text-center m-0"}>
                        Frequently Asked Questions
                    </h3>
                    <p className={"text-secondary text-center"}>
                        Selecting brand on Bicycle Company to selling my bicycle
                    </p>
                </div>
                {
                    this.props.faq.map((faq, index) => <div key={"faq_" + index}>
                            <p className={"fw-bold fs_75c_10"}>
                                {faq.question}
                            </p>
                            <p className={"fs_75c_4"}>
                                {faq.answer}
                            </p>
                        </div>)
                }
            </Container>
        );
    }
}

export default SellFaq;