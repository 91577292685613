import {Component} from 'react';
import {ADD_SELL_ORDER_IMAGE} from "../../../../constants/backend/APIConstant";
import {CreteImageForm} from "../../../../utils/FormManager";
import {BackendPOSTRequestWithImage} from "../../../../webrequests/BackendRequest";
import {ShowErrorModalFromError} from "../../../modals/centermodal/CenterModalScreenManager";
import {ORDER_ID_PARAM} from "../../../../constants/ParamConstant";

class SingleOrderImageUpload extends Component {

    constructor(props) {
        super(props);
        this.OnChangeImage = this.OnChangeImage.bind(this);
        this.StartImageUpload = this.StartImageUpload.bind(this);
        this.OnFailureImageUpload = this.OnFailureImageUpload.bind(this);
        this.OnSuccessImageUpload = this.OnSuccessImageUpload.bind(this);
        this.OnImageUploadProgress = this.OnImageUploadProgress.bind(this);
    }

    StartImageUpload(imageBlob) {
        this.setState({
            is_uploading_image: true,
            image_upload_progress: 0
        });

        let orderId = this.props.match.params[ORDER_ID_PARAM];
        let formData = CreteImageForm("orderId", orderId, imageBlob);
        BackendPOSTRequestWithImage(this.props, ADD_SELL_ORDER_IMAGE, formData, this.OnSuccessImageUpload, this.OnFailureImageUpload, this.OnImageUploadProgress);
    }

    OnSuccessImageUpload(response) {
        let order = this.state.order;
        if(this.state.order.images === undefined) {
            order.images = [];
        }
        order.images.push(response.body);

        this.setState({
            is_uploading_image: false,
            imageUrl: "",
            imageBlob: null,
            order: order
        });
    }

    OnFailureImageUpload(error) {
        this.setState({
            is_uploading_image: false
        });

        ShowErrorModalFromError(this.props, "Image Upload Error", error);
    }

    OnImageUploadProgress(percent) {
        this.setState({
            image_upload_progress: percent
        });
    }

    OnChangeImage(event) {
        if (event.target.files.length <= 0) return;

        this.setState({
            imageUrl: URL.createObjectURL(event.target.files[0]),
            imageBlob: event.target.files[0],
        });

        this.StartImageUpload(event.target.files[0]);
    }
}

export default SingleOrderImageUpload;