import React, {Component} from 'react';
import {Button, Col, Container, FormControl, Row, Form, InputGroup, Badge, Table} from "react-bootstrap";
import {BreadCrumb} from "../../../../panels/breadcrum/BreadCrumb";
import {BackendPOSTRequest, BackendPOSTRequestWithImage} from "../../../../../webrequests/BackendRequest";
import {
    CREATE_BICYCLE_API,
    UPDATE_BICYCLE_IMAGE_API
} from "../../../../../constants/backend/APIConstant";
import {
    COL
} from "../../../../../constants/application/ApplicationUrl";
import {Link} from "react-router-dom";
import {ShowOkButtonErrorModal} from "../../../../modals/centermodal/CenterModalScreenManager";
import {Connector} from "../../../../../../../redux/Connector";
import {BASE_SELLER_URL, SELLER_BUY_BICYCLE_URL, SELLER_SHOW_SINGLE_BICYCLE_URL} from "../../urls/SellerUrl";
import {CreteImageForm, JsonFromFormEvent} from "../../../../../utils/FormManager";
import {DEFAULT_ERROR_MSG} from "../../../../../constants/Constant";
import {ITEM_ID_PARAM} from "../../../../../constants/ParamConstant";

class SellerCreateItem extends Component {
    state = {
        imageBlob: null,
        imageUrl: "",
        isAllowRent: false,
        isAllowBuy: false,
        specifications: [
            {}
        ],
        MAX_SPEC: 10,
        is_loading: true
    }

    constructor(props) {
        super(props);
        this.onChangeImage = this.onChangeImage.bind(this);
        this.OnAllowRentChange = this.OnAllowRentChange.bind(this);
        this.OnAllowBuyChange = this.OnAllowBuyChange.bind(this);
        this.AddSpecification = this.AddSpecification.bind(this);
        this.RemoveSpecification = this.RemoveSpecification.bind(this);
        this.HandlerSubmitItemCreateForm = this.HandlerSubmitItemCreateForm.bind(this);
        this.OnSuccessCreateItem = this.OnSuccessCreateItem.bind(this);
        this.OnFailureCreateItem = this.OnFailureCreateItem.bind(this);

        this.image_input = React.createRef();
    }

    onChangeImage(event) {
        if(event.target.files.length  <= 0 ) {
            return this.setState({
                imageUrl: "",
                imageBlob: null
            });
        }

        this.setState({
            imageUrl: URL.createObjectURL(event.target.files[0]),
            imageBlob: event.target.files[0]
        });
    }

    OnAllowRentChange(event) {
        this.setState({
            isAllowRent: event.target.checked
        });
    }

    OnAllowBuyChange(event) {
        this.setState({
            isAllowBuy: event.target.checked
        });
    }

    AddSpecification(event) {
        this.setState({
            specifications: [...this.state.specifications, {}]
        });
    }

    RemoveSpecification(event) {
        this.state.specifications.pop();

        this.setState({
            specifications: [...this.state.specifications]
        });
    }

    HandlerSubmitItemCreateForm(event) {
        event.preventDefault();

        let formJsonData = JsonFromFormEvent(event);

        formJsonData["specifications"] = [];
        if(event.target.elements["specifications"] instanceof HTMLInputElement) {
            formJsonData["specifications"].push(event.target.elements["specifications"].value);
        } else {
            event.target.elements["specifications"].forEach((specification) => {
                formJsonData["specifications"].push(specification.value);
            });
        }

        this.setState({
            is_loading: true
        });

        BackendPOSTRequest(this.props, CREATE_BICYCLE_API, formJsonData, this.OnSuccessCreateItem, this.OnFailureCreateItem);
        return false;
    }

    OnSuccessCreateItem(response) {
        let formData = CreteImageForm("itemId", response.body.id, this.state.imageBlob);

        BackendPOSTRequestWithImage(this.props, UPDATE_BICYCLE_IMAGE_API.replace(COL + ITEM_ID_PARAM, response.body.id), formData, () => {
            this.setState({
                is_loading: false
            });
            window.location.href = SELLER_SHOW_SINGLE_BICYCLE_URL.replace(COL + ITEM_ID_PARAM, response.body.id);
        }, this.OnFailureCreateItem);
    }

    OnFailureCreateItem(error) {
        this.setState({
            is_loading: false
        });

        let content = DEFAULT_ERROR_MSG;
        if(error.response !== undefined && error.response.data !== null) {
            content = error.response.data.message;
        }

        ShowOkButtonErrorModal(this.props, "Bicycle Create Issue", content);
    }


    render() {
        return (
            <div>
                <Container className={"pt-5p"}>
                    <h3>Create New Bicycle</h3>
                    <div className={"py-2"}>
                        <BreadCrumb base_url={BASE_SELLER_URL} />
                    </div>
                    <div className={"mb-5"}>
                        <form onSubmit={this.HandlerSubmitItemCreateForm}>
                            <div className={"bg-white shadow-sm p-2 "}>
                                <Row className={"g-0"}>
                                    <Col md={6} className={"p-2"}>
                                        <div style={{height: 264}}
                                             className={"mb-3 border border-secondary bg-light cursor-pointer"}
                                             onClick={() => {
                                                 this.image_input.current.click()
                                             }}>
                                            {
                                                this.state.imageUrl &&
                                                <img src={this.state.imageUrl} name={"image"} alt={"selected-item"}
                                                     className={"img-object-fit-cover"}/>
                                            }
                                        </div>

                                        <div className={"mb-3"}>
                                            <FormControl required ref={this.image_input} type={"file"} name={"image"}
                                                         onChange={this.onChangeImage}/>
                                            <i>Note: More images you can upload it later</i>
                                        </div>
                                    </Col>
                                    <Col md={6} className={"p-2"}>
                                        <Form.Check
                                            type={"switch"}
                                            placeholder={"eg. No"}
                                            name={"assemblyRequired"}
                                            label={"Assembly Required"}
                                        />

                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            label="Allow for rent"
                                            checked={this.state.isAllowRent}
                                            onChange={this.OnAllowRentChange}
                                            name={"isRent"}
                                        />

                                        {
                                            this.state.isAllowRent &&
                                            <div className={"p-2 shadow-sm"}>
                                                <h6>Rent details</h6>
                                                <Row className={"g-0"}>
                                                    <Col className={"p-1"} md={6}>
                                                        <label>Start date</label>
                                                        <FormControl type={"date"} name={"startRentTime"}/>
                                                    </Col>
                                                    <Col className={"p-1"} md={6}>
                                                        <label>End date</label>
                                                        <FormControl type={"date"} name={"endRentTime"}/>
                                                    </Col>
                                                    <Col className={"p-1"} md={12}>
                                                        <label>Rent per day</label>
                                                        <FormControl type={"number"} placeholder={"Rent per day in Rs."}
                                                                     name={"rentPerDay"}/>
                                                    </Col>
                                                </Row>
                                            </div>
                                        }

                                        <Form.Check
                                            type="switch"
                                            id="custom-switch"
                                            label="Allow for buy"
                                            checked={this.state.isAllowBuy}
                                            onChange={this.OnAllowBuyChange}
                                            name={"isBuy"}
                                        />

                                        <div className={"p-2 shadow-sm"}>
                                            <h6>Buying details</h6>
                                            <Row className={"g-0"}>
                                                <Col className={"p-1"} md={12}>
                                                    <label>Item Price</label>
                                                    <FormControl required type={"number"}
                                                                 placeholder={"Item price in Rs."} name={"price"}/>
                                                </Col>

                                                <Col className={"p-1"} md={12}>
                                                    <label>Item MRP</label>
                                                    <FormControl required type={"number"}
                                                                 placeholder={"Item MRP in Rs."} name={"mrp"}/>
                                                </Col>

                                                <Col className={"p-1"} md={12}>
                                                    <label>How old is this item in days ?</label>
                                                    <FormControl required type={"number"}
                                                                 placeholder={"How old is this item in days, 0 in case it's new"}
                                                                 name={"howOld"}/>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <div className={"bg-white p-2 my-2"}>
                                <Table striped bordered hover>
                                    <tbody>
                                    <tr>
                                        <th>Name of the item</th>
                                        <td>
                                            <FormControl required name={"name"} minLength={2}
                                                         placeholder={"eg. Firefox Road Runner"}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Bike Type</th>
                                        <td>
                                            <Form.Select name={"bikeType"}>
                                                <option value={"MB"}>Mountain Bike</option>
                                                <option value={"RB"}>Road Bike</option>
                                                <option value={"HB"}>Hybrid</option>
                                            </Form.Select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Brand</th>
                                        <td>
                                            <Form.Select name={"brand"}>
                                                <option value={"Firefox"}>Firefox</option>
                                                <option value={"Atlas"}>Atlas</option>
                                                <option value={"Leader"}>Leader</option>
                                            </Form.Select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Usage</th>
                                        <td>
                                            <FormControl placeholder={"eg. road, mountains"} name={"usage"}/>
                                            <Badge className={"m-1"}>Road</Badge>
                                            <Badge className={"m-1"}>Trail</Badge>
                                            <Badge className={"m-1"}>Mountains</Badge>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Suspension</th>
                                        <td>
                                            <FormControl placeholder={"eg. front, back"} name={"suspension"}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Included Components</th>
                                        <td>
                                            <FormControl placeholder={"Other included components rather than bicycle"}
                                                         name={"includedComponent"}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Number of speeds</th>
                                        <td>
                                            <FormControl required type={"Number"} placeholder={"1 to 21"} max={21}
                                                         name={"numberOfSpeeds"}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Colour</th>
                                        <td>
                                            <FormControl required placeholder={"eg. green, red"} name={"color"}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Frame Material</th>
                                        <td>
                                            <FormControl placeholder={"eg. steel"} name={"frameMaterial"}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Brake Type</th>
                                        <td>
                                            <FormControl placeholder={"eg. disc"} name={"breakType"}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Theme</th>
                                        <td>
                                            <FormControl placeholder={"eg. sports"} name={"theme"}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Model Name</th>
                                        <td>
                                            <FormControl required placeholder={"eg. RAPIDE Firefox"}
                                                         name={"modelName"}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Wheel Material</th>
                                        <td>
                                            <FormControl placeholder={"eg. alloy steel"} name={"wheelMaterial"}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Model Year</th>
                                        <td>
                                            <FormControl placeholder={"eg. 2022"} name={"modelYear"}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Speed Rating</th>
                                        <td>
                                            <FormControl placeholder={"eg. single speed"} name={"speedRating"}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Manufacturer</th>
                                        <td>
                                            <FormControl required placeholder={"eg. Bicycle Company"} name={"manufacturer"}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Country of Origin</th>
                                        <td>
                                            <FormControl required placeholder={"eg. India"} name={"countryOfOrigin"}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Size</th>
                                        <td>
                                            <FormControl required placeholder={"eg. 2-6 years, 6-10 years"}
                                                         name={"size"}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Wheel Size</th>
                                        <td>
                                            <FormControl required placeholder={"eg. 26 inches"} name={"wheelSize"}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Frame Size</th>
                                        <td>
                                            <FormControl required placeholder={"eg. 18 inch"} name={"frameSize"}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Item Dimensions LxWxH</th>
                                        <td>
                                            <FormControl required placeholder={"eg. 137 x 21 x 72 Centimeters"}
                                                         name={"dimensions"}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Item Weight</th>
                                        <td>
                                            <FormControl placeholder={"eg. 2000 gm"} name={"itemWeight"}/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>

                            <div className={"bg-white p-2 pb-5"}>
                                <label>
                                    Product Specifications/Details
                                </label>
                                <div className={"specifications-input-box"}>
                                    {
                                        this.state.specifications.map((specification, index) => {
                                            return <InputGroup className={"my-1"} key={"spec_" + index}>
                                                <FormControl placeholder={"specification " + (index + 1)}
                                                             name={"specifications"}/>
                                                <Button variant={"danger"} onClick={this.RemoveSpecification}>
                                                    X
                                                </Button>
                                            </InputGroup>
                                        })
                                    }
                                </div>
                                {
                                    this.state.specifications.length < this.state.MAX_SPEC &&
                                    <Button variant={"transparent"}
                                            className={"border rounded-circle m-1 float-end"}
                                            onClick={this.AddSpecification}>
                                        +
                                    </Button>
                                }
                            </div>

                            <div className={"py-4 text-center"}>
                                <Button variant={"danger"} type={"button"} className={"m-1"} as={Link}
                                        to={SELLER_BUY_BICYCLE_URL}>
                                    Go back to Item Screen
                                </Button>

                                <Button variant={"primary"} type={"submit"} className={"m-1"}>
                                    Create Item
                                </Button>
                            </div>
                        </form>
                    </div>
                </Container>
            </div>
        );
    }
}

export default Connector(SellerCreateItem);