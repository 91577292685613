import React, {Component} from 'react';
import {Container, FormControl} from "react-bootstrap";
import {BackendGETRequest} from "../../../webrequests/BackendRequest";
import {GET_ALL_MODELS_FOR_BRAND_API} from "../../../constants/backend/APIConstant";
import {ShowErrorModalFromError} from "../../modals/centermodal/CenterModalScreenManager";
import {Connector} from "../../../../../redux/Connector";
import TopSellingBrandsAndModels from "../../home/sections/TopSellingBrandsAndModels";
import {ModelSelectComponent} from "./BrandSelectComponent";
import {
    COL, SELL_BICYCLE_NOW_PAGE_URL
} from "../../../constants/application/ApplicationUrl";
import {Link} from "react-router-dom";
import {BRAND_ID_PARAM, MODEL_ID_PARAM} from "../../../constants/ParamConstant";
import {DEFAULT_MODEL_VALUE} from "../../../constants/Constant";
import {SquarePlaceHolder} from "../../general/PlaceHolder";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {isEmptyString} from "../../../utils/validators/Validator";
import ArrowComponent from "./arrow_components/ArrowComponent";
import SellFaq from "./sell_faq/SellFAQ";
import {SetDocumentTitle} from "../../../managers/DocumentTitleManager";

class ShowAllModelsForBrand extends Component {
    state = {
        is_loading_models: true,
        brand: {},
        models: [],
        search_text: "",
        faq: [{
            question: "Why to select model?",
            answer: "Selecting the right model will help you to get better price instead going with the random model as the some popular model have most demanding value in the market."
        }]
    }

    constructor(props) {
        super(props);
        this.OnSuccessGetModels = this.OnSuccessGetModels.bind(this);
        this.OnSearchTextChange = this.OnSearchTextChange.bind(this);
        this.ClearModelSearch = this.ClearModelSearch.bind(this);
    }

    componentDidMount() {
        SetDocumentTitle("Sell with bicycle models", "Start selling your bicycle in Bengaluru from the model or brand that you have. It's now very easy with Pixie Mart to start selling your bicycle with mobile app.");
        BackendGETRequest(this.props, GET_ALL_MODELS_FOR_BRAND_API.replace(BRAND_ID_PARAM, this.props.match.params[BRAND_ID_PARAM]), this.OnSuccessGetModels, (error) => ShowErrorModalFromError(this.props, "Requesting models", error));
    }

    OnSuccessGetModels(response) {
        if(response.body["models"].length === 0) {
            window.location.href = SELL_BICYCLE_NOW_PAGE_URL.replace(COL + BRAND_ID_PARAM, this.props.match.params[BRAND_ID_PARAM]).replace(COL + MODEL_ID_PARAM, DEFAULT_MODEL_VALUE);
        }

        this.setState({
            models: response.body["models"],
            brand: response.body["brand"],
            is_loading_models: false
        });
    }

    OnSearchTextChange(event) {
        this.setState({
            search_text: event.target.value
        });
    }

    ClearModelSearch() {
        this.setState({
            search_text: ""
        });
    }

    render() {
        return (
            <div>
                <Container className={"mb-4"}>
                    <div>
                        <ArrowComponent color={["svg-green", "svg-blue", "svg-grey"]} brand={this.state.brand.name} />

                        <div className={"bg-white rounded my-3 p-2 py-3"}>
                            <div className={"position-relative p-2 bg-light-blue2 d-flex justify-content-between align-items-center"}>
                                <div>
                                    <h5 className={"fw-bold text-primary m-0"}>Select from bicycle models for {this.state.brand.name} to sell your bicycle</h5>
                                </div>
                                <div className={"position-relative"} style={{minWidth: "26%"}}>
                                    <div className={"position-absolute h-100 d-flex align-items-center"} style={{left: "0.32rem", top: "0"}}>
                                        <div className={"p-1 bg-px-blue rounded-circle d-flex align-items-center justify-content-center"} style={{width: 30, height: 30}}>
                                            <FontAwesomeIcon icon={"search"} color={"white"} />
                                        </div>
                                    </div>
                                    <FormControl value={this.state.search_text} onChange={this.OnSearchTextChange} placeholder={"Search for your models"} className={"rounded-pm-1 w-100"} style={{paddingLeft: "2.4rem"}} />
                                    <div className={"position-absolute h-100 d-flex align-items-center"} style={{right: "0.32rem", top: "0"}}>
                                        {
                                            (!isEmptyString(this.state.search_text)) &&
                                            <div className={"p-1 rounded-circle d-flex align-items-center justify-content-center cursor-pointer"} style={{width: 30, height: 30}} onClick={this.ClearModelSearch}>
                                                <FontAwesomeIcon icon={"times"} color={"grey"} />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className={"d-flex flex-wrap mt-3 position-relative overflow-auto"} style={{maxHeight: "80vh"}}>
                                {
                                    this.state.is_loading_models &&
                                    [...Array(12)].map((_, index) => <div key={"model_id_" + index} className={"p-2 flex-shrink-0 col-md-3 col-sm-4 col-6"} style={{height: 170}}>
                                        <SquarePlaceHolder />
                                    </div>)
                                }
                                {
                                    this.state.models.filter(model => isEmptyString(this.state.search_text) || model.modelName?.toLowerCase().replace(" ","").includes(this.state.search_text.toLowerCase().replace(" ","")))
                                        .map((model, index) => <div key={"model_id_" + index} className={"p-2 col-md-3 col-sm-4 col-6"}>
                                            <ModelSelectComponent model={model} brand={this.state.brand} />
                                        </div>)
                                }
                            </div>
                            <div className={"p-2"}>
                                {
                                    <Link className={"no-text-decor text-primary fw-bold"}
                                          to={SELL_BICYCLE_NOW_PAGE_URL.replace(COL + BRAND_ID_PARAM, this.props.match.params[BRAND_ID_PARAM]).replace(COL + MODEL_ID_PARAM, DEFAULT_MODEL_VALUE)}>
                                        I'm not sure which model to select, please proceed to sell with Firefox brand
                                    </Link>
                                }
                            </div>
                        </div>
                    </div>
                </Container>
                <div className={"p-2 bg-white"}>
                    <SellFaq faq={this.state.faq} />
                </div>
                <div className={"py-5 bg-white"}>
                    <TopSellingBrandsAndModels />
                </div>
            </div>
        );
    }
}

export default Connector(ShowAllModelsForBrand);