import React, {Component} from 'react';

class FormTitleComponent extends Component {
    render() {
        return (
            <div className={"p-2 mb-3"} style={{borderLeft: "6px solid #1167b1", backgroundColor: "rgba(17,103,177,0.1)"}}>
                <h4 className={"fw-bold"}> {this.props.title}</h4>
                <p className={"text-muted"}>
                    {this.props.content}
                </p>
            </div>
        );
    }
}

export default FormTitleComponent;