import React, {Component} from 'react';
import {Dropdown, Form} from "react-bootstrap";
import "./dropdownselect.css";
import {isEmptyArray, isNullOrUndefinedObject} from "../../../utils/validators/Validator";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class DropDownSelect extends Component {
    state = {
        drop_down_selected_value: { name:"", value: "" }
    }

    constructor(props) {
        super(props);
        this.OnClickDropDownItem = this.OnClickDropDownItem.bind(this);
        this.GetDropDownSelectedName = this.GetDropDownSelectedName.bind(this);
    }

    componentDidMount() {
        if(!isNullOrUndefinedObject(this.props.defaultIndex)) {
            this.OnClickDropDownItem(this.props.defaultIndex);
        }
    }

    OnClickDropDownItem(index) {
        this.setState({
            drop_down_selected_value: this.props.items[index]
        }, () => {
            if(this.props.onChange) {
                this.props.onChange(index, this.props.items[index].value);
            }
        });
    }

    GetDropDownSelectedName() {
        return this.state.drop_down_selected_value.name;
    }

    render() {
        if(isEmptyArray(this.props.items)) {
            return <></>;
        }

        return (
            <div className={"w-100"}>
                <Dropdown onSelect={this.OnClickDropDownItem}>
                    <Dropdown.Toggle className={"bg-transparent shadow-none w-100"} bsPrefix={"g-0"}>
                        <div className={"text-dark position-relative"}>
                            <label className={"fw-bold small"}>
                                {this.props.label}
                                {
                                    this.props.required &&
                                    <span className={"text-danger"}>*</span>
                                }
                            </label>
                            <div className={"border rounded position-relative overflow-hidden text-truncate"} style={{padding: ".375rem 2.25rem .375rem .75rem", height: "2.25rem"}}>
                                {this.GetDropDownSelectedName()}
                                <div className={"position-absolute end-0 top-0 d-flex align-items-center h-100 px-2"}>
                                    <FontAwesomeIcon icon={"chevron-down"} color={"grey"} />
                                </div>
                            </div>
                            <Form.Control className={"visually-hidden"} name={this.props.name}
                                          required={this.props.required} value={this.state.drop_down_selected_value.value} onChange={()=>{}}
                                          placeholder={this.props.placeholder} />
                        </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className={"w-100 overflow-auto shadow border"} style={{maxHeight: "50vh"}}>
                        {
                            this.props.items?.map( (item, index) => {
                                return <Dropdown.Item key={this.props.name + "_drop_item_" + index} eventKey={index} className={(this.props.items.length === index + 1)?"": "border-bottom"}>
                                    <div className={"p-1 cursor-pointer overflow-hidden"} style={{height: 32}}>
                                        {item.name}
                                        {
                                            item.image && <div className={"float-end overflow-hidden h-100 px-2"}>
                                                <img src={item.image} alt={"im_" + index} className={"img-object-fit-cover"} />
                                            </div>
                                        }
                                    </div>
                                </Dropdown.Item>
                            })
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </div>
        );
    }
}

export default DropDownSelect;