import React, {Component} from 'react';
import {OverlayTrigger, Popover, ProgressBar} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class Indicators extends Component {
    constructor(props) {
        super(props);
        this.Circle = this.Circle.bind(this);
    }

    Circle({start, index, title, content}) {
        return <OverlayTrigger
            placement="top"
            overlay={<Popover>
                <Popover.Header as="h3">{title}</Popover.Header>
                <Popover.Body className={"p-1"}>
                    {content}
                </Popover.Body>
            </Popover>}>
            {({ref, ...triggerHandler}) => (
                this.props.activeIndex > index ?
                    <div {...triggerHandler} ref={ref}
                        className={`outer-ring bg-white rounded-circle position-absolute top-0 ${!start && "end-0"}`}>
                        <FontAwesomeIcon color={"green"} icon={"check-circle"} className={"w-100 h-100"}/>
                    </div> : <div {...triggerHandler} ref={ref}
                        className={`outer-ring bg-primary rounded-circle position-absolute top-0 p-1 ${!start && "end-0"}`}>
                        <div className={"bg-white w-100 h-100 rounded-circle"}>
                        </div>
                    </div>
            )}
        </OverlayTrigger>;
    }

    render() {
        return (
            <div className={"d-flex p-2"}>
                {
                    this.props.circles.map((circle, index) => {
                        if(index === 0) return <div key={"index_" + index} className={"position-relative d-flex"} style={{height: 24, zIndex: 1, paddingRight: 24}}>
                            <this.Circle index={index} title={circle.title} content={circle.content} />
                        </div>;

                        return <div key={"index_" + index}
                            className={"position-relative w-100 d-flex align-items-center"}
                            style={{height: 24}}>
                            <ProgressBar now={index > this.props.activeIndex ? 0: 100} className={"rounded-0 flex-fill pm-progress-bar"} variant={index > this.props.activeIndex ? "light": "success"} />
                            <this.Circle index={index} title={circle.title} content={circle.content} />
                        </div>;
                    })
                }
            </div>
        );
    }
}

export default Indicators;