import React, {Component} from 'react';
import {Route, Switch} from "react-router-dom";
import {APPROVAL_URL, SELLING_APPROVAL_URL} from "./ApprovalsUrls";
import ApprovalsHome from "../ApprovalsHome";
import PendingForPaymentApprovals from "../sell/PendingForPaymentApprovals";

class ApprovalsUrlsComponent extends Component {
    render() {
        return (
            <Switch>
                <Route path={APPROVAL_URL} exact={true} component={ApprovalsHome} />
                <Route path={SELLING_APPROVAL_URL} exact={true} component={PendingForPaymentApprovals} />
            </Switch>
        );
    }
}

export default ApprovalsUrlsComponent;