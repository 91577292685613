import React, {Component} from 'react';
import {Button, FormCheck, FormControl, InputGroup} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {GoogleAuthProvider} from "../../google/login/GoogleAuthProvider";
import {SetDocumentTitle} from "../../managers/DocumentTitleManager";

class LoginPage extends Component {

    componentDidMount() {
        SetDocumentTitle("Login", "Login into pixie mart to start selling and buying bicycle and unlock the best experience in bicycle categories");
    }

    render() {
        return (
            <div className={"d-flex justify-content-center align-items-center flex-fill p-2 py-4"}>
                    <div className={"d-flex bg-primary overflow-hidden rounded shadow-sm"} style={{minWidth: "40%"}}>
                        {/*<div className={"flex-fill w-50 hide-on-small-screen"} >*/}
                        {/*    <img />*/}
                        {/*</div>*/}
                        <div className={"flex-fill w-50 bg-white p-4"}>
                            <h1 className={"fw-bold fs_heading2"}>
                                Login Bicycle Company | Pixie Mart
                            </h1>
                            <p className={"text-secondary small"}>
                                Login into Bicycle Company to buy or sell bicycle
                            </p>

                            <div className={"py-2"}>
                                <div className={"d-flex py-3"}>
                                    <GoogleAuthProvider />
                                </div>

                                <div className={"text-secondary small"}>
                                    Or sign in with
                                </div>
                            </div>

                            <InputGroup className={"shadow-none my-2 mt-4"}>
                                <InputGroup.Text>
                                    <FontAwesomeIcon icon={"user"}/>
                                </InputGroup.Text>
                                <FormControl name="username" placeholder={"Email or username"}
                                             className={"shadow-none"}/>
                            </InputGroup>

                            <InputGroup className={"shadow-none mb-4"}>
                                <InputGroup.Text>
                                    <FontAwesomeIcon icon={"key"}/>
                                </InputGroup.Text>
                                <FormControl name="password" type={"password"} placeholder={"Password"}
                                             className={"shadow-none"}/>
                            </InputGroup>

                            <FormCheck label="Remember Me" className={"shadow-none small"}/>

                            <Button className={"my-2 w-100 shadow-none"}>Login</Button>
                            <div className={"text-primary small"}>
                                Forgot password
                            </div>
                        </div>
                    </div>
            </div>
        );
    }
}

export default LoginPage;