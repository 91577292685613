import React, {Component} from 'react';
import {Connector} from "../../../../../../../redux/Connector";
import {Button, Container, FormControl, InputGroup, Spinner, Table} from "react-bootstrap";
import {BackendGETRequest, BackendPOSTRequest} from "../../../../../webrequests/BackendRequest";
import {ShowErrorModalFromError, ShowOkButtonErrorModal} from "../../../../modals/centermodal/CenterModalScreenManager";
import {isEmptyString, isNullOrUndefinedObject} from "../../../../../utils/validators/Validator";
import {
    CREATE_MODEL_FROM_AMAZON_ASIN_API,
    GET_MODEL_FROM_AMAZON_ASIN_API
} from "../../../../../constants/backend/APIConstant";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class ModelWithAmazonAsin extends Component {

    state = {
        isLoading: false,
        bicycleModel: {}
    }

    constructor(props) {
        super(props);
        this.onClickSubmitAsin = this.onClickSubmitAsin.bind(this);
        this.FieldData = this.FieldData.bind(this);
        this.SubmitModel = this.SubmitModel.bind(this);
    }

    onClickSubmitAsin(event) {
        event.preventDefault();

        let asinNumber = event.target.elements["asin"].value;
        if(isEmptyString(asinNumber) || asinNumber.length < 10 || asinNumber.length > 15) {
            ShowOkButtonErrorModal(this.props, "Bicycle model", "Invalid asin number");
            return false;
        }

        this.setState({
            is_loading: true
        });

        BackendGETRequest(this.props, GET_MODEL_FROM_AMAZON_ASIN_API + asinNumber, (response) => {
            this.setState({bicycleModel: response["body"], is_loading: false});
        }, (error) => {
            this.setState({bicycleModel: {}, is_loading: false});

            ShowErrorModalFromError(this.props, "Bicycle Model", error);
        })
        return false;
    }

    SubmitModel() {
        if(!isNullOrUndefinedObject(this.state.bicycleModel["id"])) {
            // window.location.href = "";
            alert("WIP");
            return;
        }

        this.setState({
            is_loading: true
        });

        BackendPOSTRequest(this.props, CREATE_MODEL_FROM_AMAZON_ASIN_API, this.state.bicycleModel, (response) => {
            let bicycleModelLocal = this.state.bicycleModel;
            bicycleModelLocal.id = response.body["id"];

            this.setState({
                is_loading: false,
                bicycleModel: bicycleModelLocal
            });
        }, (error) => {
            this.setState({is_loading: false});

            ShowErrorModalFromError(this.props, "Bicycle Model", error);
        });
    }

    FieldData({field}) {
        if(field === "specifications") {
            return <ul>
                {this.state.bicycleModel[field].map((specification, index) => <li key={"specification" + index}>{specification}</li>)}
            </ul>
        } else if(field === "images") {
            return <div className={"d-flex"}>
                {
                    this.state.bicycleModel[field].map((image, index) => <div className={"p-1"} key={"image_" + index}>
                        <div className={"bg-white rounded p-1"}>
                            <img src={image} alt={"amazon_asin_im"} className={"img-object-fit-cover"} />
                        </div>
                    </div>)
                }
            </div>
        }

        return this.state.bicycleModel[field];
    }

    render() {
        return (
            <div>
                <Container className={"d-flex flex-column"}>
                    <div className={"d-flex"}>
                        <div className={"bg-white rounded p-2 m-2"}>
                            <form onSubmit={this.onClickSubmitAsin}>
                                <label className={"fw-bold small"}>Enter asin</label>
                                <InputGroup>
                                    <FormControl name={"asin"} className={"text-uppercase"} />
                                    <Button type={"submit"} className={"shadow-none"} disabled={this.state.is_loading}>
                                        {
                                            this.state.is_loading && <Spinner animation={"border"} size={"sm"} className={"me-1"} />
                                        }
                                        Submit
                                    </Button>
                                </InputGroup>
                            </form>
                        </div>
                    </div>

                    {
                        this.state.bicycleModel["asin"] &&
                        <div className={"flex-fill m-2 p-2 bg-white"}>
                            <Table striped bordered hover>
                                <tbody>
                                {
                                    Object.keys(this.state.bicycleModel).filter(key=>key !== "brandDetails").map((field, index) => <tr key={"fi_" + index}>
                                        <th className={"text-capitalize"}>
                                            {field}
                                        </th>
                                        <td>
                                            <this.FieldData field={field} />
                                        </td>
                                    </tr>)
                                }
                                </tbody>
                            </Table>
                            <div className={"mb-2"}>
                                {
                                    !this.state.bicycleModel["brandDetails"] &&
                                    <div>
                                        <FontAwesomeIcon icon={"check-circle"} size={"sm"} className={"me-1"} />
                                        New brand <b>{this.state.bicycleModel["brand"]}</b> will get created on adding this model
                                    </div>
                                }
                            </div>
                            <Button onClick={this.SubmitModel} disabled={this.state.is_loading}>
                                {
                                    this.state.is_loading && <Spinner animation={"border"} size={"sm"} className={"me-1"} />
                                }
                                {this.state.bicycleModel["id"]?"Edit Model":"Add Model"}
                            </Button>
                        </div>
                    }
                </Container>
            </div>
        );
    }
}

export default Connector(ModelWithAmazonAsin);