import {SetErrorModalScreen} from "../../../../../redux/ConnectorWrapper";
import {isNullOrUndefinedObject} from "../../../utils/validators/Validator";
import {DEFAULT_ERROR_MSG} from "../../../constants/Constant";

export function HideErrorModal(props) {
    SetErrorModalScreen(props,{});
}

export function ShowOkButtonErrorModal(props, label, content) {
    SetErrorModalScreen(props,{
        show:true, image: "", label: label, content: content, okButton: true
    });
}

export function ShowSuccessErrorModal(props, label, content) {
    SetErrorModalScreen(props, {show:true, label: label, content: content, okButton: true});
}

export function ShowErrorModalFromError(props, label, error) {
    ShowOkButtonErrorModal(props, label, (!isNullOrUndefinedObject(error.response) && error.response.data.message) || DEFAULT_ERROR_MSG);
}
